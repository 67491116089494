import actions from './actions';

const initState = {
  isLoading: false,
  selectOptions: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_SELECT_OPTIONS_CONNECTOR:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SELECT_OPTIONS_CONNECTOR_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const data = payload.data;
      data.key = payload.dataType;

      return {
        ...state,
        isLoading: false,
        selectOptions: state.selectOptions.find(element => element.key === data.key)
          ? [...state.selectOptions]
          : [...state.selectOptions, data],
      };
    case actions.LOAD_SELECT_OPTIONS_CONNECTOR_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
