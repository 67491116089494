import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IPopularLocationUpdate } from '../types';
import type { PopularLocationLoaderType } from '../queries';

import { usePopularLocationQuery, usePopularLocationUpdate } from '../queries';
import { routes } from '@/app/router/routes';
import { useValidation } from '@/hooks/useValidation';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const PopularLocationUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPopularLocationUpdate>();
  const initialData = useLoaderData() as PopularLocationLoaderType;
  const { validate } = useValidation();
  const { data: popularLocation, isLoading: isLoadingQuery } = usePopularLocationQuery(initialData);
  const { mutate: popularLocationUpdate, isLoading: isLoadingMutation } = usePopularLocationUpdate();

  const submitHandler = (data: IPopularLocationUpdate) => {
    popularLocationUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('popularLocations.form.updateTitle')}
        description={t('popularLocations.form.updateDescription')}
        navigateBackUrl={routes.popularLocations()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='popularLocationUpdate'
          form={form}
          initialValues={{ ...popularLocation, location: popularLocation.location.id }}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon initialLocation={popularLocation.location} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
