import { App } from 'antd';

import type { NotificationPlacement } from 'antd/es/notification/interface';

import { NotificationType } from '@/utils/notification';
import { useTranslation } from 'react-i18next';

export const useNotification = () => {
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const translateByKey = (key?: string) => key && t(key, { defaultValue: key });

  const createNotification = (
    type: NotificationType,
    messageKey: string,
    descriptionKey: string | undefined = undefined,
    placement: NotificationPlacement | undefined = 'topRight',
  ) => {
    notification[type]({
      message: translateByKey(messageKey),
      description: translateByKey(descriptionKey),
      placement,
    });
  };

  const successNotification = (
    messageKey: string,
    descriptionKey: string | undefined = undefined,
    placement: NotificationPlacement | undefined = 'topRight',
  ) => {
    createNotification(NotificationType.success, messageKey, descriptionKey, placement);
  };

  const infoNotification = (
    messageKey: string,
    descriptionKey: string | undefined = undefined,
    placement: NotificationPlacement | undefined = 'topRight',
  ) => {
    createNotification(NotificationType.info, messageKey, descriptionKey, placement);
  };

  const errorNotification = (
    messageKey: string,
    descriptionKey: string | undefined = undefined,
    placement: NotificationPlacement | undefined = 'topRight',
  ) => {
    createNotification(NotificationType.error, messageKey, descriptionKey, placement);
  };

  return { successNotification, infoNotification, errorNotification };
};
