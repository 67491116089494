import { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface SwitchMapProps {
  disabled: boolean;
  initialValue: string;
}

export const SwitchMap = ({ disabled, initialValue, ...restProps }: SwitchMapProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if ('onChange' in restProps && typeof restProps.onChange === 'function') {
      if ('checked' in restProps && typeof restProps.checked === 'string') {
        restProps.onChange(Boolean(Number.parseInt(initialValue)));
      }
    }

    setVisible(true);
  }, [initialValue, restProps]);

  return (
    <>
      {visible && (
        <Switch disabled={disabled} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} {...restProps} />
      )}
    </>
  );
};
