import { useMutation } from '@tanstack/react-query';

import type { IFile } from '@/types/global';

import { uploadService } from './service';

export const useUpload = (onSuccess: (file: IFile) => void) => {
  const mutationInfo = useMutation({
    mutationFn: (file: FormData) => uploadService.sendFile(file),
    onSuccess,
  });

  return mutationInfo;
};
