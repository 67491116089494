import styled from 'styled-components';

import type { GlobalToken } from 'antd';

interface BottomMenuContainerProps {
  token: GlobalToken;
}

export const BottomMenuContainer = styled.div<BottomMenuContainerProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  padding: 0 24px;
  border-radius: 8px;
  z-index: 999;
  width: 36rem;
  height: 5.4rem;
  text-align: center;
  font-size: 2rem;
  background-color: ${props => props.token.colorPrimary};
`;
