import { useContext } from 'react';
import { theme } from 'antd';

import { MapContext } from '../../MapContext';
import { FloorsIcon } from '../../assets';

import { DropdownStyled, DropdownIcon } from './style';
import { useFloorsMenuQuery } from './queries';

interface FloorsMenuProps {
  changeFloor: (newFloor: string) => void;
}

export const FloorsMenu = ({ changeFloor }: FloorsMenuProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currentFloor } = useContext(MapContext) as any;
  const { data: floorsMenu, isLoading } = useFloorsMenuQuery(changeFloor);
  const { token } = theme.useToken();

  return (
    <DropdownStyled
      menu={{
        items: floorsMenu,
        selectable: true,
        selectedKeys: [currentFloor],
      }}
      trigger={['click']}
      disabled={isLoading}
    >
      <DropdownIcon token={token}>
        <FloorsIcon />
      </DropdownIcon>
    </DropdownStyled>
  );
};
