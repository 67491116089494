import { useQuery } from '@tanstack/react-query';

import { deviceService } from '@/pages/Devices/service';

export const useDevicesNoPaginateQuery = () => {
  const queryInfo = useQuery({
    queryKey: ['devices', 'select'],
    queryFn: () => deviceService.getDevicesNoPaginate(),
    select: devices => devices.map(device => ({ label: device.namePl, value: device.id })),
  });

  return queryInfo;
};
