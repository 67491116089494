import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';

export const FormCommon = () => {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <>
      <Form.Item name='symbol' label={t('units.symbol')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='namePl' label={t('units.namePL')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='nameEn' label={t('units.nameEN')} rules={validate('required')}>
        <Input />
      </Form.Item>
    </>
  );
};
