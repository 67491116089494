import { routes } from '@/app/router/routes';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Result
      status='404'
      title={t('notFound.title')}
      subTitle={t('notFound.description')}
      extra={
        <Button type='primary' href={routes.base()}>
          {t('notFound.extraTitle')}
        </Button>
      }
    />
  );
};
