import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IDeviceCreate } from '../types';

import { useDeviceCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

export const DeviceCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IDeviceCreate>();
  const { mutate: deviceCreate, isLoading } = useDeviceCreate();

  const submitHandler = (data: IDeviceCreate) => {
    deviceCreate(data);
  };

  return (
    <>
      <PageHeader
        title={t('devices.form.createTitle')}
        description={t('devices.form.createDescription')}
        navigateBackUrl={routes.devices()}
      />
      <Form name='deviceCreate' form={form} requiredMark='optional' layout='vertical' onFinish={submitHandler} scrollToFirstError>
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
