import { profileService } from '@/pages/Profiles/service';
import { useQuery } from '@tanstack/react-query';

export const useFloorsMenuQuery = (changeFloor: (newFloor: string) => void) => {
  const queryInfo = useQuery({
    queryKey: ['profiles', 'floorMenu'],
    queryFn: () => profileService.getProfilesNoPaginate(),
    select: profiles =>
      profiles.map(profile => ({ label: profile.label, key: `p${profile.key}`, onClick: () => changeFloor(`p${profile.key}`) })),
  });

  return queryInfo;
};
