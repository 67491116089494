import { useMemo } from 'react';

import { DrawShape, MapObjectGroup, MapObjectGroupValues } from '@/pages/Map/enums';
import { assertUnreachable } from '@/utils/assertUnreachable';

import { SelectStyled } from './style';
import { useTranslation } from 'react-i18next';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

interface SelectObjectTypeProps {
  shape: DrawShape;
  handleChange: () => void;
}

export const SelectObjectType = ({ shape, handleChange }: SelectObjectTypeProps) => {
  const { t } = useTranslation();
  const { translate } = useMapObjectTranslation();

  const type = useMemo(() => {
    if (!shape) return;

    switch (shape) {
      case DrawShape.point:
        return MapObjectGroup.point;

      case DrawShape.line:
        return MapObjectGroup.line;

      case DrawShape.polygon:
      case DrawShape.circle:
      case DrawShape.square:
      case DrawShape.box:
        return MapObjectGroup.polygon;

      default:
        assertUnreachable(shape);
    }
  }, [shape]);

  const options = useMemo(
    () =>
      type
        ? MapObjectGroupValues[type].map(mapObject => ({
            label: translate(mapObject),
            value: mapObject,
          }))
        : [],
    [translate, type],
  );

  return (
    <SelectStyled
      showSearch
      options={options}
      placeholder={t('map.editMode.mapObjectAside.chooseMapObjectType')}
      onChange={handleChange}
    />
  );
};
