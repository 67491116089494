import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';

import { Upload } from '@/ui/Upload';

import { FileType } from '@/ui/Upload/types';

interface FormCommonProps {
  initialPhotoUrl?: string;
}

export const FormCommon = ({ initialPhotoUrl }: FormCommonProps) => {
  const { t } = useTranslation();
  const { validate } = useValidation();
  return (
    <>
      <Form.Item name='namePl' label={t('pushNotifications.namePL')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='nameEn' label={t('pushNotifications.nameEN')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='descriptionPl' label={t('pushNotifications.textPL')} rules={validate('required')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name='descriptionEn' label={t('pushNotifications.textEN')} rules={validate('required')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name='photo' label={t('pushNotifications.photo')} rules={validate('required')}>
        <Upload fileType={FileType.photo} initialUrl={initialPhotoUrl} />
      </Form.Item>
    </>
  );
};
