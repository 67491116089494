import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IPlaceNotificationCreate } from '../types';

import { usePlaceNotificationCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

import { NotificationType } from '@/types/global';

export const PlaceNotificationCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPlaceNotificationCreate>();
  const { mutate: placeNotificationCreate, isLoading } = usePlaceNotificationCreate();

  const submitHandler = (data: IPlaceNotificationCreate) => {
    placeNotificationCreate({ ...data, type: NotificationType.place });
  };

  return (
    <>
      <PageHeader
        title={t('placeNotifications.form.createTitle')}
        description={t('placeNotifications.form.createDescription')}
        navigateBackUrl={routes.placeNotifications()}
      />
      <Form
        name='placeNotificationCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
