import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IAnnouncementCreate, IAnnouncementUpdate } from './types';

import { announcementsService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const announcementsQuery = (page: number) => ({
  queryKey: ['announcements', 'list', page],
  queryFn: () => announcementsService.getAnnouncements(page),
  staleTime: 1000 * 60 * 24,
});

export const announcementsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = announcementsQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type AnnouncementsLoaderType = Awaited<ReturnType<ReturnType<typeof announcementsLoader>>>;

export const useAnnouncementsQuery = (initialData: AnnouncementsLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...announcementsQuery(page),
    initialData,
  });

  return queryInfo;
};

const announcementQuery = (id: number) => ({
  queryKey: ['announcements', 'details', id],
  queryFn: () => announcementsService.getAnnouncement(id),
});

export const announcementLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = announcementQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type AnnouncementLoaderType = Awaited<ReturnType<ReturnType<typeof announcementLoader>>>;

export const useAnnouncementQuery = (initialData: AnnouncementLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...announcementQuery(id),
    initialData,
  });

  return queryInfo;
};

export const useAnnouncementCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IAnnouncementCreate) => announcementsService.createAnnouncement(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['announcements', 'list'] });
      successNotification('announcements.notification.createMessage');
      navigate(routes.announcements());
    },
  });

  return mutationInfo;
};

export const useAnnouncementUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IAnnouncementUpdate) => announcementsService.updateAnnouncement(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['announcements', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['announcements', 'details', variables.id] });
      successNotification('announcements.notification.updateMessage');
      navigate(routes.announcements());
    },
  });

  return mutationInfo;
};

export const useAnnouncementDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => announcementsService.deleteAnnouncement(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['announcements', 'list'] });
      successNotification('announcements.notification.deleteMessage');
    },
  });

  return mutationInfo;
};
