import { Form } from 'antd';

import { ComponentSwitcher } from './ComponentSwitcher';
import { useValidation } from '@/hooks/useValidation';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';
import { MapObjectComponentType } from '@/pages/Map/enums';

export const MapObjectForm = ({ selectedTypeAttr, geoCenter, featureId, featureToEdit }) => {
  const { validate } = useValidation();
  const { translateLabel } = useMapObjectTranslation();

  return selectedTypeAttr.map(attr =>
    attr.showable ? (
      <Form.Item
        key={attr.key}
        name={attr.key}
        label={translateLabel(attr.label)}
        required={attr.required}
        initialValue={featureToEdit ? featureToEdit[attr.key] : attr.default_value}
        rules={attr.required && validate('required')}
        valuePropName={attr.type === MapObjectComponentType.switch ? 'checked' : undefined}
      >
        <ComponentSwitcher
          componentType={attr.type}
          fieldType={attr.key}
          disabled={attr.read_only}
          geoCenter={geoCenter}
          featureId={featureId}
          initialValue={featureToEdit ? featureToEdit[attr.key] : attr.default_value}
        />
      </Form.Item>
    ) : null,
  );
};
