import { useContext, useMemo } from 'react';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import { MapContext } from '@/pages/Map/MapContext';

import { MapObjectForm } from './MapObjectForm';
import { AsideContainer } from '../../AsideContainer';
import { Loading } from '@/ui/Loading';

import { useTypesData } from '../useTypesData';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';
import { successNotification } from '@/utils/notification';
import { findGeoCenter } from '@/pages/Map/utils/findGeoCenter';
import { getFeaturePropertiesFromDTO, getFeaturePropertiesToDTO } from './dto';

import { FormStyled } from './style';

export const MapObjectUpdate = ({ open, mask, handleClose }) => {
  const { featureToEdit, addToEditFeatures, setFeatureToEdit } = useContext(MapContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { types, typesLoading } = useTypesData();
  const { translate } = useMapObjectTranslation();

  const geoCenter = useMemo(() => {
    if (isEmpty(featureToEdit)) {
      return [];
    }

    return findGeoCenter(featureToEdit);
  }, [featureToEdit]);

  const featureType = useMemo(() => {
    if (isEmpty(featureToEdit)) {
      return null;
    }

    return featureToEdit.getProperties().type;
  }, [featureToEdit]);

  const selectedType = useMemo(() => {
    if (!featureType) {
      return null;
    }

    return types.find(element => element.key === featureType);
  }, [types, featureType]);

  const onCloseAside = () => {
    handleClose();
    form.setFieldsValue(() => null);
  };

  // !!! do not change the order of calls
  const handleSubmit = values => {
    featureToEdit.setProperties(getFeaturePropertiesToDTO(selectedType.geometry_attributes, values));
    addToEditFeatures(featureToEdit);
    setFeatureToEdit();
    successNotification(t('map.editMode.mapObjectAside.successUpdate'));
    form.setFieldsValue(() => null);
  };

  return (
    <AsideContainer
      title={featureType && translate(featureType)}
      mask={mask}
      maskClosable={false}
      open={open}
      handleClose={onCloseAside}
    >
      <Loading loading={typesLoading}>
        <FormStyled form={form} onFinish={handleSubmit} layout='vertical' requiredMark='optional' scrollToFirstError>
          {selectedType?.geometry_attributes && featureToEdit && (
            <>
              <MapObjectForm
                selectedTypeAttr={selectedType.geometry_attributes}
                geoCenter={geoCenter}
                featureId={featureToEdit.getId()}
                featureToEdit={getFeaturePropertiesFromDTO(selectedType.geometry_attributes, featureToEdit.getProperties())}
              />
              <Button type='primary' htmlType='submit'>
                {t('map.editMode.mapObjectAside.submit')}
              </Button>
            </>
          )}
        </FormStyled>
      </Loading>
    </AsideContainer>
  );
};
