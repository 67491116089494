import { useQuery } from '@tanstack/react-query';

import { accessControlService } from './service';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

export const useAccessControlsQuery = () => {
  const { translateLabel } = useMapObjectTranslation();

  const queryInfo = useQuery({
    queryKey: ['accessControls', 'select'],
    queryFn: () => accessControlService.getAccessControls(),
    select: accessControls => Object.values(accessControls).map(value => ({ label: translateLabel(value), value })),
  });

  return queryInfo;
};
