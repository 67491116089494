import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import typesActions from '@/redux/types/actions';
import { getLoadingStatus, getTypes } from '@/redux/types/selectors';

export const useTypesData = () => {
  const dispatch = useDispatch();
  const types = useSelector(getTypes);
  const typesLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    dispatch(typesActions.loadTypes());
  }, [dispatch]);

  return { types, typesLoading };
};
