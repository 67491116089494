import { MapButtonStyled } from './style';

interface MapButtonProps {
  label: string;
  handleClick: () => void;
  rightDistance: number;
}

export const MapButton = ({ label, handleClick, rightDistance }: MapButtonProps) => (
  <MapButtonStyled type='primary' onClick={handleClick} distance={rightDistance}>
    {label}
  </MapButtonStyled>
);
