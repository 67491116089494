import { useQuery } from '@tanstack/react-query';

import { LoadingBlock } from '@/ui/Loading';

import { unitService } from '@/pages/Units/service';

interface UnitObjectProps {
  id: number;
}

export const UnitObject = ({ id }: UnitObjectProps) => {
  const {
    data: unit,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['units', 'details', id],
    queryFn: () => unitService.getUnit(id),
    enabled: !!id,
  });

  return <LoadingBlock loading={isLoading && isFetching}>{unit?.symbol}</LoadingBlock>;
};
