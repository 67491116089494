import { useSearchParams } from 'react-router-dom';

import type { TablePaginationConfig } from 'antd';

export const usePagination = (total: number, pageSize: number, returnOnChange = true): TablePaginationConfig => {
  const [searchParams, setSearchParams] = useSearchParams();

  let page = 1;
  const paramsPage = searchParams.get('page');
  if (paramsPage && Number.isInteger(Number.parseInt(paramsPage))) {
    page = Number.parseInt(paramsPage);
  }

  const handlePageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  return {
    current: page,
    onChange: returnOnChange ? handlePageChange : undefined,
    hideOnSinglePage: true,
    total: total,
    pageSize: pageSize,
    showSizeChanger: false,
  };
};
