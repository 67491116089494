import GeoJSON from 'ol/format/GeoJSON';
import Projection from 'ol/proj/Projection';

import { MapSettings } from '../enums';

const createProjection = () => {
  return new Projection({
    code: MapSettings.projection,
  });
};

const createGeoJson = () => {
  const proj = createProjection();
  return new GeoJSON({
    featureProjection: proj,
  });
};

export default class GeoJsonUtils {
  static createProjection = createProjection;
  static createGeoJson = createGeoJson;
}
