import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export const NotEmptyCollectionsModal = ({ setVisible, clearCollections, visible }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('map.editMode.modalNotEmptyCollection.title')}
      okText={t('map.editMode.modalNotEmptyCollection.confirmButtonTitle')}
      cancelText={t('map.editMode.modalNotEmptyCollection.cancelButtonTitle')}
      open={visible}
      onOk={clearCollections}
      onCancel={setVisible}
      getContainer={false}
      centered
    >
      {t('map.editMode.modalNotEmptyCollection.text')}
    </Modal>
  );
};
