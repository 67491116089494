import type { SorterResult } from 'antd/es/table/interface';
import type { SetURLSearchParams } from 'react-router-dom';

export interface ISorting {
  orderBy?: string;
  orderType?: 'asc' | 'desc';
}

const getOrderTypeDTO = <T>(order: SorterResult<T>['order']): ISorting['orderType'] => {
  switch (order) {
    case 'ascend':
      return 'asc';
    case 'descend':
      return 'desc';

    default:
      return;
  }
};

export const setSortingSearchParams = <T>(
  sorterResult: SorterResult<T>,
  currentPage?: number,
  searchParams?: URLSearchParams,
  setSearchParams?: SetURLSearchParams,
) => {
  const sorting = {
    orderBy: sorterResult.field?.toString() ?? '',
    orderType: getOrderTypeDTO(sorterResult.order),
  };

  if (searchParams && setSearchParams) {
    const oldValues = Object.fromEntries(searchParams);
    const page = (currentPage ?? 1).toString();

    if (sorting.orderBy && sorting.orderType) {
      setSearchParams({ ...oldValues, orderBy: sorting.orderBy, orderType: sorting.orderType, page: page });
    } else {
      delete oldValues.orderBy;
      delete oldValues.orderType;

      setSearchParams({ ...oldValues, page: page });
    }
  }
};

const getOrderTypeTable = <T>(order: string): SorterResult<T>['order'] => {
  switch (order) {
    case 'asc':
      return 'ascend';
    case 'desc':
      return 'descend';

    default:
      return undefined;
  }
};

export const getSortingTable = <T>(searchParams: URLSearchParams): SorterResult<T> => {
  const orderBy = searchParams.get('orderBy') ?? undefined;
  const orderType = searchParams.get('orderType') ?? undefined;

  return {
    field: orderBy?.toString() ?? undefined,
    columnKey: orderBy?.toString() ?? undefined,
    order: orderType ? getOrderTypeTable(orderType) : undefined,
  };
};
