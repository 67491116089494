import pl_PL from 'antd/locale/pl_PL';
import en_US from 'antd/locale/en_US';

import { getDefaultLanguage } from './i18n';

export enum Language {
  PL = 'pl',
  EN = 'en',
}

export const defaultLanguage = Language.PL;

export const localStorageLanguageKey = 'appLang';

export const getAntdLocales = () => (getDefaultLanguage() === Language.PL ? pl_PL : en_US);
