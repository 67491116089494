import { useParams, useSearchParams } from 'react-router-dom';

import type { Params } from 'react-router-dom';

export const getSearchParamsFromRequest = (request: Request, initialParam?: { name: string; value: string }) => {
  const searchParams = new URL(request.url).searchParams;

  if (!initialParam) {
    return searchParams;
  }

  const initialSearchParamValue = searchParams.get(initialParam.name);

  if (!initialSearchParamValue) {
    searchParams.set(initialParam.name, initialParam.value);
  }

  return searchParams;
};

export const getPageFromRequest = (request: Request) => {
  const page = new URL(request.url).searchParams.get('page');

  return page ? Number.parseInt(page) : 1;
};

export const usePageFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  return page ? Number.parseInt(page) : 1;
};

export const getIdFromParams = (params: Params) => {
  const { id } = params;

  return id ? Number.parseInt(id) : 0;
};

export const useIdFromParams = () => {
  const { id } = useParams();

  return id ? Number.parseInt(id) : 0;
};

export const addParamToSearchParams = (searchParams: URLSearchParams, param?: { name: string; value: string }) => {
  if (!param) {
    return searchParams;
  }

  const paramSearchParamsValue = searchParams.get(param.name);

  if (!paramSearchParamsValue) {
    searchParams.set(param.name, param.value);
  }

  return searchParams;
};
