import { mapsUrl } from '@/settings';

export const mapConfig = {
  centerPosition: [19.453383223199438, 51.750138932202276],
  xyz: mapsUrl,
  xyzEnd: '{z}/{x}/{y}.png',
};

export const tileConfig = {
  minZoom: 12,
  maxZoom: 22,
};
