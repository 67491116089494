import { InteractionMenuActionType } from '../enums';

const initialStateInteractionMenuReducer = {
  draw: null,
  modify: false,
  select: true,
  snap: false,
};

const interactionMenuReducer = (state, action) => {
  switch (action.type) {
    case InteractionMenuActionType.clickDraw:
      return { ...state, draw: action.payload, modify: false, select: false };

    case InteractionMenuActionType.clickModify:
      return { ...state, draw: null, modify: true, select: false };

    case InteractionMenuActionType.clickSelect:
      return { ...state, draw: null, modify: false, select: true };

    case InteractionMenuActionType.clickSnap:
      return { ...state, snap: !state.snap };

    default:
      return state;
  }
};

export { initialStateInteractionMenuReducer, interactionMenuReducer };
