import { useContext, useState, useEffect } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import { View } from 'ol';

import { MapContext } from '@/pages/Map/MapContext';
import { viewConfig } from '@/pages/Map/utils';

import { RotateResetButton } from './style';

export const RotateReset = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { map } = useContext(MapContext) as any;
  const [view, setView] = useState<View>();

  const handleClick = () => {
    if (!view) {
      return;
    }
    view.setRotation(viewConfig.defaultRotation);
  };

  useEffect(() => {
    if (!map) {
      return;
    }

    setView(map.getView());
  }, [map]);

  return (
    <RotateResetButton onClick={handleClick}>
      <ArrowUpOutlined />
    </RotateResetButton>
  );
};
