import { GlobalToken } from 'antd';
import styled from 'styled-components';

export const MapBeaconStyled = styled.div`
  height: 50rem;
  width: 100%;
`;

interface ProfileProps {
  token: GlobalToken;
}

export const Profile = styled.div<ProfileProps>`
  height: 0;
  position: relative;
  top: 2rem;
  left: 2rem;
  z-index: 50;
  font-size: 2.4rem;
  color: ${({ token }) => token.colorPrimary};
  font-weight: bold;
`;
