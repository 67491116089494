import { Dispatch, SetStateAction, createContext } from 'react';
import { Map as MapOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';
import { IBeacon } from '../types';

interface IMapStateContext {
  map?: MapOl;
  tileSource: XYZSourceOl;
  vectorSource: VectorSourceOl;
  mapProfile: string;
}

interface IMapDispatchContext {
  handleChangeMapProfile: (arg1: number) => void;
  handleChangeBatteryScope: (arg1?: string) => void;
  setSelectedFeature: Dispatch<SetStateAction<IBeacon | null>>;
}

export const MapStateContext = createContext<IMapStateContext | undefined>(undefined);
export const MapDispatchContext = createContext<IMapDispatchContext | undefined>(undefined);
