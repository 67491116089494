import { useContext } from 'react';

import { MapContext } from '../../MapContext';

import { useCopy, usePaste } from '../../Interactions/CopyPaste';
import { useMerge } from '../../Interactions/Merge/useUnion';
import { useDifference } from '../../Interactions/Merge/useDifference';

import { DrawShape, InteractionMenuActionType } from '../../enums';

export const useInteractionsMenuItemsActions = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setMapMenuState, setVisibleModalDelete, setVisibleHelpModal } = useContext(MapContext) as any;

  const handleClickCopy = useCopy(false);
  const handleClickPaste = usePaste();
  const handleClickMerge = useMerge();
  const handleClickDifference = useDifference();

  const handleClickSelect = () => {
    setMapMenuState({ type: InteractionMenuActionType.clickSelect });
  };

  const handleClickDraw = (type: DrawShape) => {
    setMapMenuState({ type: InteractionMenuActionType.clickDraw, payload: type });
  };

  const handleClickModify = () => {
    setMapMenuState({ type: InteractionMenuActionType.clickModify });
  };

  const handleClickDelete = () => {
    setVisibleModalDelete();
    setMapMenuState({ type: InteractionMenuActionType.clickSelect });
  };

  const handleClickHelp = () => {
    setVisibleHelpModal();
  };

  return {
    handleClickSelect,
    handleClickDraw,
    handleClickModify,
    handleClickCopy,
    handleClickPaste,
    handleClickMerge,
    handleClickDifference,
    handleClickDelete,
    handleClickHelp,
  };
};
