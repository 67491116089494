import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { INavigationCueCreate } from '../types';

import { useNavigationCueCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

export const NavigationCueCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<INavigationCueCreate>();
  const { mutate: navigationCueCreate, isLoading } = useNavigationCueCreate();

  const submitHandler = (data: INavigationCueCreate) => {
    navigationCueCreate(data);
  };

  return (
    <>
      <PageHeader
        title={t('navigationCues.form.createTitle')}
        description={t('navigationCues.form.createDescription')}
        navigateBackUrl={routes.navigationCues()}
      />
      <Form
        name='navigationCueCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
        initialValues={{
          isNotification: false,
        }}
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
