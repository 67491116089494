import { useRef, useState, useEffect, ReactNode, useMemo } from 'react';
import { Map as MapOl, View as ViewOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { fromLonLat } from 'ol/proj';
import { feature } from '@turf/helpers';
import { theme } from 'antd';

import type { Coordinate } from 'ol/coordinate';
import type { ISingleBeaconMap } from '../../types';
import type { IProfile } from '@/pages/Profiles/types';

import { mapConfig, tileConfig } from './config';
import { MapBeaconStateContext } from './MapBeaconContext';
import { changeBeaconColorToBattery } from '../../Map/utils';

import { MapBeaconStyled, Profile } from './styles';
import 'ol/ol.css';
import { useTranslation } from 'react-i18next';

interface MapBeaconContainerProps {
  vectorData: ISingleBeaconMap;
  profiles: IProfile[];
  mapProfile: number;
  children: ReactNode;
}

export const MapBeaconContainer = ({ vectorData, profiles, mapProfile, children }: MapBeaconContainerProps) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<MapOl>();
  const tileSource = useMemo<XYZSourceOl>(
    () =>
      new XYZSourceOl({
        url: `${mapConfig.xyz}/${`p${mapProfile}`}/${mapConfig.xyzEnd}`,
        minZoom: tileConfig.minZoom,
        maxZoom: tileConfig.maxZoom,
      }),
    [mapProfile],
  );
  const vectorSource = useMemo<VectorSourceOl>(
    () =>
      new VectorSourceOl({
        format: new GeoJSON(),
      }),
    [],
  );

  useEffect(() => {
    const options = {
      view: new ViewOl({
        center: fromLonLat(vectorData.geometry.coordinates as Coordinate),
        zoom: mapConfig.zoom,
        minZoom: mapConfig.minZoom,
        maxZoom: mapConfig.maxZoom,
        constrainResolution: mapConfig.constrainResolution,
      }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new MapOl(options);

    if (mapRef.current) {
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
    }

    return () => {
      mapObject.setTarget(undefined);
    };
  }, [vectorData]);

  useEffect(() => {
    const beacon = new GeoJSON().readFeature(feature(vectorData.geometry, vectorData.properties, { id: vectorData.id }), {
      featureProjection: mapConfig.featureProjection,
    });
    vectorSource.clear();
    vectorSource.addFeature(beacon);

    changeBeaconColorToBattery(beacon, 'form');
  }, [vectorData, vectorSource]);

  return (
    <MapBeaconStateContext.Provider value={{ map, tileSource, vectorSource }}>
      <Profile token={token}>{`${t('beacons.mapProfile')} ${
        profiles.find(profile => profile.key === mapProfile)?.label ?? ''
      }`}</Profile>
      <MapBeaconStyled ref={mapRef}>{children}</MapBeaconStyled>
    </MapBeaconStateContext.Provider>
  );
};
