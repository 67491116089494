import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Collection, View, Map as MapOl } from 'ol';
import { GeoJSON } from 'ol/format';
import { fromLonLat, toLonLat } from 'ol/proj';
import { defaults as defaultInteraction } from 'ol/interaction';
import { getTopLeft, getBottomRight } from 'ol/extent';
import { Vector as VectorSource, XYZ as XYZSource } from 'ol/source';
import { Tile as TileLayer } from 'ol/layer';

import { infoNotification } from '@/utils/notification';
import { MapSettings, InteractionMenuActionType } from './enums';

import { viewConfig } from './utils/viewConfig';
import { mapConfig, tileConfig } from './mapConfig';
import { MapContext } from './MapContext';
import { routes } from '@/app/router/routes';

import actions from '@/redux/features/actions';
import {
  getNewFeatures,
  getEditFeatures,
  getDeleteFeatures,
  getSavingStatus,
  getFeaturesObjects,
  getExtent,
} from '@/redux/features/selectors';

const {
  addFeatureToNewFeatures,
  addFeatureToEditFeatures,
  addFeatureToDeleteFeatures,
  sendFeatures,
  loadFeatures,
  swapNewFeatures,
  swapEditFeatures,
  clearCollections,
  generateGraph,
} = actions;

const withMapContainer = WrappedComponent => {
  class Map extends Component {
    constructor(props) {
      super(props);
      this.ref = React.createRef();
      this.controller = React.createRef();
      this.controller.current = new AbortController();
      this.state = {
        vectorSource: new VectorSource({
          format: new GeoJSON(),
        }),
        selectCollection: new Collection(),
        view: this.view,
        mapObject: new MapOl(this.options),
        map: null,
        mapSource: null,
        visibleDrawerDraw: false,
        visibleDrawerSelect: false,
        visibleDrawerEdit: false,
        closeVisibleDrawerSelect: this.closeVisibleDrawerSelect,
        modalDeleteVisible: false,
        setVisibleModalDelete: this.setVisibleModalDelete,
        modalNotEmptyCollectionsVisible: false,
        setVisibleNotEmptyCollectionsModal: this.setVisibleNotEmptyCollectionsModal,
        clearCollectionsOnConfirm: this.clearCollectionsOnConfirm,
        actionAfterClear: null,
        drawnFeature: {},
        clearDrawnFeature: this.clearDrawnFeature,
        currentFloor: MapSettings.startingFloor,
        editMode: false,
        addToNewFeatures: this.addToNewFeatures,
        addToEditFeatures: this.addToEditFeatures,
        addToDeleteFeatures: this.addToDeleteFeatures,
        mapMenuState: this.mapMenuState,
        setMapMenuState: this.setMapMenuState,
        featureToEdit: {},
        setFeatureToEdit: this.setFeatureToEdit,
        visibleHelpModal: false,
        setVisibleHelpModal: this.setVisibleHelpModal,
      };
    }

    componentDidMount() {
      this.state.mapObject.setTarget(this.ref.current);
      this.setState({
        map: this.state.mapObject,
        mapSource: new XYZSource({
          url: `${mapConfig.xyz}/${MapSettings.startingFloor}/${mapConfig.xyzEnd}`,
          minZoom: tileConfig.minZoom,
          maxZoom: tileConfig.maxZoom,
        }),
        currentFloor: this.props.floor ?? MapSettings.startingFloor,
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.features !== this.props.features && (this.state.vectorSource || !this.props.features)) {
        this.state.vectorSource.clear();
        this.state.vectorSource.addFeatures(this.props.features);
      }

      if (prevState.currentFloor !== this.state.currentFloor) {
        this.props.navigate(`${routes.map()}/${this.state.currentFloor}`);

        if (this.state.editMode) {
          this.loadingFeaturesEditingMode();
        } else {
          this.setState({
            mapSource: new XYZSource({
              url: `${mapConfig.xyz}/${this.state.currentFloor}/${mapConfig.xyzEnd}`,
              minZoom: tileConfig.minZoom,
              maxZoom: tileConfig.maxZoom,
            }),
          });
        }
      }

      if (prevProps.extent !== this.props.extent && this.props.extent) {
        const extentNotLonLat = [
          ...fromLonLat([this.props.extent[0], this.props.extent[1]]),
          ...fromLonLat([this.props.extent[2], this.props.extent[3]]),
        ];
        this.setMapView(extentNotLonLat);
      }
    }

    componentWillUnmount() {
      this.state.mapObject.setTarget(undefined);
      this.props.clearCollections();
      this.controller.current.abort();
      this.state.selectCollection.clear();
    }

    mapMenuState = {
      draw: null,
      modify: false,
      select: true,
      snap: false,
    };

    view = new View({
      center: fromLonLat(mapConfig.centerPosition),
      zoom: viewConfig.zoom,
      minZoom: viewConfig.minZoom,
      maxZoom: viewConfig.maxZoom,
      rotation: viewConfig.defaultRotation, // set to 360 degrees, it gives 0 - 360 degrees scale
      constrainRotation: viewConfig.constrainRotation, // true means no constraint, but snap to zero near zero
      constrainResolution: viewConfig.constrainResolution, // the view will always animate to the closest zoom level after an interaction
    });

    options = {
      view: this.view,
      layers: [],
      controls: [],
      overlays: [],
      interactions: defaultInteraction({ shiftDragZoom: false, mouseWheelZoom: false }),
    };

    setMapView = extent => {
      this.state.map.setView(
        new View({
          center: this.state.map.getView().getCenter(),
          zoom: this.state.map.getView().getZoom(),
          minZoom: viewConfig.minZoom,
          maxZoom: viewConfig.maxZoom,
          rotation: this.state.map.getView().getRotation(),
          constrainRotation: viewConfig.constrainRotation,
          constrainResolution: viewConfig.constrainResolution,
          ...(extent && { extent: extent }),
        }),
      );
    };

    addToNewFeatures = feature => {
      this.props.addFeatureToNewFeatures(feature);

      if (!this.state.vectorSource.getFeatures().includes(feature)) {
        this.state.vectorSource.addFeature(feature);
      }
    };

    addToEditFeatures = feature => {
      if (!this.props.newFeatures.includes(feature) && !this.props.editedFeatures.includes(feature)) {
        this.props.addFeatureToEditFeatures(feature);
      }
    };

    addToDeleteFeatures = features => {
      const newFeaturesCopy = [...this.props.newFeatures];
      const editedFeaturesCopy = [...this.props.editedFeatures];

      features.forEach(feature => {
        if (this.props.newFeatures.includes(feature)) {
          newFeaturesCopy.splice(newFeaturesCopy.indexOf(feature), 1);
        } else if (this.props.editedFeatures.includes(feature)) {
          editedFeaturesCopy.splice(editedFeaturesCopy.indexOf(feature), 1);
          this.props.addFeatureToDeleteFeatures(feature);
        } else {
          this.props.addFeatureToDeleteFeatures(feature);
        }
      });

      this.props.swapNewFeatures(newFeaturesCopy);
      this.props.swapEditFeatures(editedFeaturesCopy);
    };

    isFeaturesCollectionsEmpty = () => {
      return (
        this.props.newFeatures.length === 0 && this.props.editedFeatures.length === 0 && this.props.deletedFeatures.length === 0
      );
    };

    closeVisibleDrawerDraw = () => {
      if (this.state.visibleDrawerDraw === true) {
        this.setState({
          visibleDrawerDraw: false,
        });
      }
    };

    closeVisibleDrawerEdit = () => {
      if (this.state.visibleDrawerEdit === true) {
        this.setState({
          visibleDrawerEdit: false,
        });
      }
    };

    closeVisibleDrawerSelect = () => {
      if (this.state.visibleDrawerSelect === true) {
        this.setState({
          visibleDrawerSelect: false,
        });
      }
    };

    closeVisibleAllDrawers = () => {
      this.state.selectCollection.clear();
      this.closeVisibleDrawerDraw();
      this.closeVisibleDrawerEdit();
      this.closeVisibleDrawerSelect();
    };

    loadingFeaturesEditingMode = () => {
      if (this.controller.current) {
        this.controller.current.abort();
      }
      this.controller.current = new AbortController();
      const extent = this.state.map.getView().calculateExtent();
      this.props.loadFeatures(
        this.state.currentFloor,
        toLonLat(getTopLeft(extent)),
        toLonLat(getBottomRight(extent)),
        this.controller.current.signal,
      );
    };

    storeFeatures = () => {
      if (this.isFeaturesCollectionsEmpty()) {
        infoNotification(t('map.editMode.notifications.featuresNoData'), undefined, 'bottomRight');
      } else {
        const extent = this.state.map.getView().calculateExtent();
        this.props.sendFeatures(this.state.currentFloor, toLonLat(getTopLeft(extent)), toLonLat(getBottomRight(extent)));
      }
    };

    changeFloor = value => {
      if (this.isFeaturesCollectionsEmpty()) {
        this.changeFloorOnEmptyFeatureCollections(value);
      } else {
        this.setVisibleNotEmptyCollectionsModal();
        this.setState({
          actionAfterClear: () => this.changeFloorOnEmptyFeatureCollections(value),
        });
      }
    };

    changeFloorOnEmptyFeatureCollections = value => {
      this.props.navigate(`${routes.map()}/${value}`);
      this.setState({
        currentFloor: value,
        visibleDrawerSelect: false,
      });
    };

    setSourceUrlTileLayer = floor => {
      const tileLayer = this.state.map
        .getLayers()
        .getArray()
        .find(layer => layer instanceof TileLayer);

      tileLayer.getSource().setUrl(`${mapConfig.xyz}/${floor}/${mapConfig.xyzEnd}`);
    };

    enableEditMode = () => {
      this.setSourceUrlTileLayer(MapSettings.defaultFloor);
      this.loadingFeaturesEditingMode();
    };

    disableEditMode = () => {
      this.setSourceUrlTileLayer(this.state.currentFloor);
      this.state.vectorSource.clear();
      this.closeVisibleAllDrawers();
      this.setMapView(null);
    };

    switchEditMode = () => {
      if (this.isFeaturesCollectionsEmpty()) {
        this.switchEditModeOnEmptyFeatureCollections();
      } else {
        this.setVisibleNotEmptyCollectionsModal();
        this.setState({
          actionAfterClear: this.switchEditModeOnEmptyFeatureCollections,
        });
      }
    };

    switchEditModeOnEmptyFeatureCollections = () => {
      this.setState(prevState => {
        prevState.editMode ? this.disableEditMode() : this.enableEditMode();
        return {
          ...prevState,
          editMode: !prevState.editMode,
          mapMenuState: this.mapMenuState,
        };
      });
    };

    onSelect = () => {
      this.setState(() => {
        if (this.state.selectCollection.getLength() !== 1) {
          return { visibleDrawerSelect: false };
        } else {
          return {
            visibleDrawerSelect: true,
            visibleDrawerDraw: false,
            visibleDrawerEdit: false,
          };
        }
      });
    };

    setVisibleDraw = (isCalledByOnClose = false) => {
      this.state.selectCollection.clear();
      if (this.state.visibleDrawerDraw && this.state.vectorSource.getFeatures().includes(this.state.drawnFeature)) {
        this.state.vectorSource.removeFeature(this.state.drawnFeature);
        this.clearDrawnFeature();
      }

      this.setState(prevProps => {
        return {
          ...prevProps,
          visibleDrawerDraw: isCalledByOnClose ? false : !prevProps.visibleDrawerDraw,
          visibleDrawerSelect: false,
          visibleDrawerEdit: false,
        };
      });
    };

    setVisibleSelect = (isCalledByOnClose = false) => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          visibleDrawerSelect: isCalledByOnClose ? false : !prevProps.visibleDrawerSelect,
          visibleDrawerDraw: false,
          visibleDrawerEdit: false,
        };
      });
    };

    setVisibleEdit = (isCalledByOnClose = false) => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          visibleDrawerEdit: isCalledByOnClose ? false : !prevProps.visibleDrawerEdit,
          visibleDrawerSelect: isCalledByOnClose ? true : !prevProps.visibleDrawerSelect,
          visibleDrawerDraw: false,
        };
      });
    };

    setFeatureToEdit = (feature = null) => {
      this.setState({
        featureToEdit: feature,
      });
      this.setVisibleEdit();
    };

    onDrawend = event => {
      this.setState({
        drawnFeature: event.feature,
      });
      this.setVisibleDraw();
    };

    clearDrawnFeature = () => {
      this.setState({
        drawnFeature: {},
      });
    };

    onModifyend = event => {
      const edited = event.features.getArray();
      edited.forEach(element => {
        this.addToEditFeatures(element);
      });
    };

    setVisibleNotEmptyCollectionsModal = () => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          modalNotEmptyCollectionsVisible: !prevProps.modalNotEmptyCollectionsVisible,
        };
      });
    };

    clearCollectionsOnConfirm = () => {
      this.state.selectCollection.clear();
      this.props.clearCollections();
      this.setVisibleNotEmptyCollectionsModal();
      this.state.actionAfterClear();
    };

    setVisibleModalDelete = () => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          modalDeleteVisible: !prevProps.modalDeleteVisible,
        };
      });
      this.setMapMenuState({ type: InteractionMenuActionType.clickSelect });
    };

    setVisibleHelpModal = () => {
      this.setState(prevProps => {
        return {
          ...prevProps,
          visibleHelpModal: !prevProps.visibleHelpModal,
        };
      });
    };

    handleModalDeleteConfirm = () => {
      this.setVisibleModalDelete();
      this.deleteFeature();
    };

    deleteFeature = () => {
      this.state.selectCollection.forEach(item => {
        this.state.vectorSource.removeFeature(item);
      });
      this.addToDeleteFeatures(this.state.selectCollection);
      this.state.selectCollection.clear();

      if (this.state.visibleDrawerSelect === true) {
        this.setVisibleSelect();
      }
    };

    setMapMenuState = ({ type, payload = null }) => {
      switch (type) {
        case InteractionMenuActionType.clickDraw:
          this.setState(prevProps => {
            return {
              ...prevProps,
              mapMenuState: { ...prevProps.mapMenuState, draw: payload, modify: false, select: false },
            };
          });
          break;
        case InteractionMenuActionType.clickModify:
          this.setState(prevProps => {
            return {
              ...prevProps,
              mapMenuState: { ...prevProps.mapMenuState, draw: null, modify: true, select: false },
            };
          });
          break;
        case InteractionMenuActionType.clickSelect:
          this.setState(prevProps => {
            return {
              ...prevProps,
              mapMenuState: { ...prevProps.mapMenuState, draw: null, modify: false, select: true },
            };
          });
          break;
        case InteractionMenuActionType.clickSnap:
          this.setState(prevProps => {
            return {
              ...prevProps,
              mapMenuState: { ...prevProps.mapMenuState, snap: !prevProps.mapMenuState.snap },
            };
          });
          break;

        default:
          return;
      }
    };

    render() {
      window.onbeforeunload = () => (this.isFeaturesCollectionsEmpty() ? undefined : true);

      return (
        <React.Fragment>
          <MapContext.Provider value={{ ...this.state }}>
            <WrappedComponent
              {...this.props}
              ref={this.ref}
              loadingFeaturesEditingMode={this.loadingFeaturesEditingMode}
              storeFeatures={this.storeFeatures}
              featuresToLoad={this.props.features}
              floor={this.props.floor}
              switchEditMode={this.switchEditMode}
              changeFloor={this.changeFloor}
              onSelect={this.onSelect}
              setVisibleDraw={this.setVisibleDraw}
              setVisibleSelect={this.setVisibleSelect}
              setVisibleEdit={this.setVisibleEdit}
              onDrawend={this.onDrawend}
              onModifyend={this.onModifyend}
              handleModalDeleteConfirm={this.handleModalDeleteConfirm}
              deleteFeature={this.deleteFeature}
              isBlockingWhenPrompt={!this.isFeaturesCollectionsEmpty() && !this.state.modalNotEmptyCollectionsVisible}
            />
          </MapContext.Provider>
        </React.Fragment>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Map);
};

const mapStateToProps = state => {
  return {
    newFeatures: getNewFeatures(state),
    editedFeatures: getEditFeatures(state),
    deletedFeatures: getDeleteFeatures(state),
    loading: getSavingStatus(state),
    features: getFeaturesObjects(state),
    extent: getExtent(state),
  };
};

const mapDispatchToProps = {
  addFeatureToNewFeatures,
  addFeatureToEditFeatures,
  addFeatureToDeleteFeatures,
  sendFeatures,
  loadFeatures,
  swapNewFeatures,
  swapEditFeatures,
  clearCollections,
  generateGraph,
};

export default withMapContainer;
