import type { FeatureCollection, Geometry } from '@turf/helpers';

export interface IBeacon {
  id: number;
  battery: number;
  interval: number;
  isOutside: boolean;
  mac: string;
  name: string;
  power: number;
  profile: number;
  rssi: number;
  building: string;
}

export type IBeaconUpdate = IBeacon;

export enum BatteryScope {
  empty = '0',
  low = '1',
  medium = '2',
  full = '3',
}

export interface IBuilding {
  id: number;
  name: string;
}

export interface IBeaconFilter {
  building: IBuilding['id'];
  batteryScope: BatteryScope;
  search: string;
}

export type IBeaconMap = Omit<IBeacon, 'id'>;

export type IBeaconMapCollection = FeatureCollection<Geometry, IBeaconMap>;

export type ISingleBeaconMap = {
  id: number;
  properties: IBeaconMap;
  geometry: Geometry;
};
