import HTTP from '@/utils/http';

const Features = {
  getFeatures: (floor, p1, p2) => HTTP.get(`geo/extent?profile=${floor}&p1=${p1}&p2=${p2}`),

  sendNewFeatures: newFeatures => HTTP.postGeo('geo', newFeatures, true).then(response => response.status),

  sendEditedFeatures: editedFeatures => HTTP.putGeo('geo', editedFeatures, true).then(response => response.status),

  sendDeletedFeatures: deletedFeatures => HTTP.deleteGeo('geo', deletedFeatures, true).then(response => response.status),

  generateGraph: () => HTTP.get('routing/generateGraph').then(response => response),
};

export default Features;
