import type { ThemeConfig } from 'antd/es/config-provider/context';

export const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: '#8B231D',
  },
  components: {
    Layout: {
      colorBgHeader: '#ffffff',
    },
  },
};
