import { useEffect } from 'react';
import useCopy from './useCopy';
import usePaste from './usePaste';
import useDuplicate from './useDuplicate';

const keys = {
  C: 67,
  D: 68,
  X: 88,
  V: 86,
};

const CopyPaste = ({ onCopy, onCut, onPaste }) => {
  const copy = useCopy(false, onCopy);
  const cut = useCopy(true, onCut);
  const paste = usePaste(onPaste);
  const duplicate = useDuplicate(5, -5);

  const handleCopyPaste = e => {
    if (e.ctrlKey || e.metaKey) {
      if (e.altKey) {
        switch (e.keyCode) {
          case keys.Z:
            duplicate();
            break;

          case keys.C:
            copy();
            break;

          case keys.X:
            cut();
            break;

          case keys.V:
            paste();
            break;

          default:
            return;
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleCopyPaste);

    return () => document.removeEventListener('keydown', handleCopyPaste);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CopyPaste;
