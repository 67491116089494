import actions from './actions';

const initState = {
  isLoading: false,
  selectOptions: [],
  singleObjectName: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_SELECT_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SELECT_OPTIONS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const feature = payload.data;
      feature.key = payload.featureType;
      return {
        ...state,
        isLoading: false,
        selectOptions: [...state.selectOptions, feature],
      };
    case actions.LOAD_SELECT_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.LOAD_SINGLE_OBJECT_NAME:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_SINGLE_OBJECT_NAME_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const singleFeature = {};
      singleFeature.name = payload.data;
      singleFeature.key = payload.featureType;
      return {
        ...state,
        isLoading: false,
        singleObjectName: [...state.singleObjectName, singleFeature],
      };
    case actions.LOAD_SINGLE_OBJECT_NAME_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.REMOVE_SELECT_OPTIONS:
      return {
        ...state,
        selectOptions: [],
      };

    case actions.REMOVE_SINGLE_OBJECT_NAME:
      return {
        ...state,
        singleObjectName: [],
      };

    default:
      return state;
  }
}
