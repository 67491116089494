import { useBeaconMapQuery, useMapProfilesQuery } from '../../queries';

import { LoadingMask } from '@/ui/Loading';

import { Layers } from './Layers';
import { TileLayer } from './Layers/TileLayer';
import { VectorLayer } from './Layers/VectorLayer';
import { MapBeaconContainer } from './MapBeaconContainer';

export const MapBeacon = (): JSX.Element => {
  const { data: beaconMap, isLoading: isLoadingBeaconMap } = useBeaconMapQuery();
  const { data: profiles, isLoading: isLoadingProfiles } = useMapProfilesQuery();

  return (
    <>
      {beaconMap && profiles && (
        <MapBeaconContainer vectorData={beaconMap} profiles={profiles} mapProfile={beaconMap.properties.profile}>
          <LoadingMask loading={isLoadingBeaconMap || isLoadingProfiles}>
            <Layers>
              <TileLayer zIndex={0} />
              <VectorLayer zIndex={1} />
            </Layers>
          </LoadingMask>
        </MapBeaconContainer>
      )}
    </>
  );
};
