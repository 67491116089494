import { useTranslation } from 'react-i18next';

import { MapSwitch } from '../../components/MapSwitch';

interface SwitchEditModeProps {
  handleChange: () => void;
  value: boolean;
}

export const SwitchEditMode = ({ handleChange, value }: SwitchEditModeProps) => {
  const { t } = useTranslation();

  return <MapSwitch label={t('map.editModeTitle')} handleChange={handleChange} value={value} topDistance={1} />;
};
