import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IAdminCreate } from './types';

import { adminService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getPageFromRequest, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const adminsQuery = (page: number) => ({
  queryKey: ['admins', 'list', page],
  queryFn: () => adminService.getAdmins(page),
  staleTime: 1000 * 60 * 24,
});

export const adminsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = adminsQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type AdminsLoaderType = Awaited<ReturnType<ReturnType<typeof adminsLoader>>>;

export const useAdminsQuery = (initialData: AdminsLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...adminsQuery(page),
    initialData,
  });

  return queryInfo;
};

export const useAdminCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IAdminCreate) => adminService.createAdmin(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admins', 'list'] });
      successNotification('admins.notification.createMessage');
      navigate(routes.admins());
    },
  });

  return mutationInfo;
};

export const useAdminDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => adminService.deleteAdmin(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admins', 'list'] });
      successNotification('admins.notification.deleteMessage');
    },
  });

  return mutationInfo;
};

export const useUsersQuery = (search: string) => {
  const queryInfo = useQuery({
    queryKey: ['admins', 'users', search],
    queryFn: () => adminService.getUsers(search, 100),
    select: users => users.map(user => ({ label: user.name, value: user.id })),
    enabled: !!search,
  });

  return queryInfo;
};
