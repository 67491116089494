import { all, fork } from 'redux-saga/effects';

import featuresSaga from './features/saga';
import typesSaga from './types/saga';
import geoBasicSaga from './geoBasic/saga';
import categoriesBasicSaga from './categoriesBasic/saga';
import connectorValuesSaga from './connectorValues/saga';

export function* rootSaga() {
  yield all([fork(featuresSaga), fork(typesSaga), fork(geoBasicSaga), fork(categoriesBasicSaga), fork(connectorValuesSaga)]);
}
