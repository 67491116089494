import { useCallback, useMemo, useState } from 'react';
import { Checkbox, theme } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { useFeaturesFilter } from './useFeaturesFilter';
import { useObjectTypesFeaturesFilter } from './useObjectTypesFeaturesFilter';

import featureActions from '@/redux/features/actions';

import { DropdownButton, DropdownStyled } from './style';

export const FeaturesFilter = () => {
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { filtersToFeatures, isCheckedAll } = useFeaturesFilter();
  const objectTypesFeaturesFilter = useObjectTypesFeaturesFilter();

  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        dispatch(featureActions.addToFiltersFeatures(e.target.id));
      } else dispatch(featureActions.removeFromFiltersFeatures(e.target.id));
    },
    [dispatch],
  );

  const checkAllItem = useMemo(
    () => [
      {
        key: 'checkAll',
        label: (
          <Checkbox key='checkAll' onChange={() => dispatch(featureActions.checkAllFeatures())} checked={isCheckedAll}>
            {isCheckedAll ? t('map.editMode.filter.checkAll') : t('map.editMode.filter.uncheckAll')}
          </Checkbox>
        ),
      },
    ],
    [t, dispatch, isCheckedAll],
  );

  const objectItems = useMemo(
    () => [
      ...objectTypesFeaturesFilter.map(objectType => ({
        key: objectType.value,
        label: (
          <Checkbox id={objectType.value} onChange={handleChange} checked={filtersToFeatures.includes(objectType.value)}>
            {objectType.label}
          </Checkbox>
        ),
      })),
    ],
    [handleChange, filtersToFeatures, objectTypesFeaturesFilter],
  );

  return (
    <DropdownStyled
      menu={{ items: [...checkAllItem, ...objectItems] }}
      trigger={['click']}
      open={open}
      onOpenChange={() => setOpen(prevState => !prevState)}
    >
      <DropdownButton token={token}>{t('map.editMode.filterTitle')}</DropdownButton>
    </DropdownStyled>
  );
};
