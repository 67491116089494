// TODO: throw warning from antd
import { notification } from 'antd';

import type { NotificationPlacement } from 'antd/es/notification/interface';

import { translateErrorCode } from './translation';

export enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

const createNotification = (
  type: NotificationType,
  message: string,
  description: string | undefined = undefined,
  placement: NotificationPlacement | undefined = 'topRight',
) => {
  notification[type]({
    message,
    description,
    placement,
  });
};

export const successNotification = (
  message: string,
  description: string | undefined = undefined,
  placement: NotificationPlacement | undefined = 'topRight',
) => {
  createNotification(NotificationType.success, message, description, placement);
};

export const infoNotification = (
  message: string,
  description: string | undefined = undefined,
  placement: NotificationPlacement | undefined = 'topRight',
) => {
  createNotification(NotificationType.info, message, description, placement);
};

export const errorNotification = (
  message: string,
  description: string | undefined = undefined,
  placement: NotificationPlacement | undefined = 'topRight',
) => {
  createNotification(NotificationType.error, message, description, placement);
};

export const errorNotificationWithErrorCode = (errorCodes: string[]) => {
  errorCodes.forEach(code => {
    const translatedCode = translateErrorCode(code);
    translatedCode && createNotification(NotificationType.error, translatedCode);
  });
};
