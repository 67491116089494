import { useContext, useEffect } from 'react';

import { MapContext } from '../MapContext';

import DragRotate from 'ol/interaction/DragRotate';
import { shiftKeyOnly } from 'ol/events/condition';

import { CompUtils } from '../utils';

export const DragRotateInteraction = props => {
  const { map } = useContext(MapContext);

  const options = {
    condition: shiftKeyOnly,
    duration: 0,
  };

  const events = {
    change: undefined,
    'change:active': undefined,
    error: undefined,
    propertychange: undefined,
  };

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);

    const dragRotate = new DragRotate(opts);
    map.addInteraction(dragRotate);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      dragRotate.on(eventName, olEvents[eventName]);
    }

    return () => map.removeInteraction(dragRotate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
