import { Button } from 'antd';
import styled from 'styled-components';

interface MapButtonStyledProps {
  distance: number;
}

export const MapButtonStyled = styled(Button)<MapButtonStyledProps>`
  &.ant-btn {
    position: absolute;
    top: 1rem;
    right: ${props => props.distance}rem;
    z-index: 100;
    height: 3.6rem;
    width: 16rem;
    border-radius: 8px;
    font-size: 16px;
  }
`;
