import { Feature as FeatureOl } from 'ol';
import { Geometry as GeometryOl } from 'ol/geom';
import { Circle, Fill, Stroke, Style } from 'ol/style';

import { mapStyles } from './config';

export const changeBeaconColorToBattery = (feature: FeatureOl<GeometryOl>, type: 'map' | 'form') => {
  feature.setStyle(
    new Style({
      image: new Circle({
        radius: type === 'map' ? mapStyles.beaconRadius : mapStyles.singleBeaconRadius,
        fill: new Fill({ color: feature.getProperties()['color'] ?? mapStyles.defaultBeaconColor }),
        stroke: new Stroke({ color: mapStyles.beaconStroke, width: mapStyles.beaconStrokeWidth }),
      }),
    }),
  );
};
