import { App as AppAntd, ConfigProvider as ConfigProviderAntd } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';

import { browserRouter } from '@/app/router';
import { defaultTheme } from '@/settings/themes';
import { store } from '@/redux/store';
import { GlobalStyles } from '@/settings/globalStyles';
import { getAntdLocales } from '@/settings/i18n/lang';

import '@/settings/i18n/i18n';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
// INFO: without these plugins antd will throw clone.weekday is not a function, date.locale is not a function etc.
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
// INFO: to handle advanced date formats e.g. HH:mm:ss
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const queryClient = new QueryClient();

export const App = (): JSX.Element => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfigProviderAntd theme={defaultTheme} locale={getAntdLocales()} virtual={false}>
        <GlobalStyles />
        <AppAntd>
          <RouterProvider router={browserRouter} />
        </AppAntd>
      </ConfigProviderAntd>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
