import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Language, defaultLanguage, localStorageLanguageKey } from '@/settings/i18n/lang';
import translations_en from '@/settings/i18n/translations_en.json';
import translations_pl from '@/settings/i18n/translations_pl.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLanguage = (lang: any): lang is Language => {
  return Object.values(Language).includes(lang);
};

export const getDefaultLanguage = (): string => {
  const languageFromLocalStorage = localStorage.getItem(localStorageLanguageKey);

  if (languageFromLocalStorage && isLanguage(languageFromLocalStorage)) {
    return languageFromLocalStorage;
  }

  return defaultLanguage;
};

i18n.on('languageChanged', language => {
  document.documentElement.setAttribute('lang', language);
});

const defaultNS = 'translation' as const;
const resources = {
  [Language.PL]: {
    [defaultNS]: translations_pl,
  },
  [Language.EN]: {
    [defaultNS]: translations_en,
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    defaultNS: defaultNS,
    resources: resources,
    lng: getDefaultLanguage(),
    fallbackLng: Language.PL,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;

// strongly typed i18next
declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)[typeof defaultLanguage];
  }
}
