import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IAnnouncementCreate } from '../types';

import { useAnnouncementCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

export const AnnouncementCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IAnnouncementCreate>();
  const { mutate: announcementCreate, isLoading } = useAnnouncementCreate();

  const submitHandler = (data: IAnnouncementCreate) => {
    announcementCreate(data);
  };

  return (
    <>
      <PageHeader
        title={t('announcements.form.createTitle')}
        description={t('announcements.form.createDescription')}
        navigateBackUrl={routes.announcements()}
      />
      <Form
        name='announcementCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
        initialValues={{
          isNotification: false,
        }}
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
