import React from 'react';
import { Layout } from 'antd';

import { Menu } from './Menu';
import { Header } from './Header';

import { LayoutContentStyled, LayoutStyled } from './style';

export const MainLayout = ({ children }: React.PropsWithChildren): JSX.Element => (
  <LayoutStyled>
    <Header />
    <Layout>
      <Menu />
      <LayoutContentStyled>{children}</LayoutContentStyled>
    </Layout>
  </LayoutStyled>
);
