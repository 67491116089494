import type { IFile } from '@/types/global';

import { InputMap } from './InputMap';
import { InputNumberMap } from './InputNumberMap';
import { InputProfileMap } from './InputProfileMap';
import { SelectConnectorMap } from './SelectConnectorMap';
import { SelectGeoMap } from './SelectGeoMap';
import { SelectCategoryMap } from './SelectCategoryMap';
import { SwitchMap } from './SwitchMap';
import { SelectDevice } from './SelectDevice';
import { SelectUnit } from './SelectUnit';
import { DateTimeMap } from './DateTimeMap';
import { SelectAccessControl } from './SelectAccessControl';
import { UploadMap } from './UploadMap';

import { MapObjectComponentType } from '@/pages/Map/enums';

import { assertUnreachable } from '@/utils/assertUnreachable';

interface ComponentSwitcherProps {
  componentType: MapObjectComponentType;
  fieldType: string; //TODO: clarify type
  disabled: boolean;
  geoCenter: [number, number];
  featureId: number;
  initialValue: string;
}

export const ComponentSwitcher = ({
  componentType,
  fieldType,
  disabled,
  featureId,
  geoCenter,
  initialValue,
  ...restProps
}: ComponentSwitcherProps) => {
  const renderComponent = () => {
    switch (componentType) {
      case MapObjectComponentType.input:
        return <InputMap disabled={disabled} {...restProps} />;

      case MapObjectComponentType.inputNumber:
        return <InputNumberMap disabled={disabled} {...restProps} />;

      case MapObjectComponentType.inputProfile:
        return <InputProfileMap disabled={disabled} {...restProps} />;

      case MapObjectComponentType.selectConnectors:
        return <SelectConnectorMap fieldType={fieldType} disabled={disabled} {...restProps} />;

      case MapObjectComponentType.select:
        return (
          <SelectGeoMap
            fieldType={fieldType}
            disabled={disabled}
            featureId={featureId}
            geoCenter={geoCenter}
            initialValue={initialValue}
            {...restProps}
          />
        );

      case MapObjectComponentType.category:
        return <SelectCategoryMap disabled={disabled} initialValue={initialValue} {...restProps} />;

      case MapObjectComponentType.switch:
        return <SwitchMap disabled={disabled} initialValue={initialValue} {...restProps} />;

      case MapObjectComponentType.device:
        return <SelectDevice disabled={disabled} {...restProps} />;

      case MapObjectComponentType.unit:
        return <SelectUnit disabled={disabled} {...restProps} />;

      case MapObjectComponentType.dateTime:
        return <DateTimeMap disabled={disabled} {...restProps} />;

      case MapObjectComponentType.accessControl:
        return <SelectAccessControl disabled={disabled} {...restProps} />;

      case MapObjectComponentType.photo:
        return <UploadMap disabled={disabled} initialValue={initialValue as unknown as IFile} {...restProps} />;

      default:
        assertUnreachable(componentType);
        return null;
    }
  };

  return renderComponent();
};
