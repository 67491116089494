import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { AuthAPI } from '@/services/auth';
import { removeAuthToken } from '@/utils/auth';
import { casLogoutUrl } from '@/settings';
import { routes } from '@/app/router/routes';

export const useLogout = () => {
  const navigate = useNavigate();
  const mutationInfo = useMutation({
    mutationFn: () => AuthAPI.logout(),
    onSuccess: () => {
      removeAuthToken();

      if (casLogoutUrl) {
        globalThis.location.replace(casLogoutUrl);
      } else {
        navigate(routes.noAccess());
      }
    },
  });

  return mutationInfo;
};
