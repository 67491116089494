import { createSelector } from 'reselect';

import { MapObjectFilterValues } from '@/pages/Map/enums';

import { GeoJsonFeaturesChanger } from '@/utils/GeoJsonFeaturesChanger';

const getSavingStatus = state => state.features.isLoading;

const getFeaturesGeoJson = state => state.features.filteredFeatures;

const getFeaturesObjects = createSelector([getFeaturesGeoJson], features =>
  GeoJsonFeaturesChanger.geoJsonToFeatureObjects(features),
);

const getExtent = state => state.features.extent;

const getNewFeatures = state => state.features.newFeatures;

const getNewFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.features.newFeatures);

const getEditFeatures = state => state.features.editFeatures;

const getEditFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.features.editFeatures);

const getDeleteFeatures = state => state.features.deleteFeatures;

const getDeleteFeaturesGeoJson = state => GeoJsonFeaturesChanger.featureObjectsToGeoJson(state.features.deleteFeatures);

const getFiltersToFeatures = state => state.features.filtersToFeatures;

const getIsCheckedAll = state => MapObjectFilterValues.every(type => state.features.filtersToFeatures.includes(type));

export {
  getSavingStatus,
  getFeaturesObjects,
  getNewFeatures,
  getNewFeaturesGeoJson,
  getEditFeatures,
  getEditFeaturesGeoJson,
  getDeleteFeatures,
  getDeleteFeaturesGeoJson,
  getExtent,
  getFiltersToFeatures,
  getIsCheckedAll,
};
