import type { SelectProps } from 'antd';
import type { TFunction } from 'i18next';

import { ContentStatus } from '@/types/global';
import { ContentLanguage } from '@/types/global';

interface IOption {
  translationKey: string;
  value: string;
}

export const useSelectOptions = (options: IOption[]): SelectProps['options'] =>
  options.map(option => ({ label: option.translationKey, value: option.value }));

export const useLanguageOptions = (t: TFunction) =>
  useSelectOptions([
    { translationKey: t('language.pl'), value: ContentLanguage.pl },
    { translationKey: t('language.en'), value: ContentLanguage.en },
  ]);

export const useStatusOptions = (t: TFunction) =>
  useSelectOptions([
    { translationKey: t('status.active'), value: ContentStatus.active },
    { translationKey: t('status.inactive'), value: ContentStatus.inactive },
  ]);
