import styled from 'styled-components';
import { InputNumber } from 'antd';

import type { GlobalToken } from 'antd';

interface RotateDesignTokenProps {
  token: GlobalToken;
}

export const InputNumberStyled = styled(InputNumber)<RotateDesignTokenProps>`
  border: none;
  font-size: 2rem;
  color: ${props => props.token.colorWhite};
  background-color: ${props => props.token.colorPrimary};

  .ant-input-number-input {
    color: ${props => props.token.colorText};
  }
`;
