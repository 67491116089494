import { Link, useLoaderData } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { NavigationCuesLoaderType } from './queries';
import type { INavigationCue } from './types';

import { useNavigationCuesQuery } from './queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';

export const NavigationCues = () => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as NavigationCuesLoaderType;
  const { data: navigationCues, isLoading } = useNavigationCuesQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('navigationCues.title')}
        description={t('navigationCues.description')}
        extra={
          <Link to={routes.navigationCueCreate()}>
            <Button type='primary'>{t('navigationCues.createButtonText')}</Button>
          </Link>
        }
      />
      <Table<INavigationCue>
        rowKey='id'
        dataSource={navigationCues.data ?? []}
        loading={isLoading}
        columns={useColumns()}
        pagination={usePagination(navigationCues.total, navigationCues.per_page)}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};
