import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';

export const FormCommon = () => {
  const { t } = useTranslation();
  const { validate } = useValidation();

  const options = (start: number, end: number, step = 5) =>
    Array.from({ length: (end - start) / step + 1 }, (_, i) => {
      const value = start + i * step;
      return { label: value, value };
    });

  return (
    <>
      <Form.Item name='key' label={t('profiles.key')} rules={validate('required')}>
        <Select showSearch options={options(-30, 100)} />
      </Form.Item>
      <Form.Item name='label' label={t('profiles.label')} rules={validate('required')}>
        <Input />
      </Form.Item>
    </>
  );
};
