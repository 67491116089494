import styled from 'styled-components';

export const WysiwygEditorContainer = styled.div`
  background-color: #ffffff;
  border-radius: 6px;

  .ql-toolbar,
  .ql-container {
    border: 0.8px solid #d9d9d9;
  }

  .ql-toolbar {
    border-radius: 6px 6px 0 0;
  }

  .ql-container {
    border-radius: 0 0 6px 6px;
  }
`;
