import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IPushNotificationUpdate } from '../types';
import type { PushNotificationLoaderType } from '../queries';

import { usePushNotificationQuery, usePushNotificationUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';
import { NotificationType } from '@/types/global';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const PushNotificationUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPushNotificationUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as PushNotificationLoaderType;
  const { data: pushNotification, isLoading: isLoadingQuery } = usePushNotificationQuery(initialData);
  const { mutate: pushNotificationUpdate, isLoading: isLoadingMutation } = usePushNotificationUpdate();

  const submitHandler = (data: IPushNotificationUpdate) => {
    pushNotificationUpdate({ ...data, type: NotificationType.push });
  };

  return (
    <>
      <PageHeader
        title={t('pushNotifications.form.updateTitle')}
        description={t('pushNotifications.form.updateDescription')}
        navigateBackUrl={routes.pushNotifications()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='pushNotificationUpdate'
          form={form}
          initialValues={{ ...pushNotification, photo: pushNotification.photo.id }}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon initialPhotoUrl={pushNotification.photo.path} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.send')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
