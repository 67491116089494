import { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import debounce from 'lodash.debounce';

import type { IBeaconFilter } from '../../types';

import { SelectBuilding } from './SelectBuilding';
import { SelectBatteryScope } from './SelectBatteryScope';

import { useBuildingsQuery } from '../../queries';

export const Filters = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IBeaconFilter>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: buildings } = useBuildingsQuery();

  const { setFieldValue } = form;

  useEffect(() => {
    setFieldValue('batteryScope', searchParams.get('batteryScope') ?? undefined);
    setFieldValue('building', searchParams.get('building') ?? undefined);
    setFieldValue('search', searchParams.get('search') ?? undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, buildings]);

  const onSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const oldValues = Object.fromEntries(searchParams);
    const newValue = e.target.value;
    if (!newValue) {
      delete oldValues.search;
      setSearchParams({ ...oldValues, page: '1' });
    } else {
      setSearchParams({ ...oldValues, search: newValue, page: '1' });
    }
  }, 1000);

  const onBatteryScopeChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.batteryScope;
      setSearchParams({ ...oldValues, page: '1' });
    } else {
      setSearchParams({ ...oldValues, batteryScope: newValue, page: '1' });
    }
  };

  const onBuildingChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.building;
      setSearchParams({ ...oldValues, page: '1' });
    } else {
      setSearchParams({ ...oldValues, building: newValue, page: '1' });
    }
  };

  return (
    <Form form={form} name='filterForm'>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name='search' label={t('global.search')}>
            <Input.Search onChange={onSearchChange} placeholder={t('global.search')} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='building' label={t('beacons.building')}>
            <SelectBuilding onChange={onBuildingChange} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='batteryScope' label={t('beacons.battery')}>
            <SelectBatteryScope onChange={onBatteryScopeChange} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
