import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography, theme } from 'antd';
import { Link } from 'react-router-dom';

import type { ReactNode } from 'react';

import { PageHeaderContainer, TextContainer, TitleContainer } from './style';

export interface PageHeaderProps {
  title: string;
  description?: string;
  extra?: ReactNode;
  navigateBackUrl?: string;
}

export const PageHeader = ({ title, description, extra, navigateBackUrl }: PageHeaderProps) => {
  const { token } = theme.useToken();

  return (
    <PageHeaderContainer>
      <TextContainer>
        <TitleContainer token={token}>
          {navigateBackUrl && (
            <Link to={navigateBackUrl}>
              <ArrowLeftOutlined />
            </Link>
          )}
          <Typography.Title>{title}</Typography.Title>
        </TitleContainer>
        {description && <Typography.Text type='secondary'>{description}</Typography.Text>}
      </TextContainer>
      <section>{extra}</section>
    </PageHeaderContainer>
  );
};
