import type { IPagination } from '@/types/global';
import type { IAnnouncement, IAnnouncementCreate, IAnnouncementUpdate } from './types';

import HTTP from '@/utils/http';

export const announcementsService = {
  getAnnouncements: (page: number) =>
    HTTP.get<IPagination<IAnnouncement>>(`article?page=${page}`).then(response => response.data),
  getAnnouncement: (id: number) => HTTP.get<IAnnouncement>(`article/${id}`).then(response => response.data),
  createAnnouncement: (announcementCreate: IAnnouncementCreate) => HTTP.post('article', announcementCreate),
  updateAnnouncement: (announcementUpdate: IAnnouncementUpdate) =>
    HTTP.put(`article/${announcementUpdate.id}`, announcementUpdate),
  deleteAnnouncement: (id: number) => HTTP.delete(`article/${id}`),
};
