/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import { Select } from 'antd';

import { LoadingBlock } from '@/ui/Loading';

import { useCategoryListBasicData } from '../../../useCategoryListBasicData';

interface SelectCategoryMapProps {
  disabled: boolean;
  initialValue: string;
}

export const SelectCategoryMap = ({ disabled, initialValue, ...restProps }: SelectCategoryMapProps) => {
  const { categoryListBasic, categoryListBasicLoading } = useCategoryListBasicData(initialValue);

  const options = useMemo(() => {
    if (!categoryListBasic) {
      return;
    }

    return [
      ...categoryListBasic.map((option: any) => ({
        key: option.id,
        value: option.id,
        label: option.namePl,
      })),
    ];
  }, [categoryListBasic]);

  return (
    <LoadingBlock loading={categoryListBasicLoading}>
      <Select
        allowClear
        options={options || []}
        disabled={disabled}
        filterOption={false}
        defaultActiveFirstOption={false}
        {...restProps}
      />
    </LoadingBlock>
  );
};
