import { Dropdown } from 'antd';
import styled from 'styled-components';

import type { GlobalToken } from 'antd';

interface DropdownDesignTokenProps {
  token: GlobalToken;
}

export const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 9rem;
  right: 1rem;
  z-index: 1000;
`;

export const DropdownIcon = styled.div<DropdownDesignTokenProps>`
  cursor: pointer;
  height: 3.6rem;
  width: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.token.colorPrimary};
  border-radius: ${props => props.token.borderRadiusLG}px;

  svg {
    height: 20px;
    width: 20px;
    fill: ${props => props.token.colorWhite};
  }
`;
