import { useContext } from 'react';

import GeoJsonUtils from '../../utils/GeoJsonUtils';
import { MapContext } from '../../MapContext';
import { InteractionFeatures } from '../../enums';

const usePaste = onPaste => {
  const { vectorSource, addToNewFeatures } = useContext(MapContext);
  const geoJson = GeoJsonUtils.createGeoJson();

  const paste = () => {
    const toPaste = sessionStorage.getItem(InteractionFeatures.copiedFeatures);
    if (!toPaste) {
      return;
    }

    const features = geoJson.readFeatures(toPaste);
    features.forEach(item => {
      item.setId(undefined);
    });
    vectorSource.addFeatures(features);
    addFeaturesAfterPaste(features);

    if (typeof onPaste === 'function') {
      onPaste({ type: InteractionFeatures.paste, features: features });
    }
  };

  const addFeaturesAfterPaste = features => {
    features.forEach(feature => {
      addToNewFeatures(feature);
    });
  };

  return paste;
};

export default usePaste;
