import { MapObject } from '@/pages/Map/enums';
import { useTranslation } from 'react-i18next';

export const useMapObjectTranslation = () => {
  const { t } = useTranslation();

  const translate = (mapObject: MapObject) => t(`map.mapObject.${mapObject}`);

  const translateLabel = (mapObjectLabel: string) => t(`map.mapObjectLabel.${mapObjectLabel}`, { defaultValue: '' });

  return { translate, translateLabel };
};
