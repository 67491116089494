import { Circle as CircleStyle, Stroke, Fill, Style } from 'ol/style';

const mapColors = {
  primary: '#8B231D',
  primaryOpacity: '#8B231D90',
  secondary: '#69686D',
  secondaryOpacity: '#69686D30',
};

const defaultFill = new Fill({ color: mapColors.primary });
const selectFill = new Fill({ color: mapColors.primaryOpacity });
const defaultStroke = new Stroke({ color: mapColors.secondary, width: 2 });
const selectStroke = new Stroke({ color: mapColors.primary, width: 2 });
const defaultPointsRadius = 5;
const defaultImage = new CircleStyle({
  radius: defaultPointsRadius,
  fill: new Fill({ color: mapColors.secondaryOpacity }),
  stroke: defaultStroke,
});
const defaultImageSelect = new CircleStyle({
  radius: defaultPointsRadius,
  fill: selectFill,
  stroke: selectStroke,
});

const styles = {
  default: new Style({
    fill: defaultFill,
    stroke: defaultStroke,
    image: defaultImage,
  }),
  select: new Style({
    fill: selectFill,
    stroke: selectStroke,
    image: defaultImageSelect,
  }),
  poj: new Style({
    fill: new Fill({ color: mapColors.secondaryOpacity }),
    stroke: defaultStroke,
    image: defaultImage,
  }),
};

export default styles;
