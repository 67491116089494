import { createContext } from 'react';
import { Map as MapOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';

interface IMapBeaconStateContext {
  map?: MapOl;
  tileSource: XYZSourceOl;
  vectorSource: VectorSourceOl;
}

export const MapBeaconStateContext = createContext<IMapBeaconStateContext | undefined>(undefined);
