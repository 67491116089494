import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import categoriesBasicActions from '@/redux/categoriesBasic/actions';
import { getLoadingStatus, getCategoriesBasic } from '@/redux/categoriesBasic/selectors';

export const useCategoryListBasicData = (categoryId: number | string) => {
  const dispatch = useDispatch();
  const categoryListBasic = useSelector(getCategoriesBasic);
  const categoryListBasicLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    dispatch(categoriesBasicActions.loadCategoriesBasic(categoryId ?? ''));

    return () => {
      dispatch(categoriesBasicActions.removeCategoryBasic());
    };
  }, [dispatch, categoryId]);

  return { categoryListBasic, categoryListBasicLoading };
};
