import { useContext, useEffect } from 'react';
import { MapContext } from '../MapContext';

import Select from 'ol/interaction/Select';
import { platformModifierKeyOnly, click } from 'ol/events/condition';

import styles from '../Styles/shapeStyles';
import { CompUtils, commonOptions, commonEvents } from '../utils';

export const SelectInteraction = props => {
  const { map } = useContext(MapContext);

  const options = {
    addCondition: undefined,
    condition: click,
    layers: undefined,
    style: styles['select'] || undefined,
    removeCondition: undefined,
    toggleCondition: platformModifierKeyOnly || undefined,
    multi: undefined,
    filter: undefined,
    hitTolerance: undefined,
  };

  const events = {
    select: undefined,
  };

  Object.assign(options, commonOptions);
  Object.assign(events, commonEvents);

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);

    const select = new Select(opts);

    map.addInteraction(select);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      select.on(eventName, olEvents[eventName]);
    }

    return () => map.removeInteraction(select);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
