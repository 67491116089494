import { Form, Input, InputNumber, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';

export const FormCommon = () => {
  const { t } = useTranslation();
  const { validate } = useValidation();

  return (
    <>
      <Form.Item name='name' label={t('beacons.name')} rules={validate('required')}>
        <Input disabled />
      </Form.Item>
      <Form.Item name='mac' label={t('beacons.mac')} rules={validate('required')}>
        <Input disabled />
      </Form.Item>
      <Form.Item name='building' label={t('beacons.building')} rules={validate('required')}>
        <Input disabled />
      </Form.Item>
      <Form.Item name='battery' label={t('beacons.battery')} rules={validate('required')}>
        <InputNumber addonAfter='%' disabled />
      </Form.Item>
      <Form.Item name='profile' label={t('beacons.profile')} rules={validate('required')}>
        <Input disabled />
      </Form.Item>
      <Form.Item name='interval' label={t('beacons.interval')} rules={validate('required')}>
        <InputNumber addonAfter='ms' disabled />
      </Form.Item>
      <Form.Item name='power' label={t('beacons.power')} rules={validate('required')}>
        <InputNumber addonAfter='dBm' disabled />
      </Form.Item>
      <Form.Item name='rssi' label={t('beacons.rssi')} rules={validate('required')}>
        <InputNumber addonAfter='dBm' disabled />
      </Form.Item>
      <Form.Item name='isOutside' label={t('beacons.isOutside')} valuePropName='checked' rules={validate('required')}>
        <Switch />
      </Form.Item>
    </>
  );
};
