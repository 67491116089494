import type { ErrorResponse } from './http';

import { errorNotificationWithErrorCode } from './notification';

export const httpErrorHandler = async (response: Response) => {
  try {
    const errorResponse = await response.json();

    if (isErrorResponse(errorResponse)) {
      errorHandler(errorResponse, response.status);
    }

    throw new Error('Unknown response');
  } catch (e) {
    throwError('Internal Server Error', 500);
  }
};

const isErrorResponse = (error: unknown): error is ErrorResponse => {
  return typeof error === 'object' && error !== null && 'message' in error && 'errors' in error;
};

export const errorHandler = (errorResponse: ErrorResponse, errorStatus: Response['status']) => {
  switch (errorStatus) {
    case 401:
    case 403:
      throwError('No Access', 403);
      break;
    case 404:
      throwError('Not Found', 404);
      break;
    case 500:
      throwError('Internal Server Error', 500);
      break;

    case 422: {
      Object.values(errorResponse.errors).forEach(errorCodes => errorNotificationWithErrorCode(errorCodes));

      return errorResponse;
    }

    default:
      return errorResponse;
  }
};

export const throwError = (name: string, status: 403 | 404 | 500) => {
  throw new Response(name, { status });
};
