import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import geoBasicActions from '@/redux/geoBasic/actions';
import { getLoadingStatus, getSelectOptions } from '@/redux/geoBasic/selectors';
import isEmpty from 'lodash.isempty';

export const useGeoBasicOptionsData = (
  type: string,
  centerPoint: [number, number],
  profile: number,
  withId: unknown,
  withoutId: number,
) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(getSelectOptions);
  const selectOptionsLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (isEmpty(centerPoint)) {
      return;
    }

    dispatch(geoBasicActions.loadSelectOptions(type, centerPoint, profile, withId, withoutId));

    return () => {
      dispatch(geoBasicActions.removeSelectOptions());
    };
  }, [dispatch, type, centerPoint, profile, withId, withoutId]);

  return { selectOptions, selectOptionsLoading };
};
