import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';

import { useLogout } from './queries';

export const Logout = () => {
  const { t } = useTranslation();
  const { mutate: logout, isLoading } = useLogout();

  return (
    <Button type='link' loading={isLoading} icon={<LogoutOutlined />} onClick={() => logout()}>
      {t('header.logoutTitle')}
    </Button>
  );
};
