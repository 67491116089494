import { Select } from 'antd';

import { useBuildingsQuery } from '../../queries';

interface SelectBuildingProps {
  onChange: (value: string) => void;
}

export const SelectBuilding = ({ onChange, ...restProps }: SelectBuildingProps): JSX.Element => {
  const { data: buildings, isLoading } = useBuildingsQuery();

  return <Select showSearch allowClear options={buildings || []} loading={isLoading} onChange={onChange} {...restProps} />;
};
