/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { Select } from 'antd';

import { Loading } from '@/ui/Loading';

import { useConnectorValuesData } from '../../../useConnectorValuesData';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

interface SelectConnectorMapProps {
  fieldType: string; //TODO: clarify type
  disabled: boolean;
}

export const SelectConnectorMap = ({ fieldType, disabled, ...restProps }: SelectConnectorMapProps) => {
  const { selectOptions, selectOptionsLoading } = useConnectorValuesData(fieldType);
  const { translateLabel } = useMapObjectTranslation();

  const options = useMemo(
    () =>
      selectOptions
        .find((element: any) => element.key === fieldType)
        ?.map((option: any) => ({
          key: option.key,
          value: option.value,
          label: translateLabel(option.label),
        })),
    [translateLabel, selectOptions, fieldType],
  );

  return (
    <Loading loading={selectOptionsLoading} rows={0}>
      <Select options={options || []} disabled={disabled} filterOption={false} defaultActiveFirstOption={false} {...restProps} />
    </Loading>
  );
};
