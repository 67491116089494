import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IPopularLocation } from './types';

import { routes } from '@/app/router/routes';

import { usePopularLocationDelete } from './queries';

export const useColumns = (): ColumnsType<IPopularLocation> => {
  const { t } = useTranslation();
  const { mutate: popularLocationDelete } = usePopularLocationDelete();

  return [
    {
      title: t('popularLocations.location'),
      dataIndex: 'location',
      key: 'location',
      render: (text: IPopularLocation['location']) => text.name,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IPopularLocation) => (
        <Space>
          <Link to={routes.popularLocationUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('popularLocations.delete.title')}
            okText={t('popularLocations.delete.okText')}
            cancelText={t('popularLocations.delete.cancelText')}
            placement='left'
            onConfirm={() => popularLocationDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
