import { Link, useLoaderData } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { PopularLocationsLoaderType } from './queries';
import type { IPopularLocation } from './types';

import { usePopularLocationsQuery } from './queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';

export const PopularLocations = () => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as PopularLocationsLoaderType;
  const { data: popularLocations, isLoading } = usePopularLocationsQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('popularLocations.title')}
        description={t('popularLocations.description')}
        extra={
          <Link to={routes.popularLocationCreate()}>
            <Button type='primary'>{t('popularLocations.createButtonText')}</Button>
          </Link>
        }
      />
      <Table<IPopularLocation>
        rowKey='id'
        dataSource={popularLocations.data ?? []}
        loading={isLoading}
        columns={useColumns()}
        pagination={usePagination(popularLocations.total, popularLocations.per_page)}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};
