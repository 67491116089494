import { useContext, useEffect } from 'react';

import { MapContext } from '../MapContext';

import ol_interaction_Transform from 'ol-ext/interaction/Transform';
import { CompUtils } from '../utils';
import transformStyles from '../Styles/transformStyles';

export const Transform = props => {
  const { map } = useContext(MapContext);

  const options = {
    filter: undefined,
    layers: undefined,
    features: undefined,
    condition: undefined,
    addCondition: undefined,
    hitTolerance: undefined,
    translateFeature: true,
    translate: false,
    translateBBox: undefined,
    stretch: true,
    scale: true,
    rotate: true,
    noFlip: undefined,
    selection: undefined,
    keepAspectRatio: undefined,
    modifyCenter: undefined,
    enableRotatedTransform: true,
    style: undefined,
  };

  const events = {
    select: undefined,
    rotatestart: undefined,
    rotating: undefined,
    rotateend: undefined,
    translatestart: undefined,
    translating: undefined,
    translateend: undefined,
    scalestart: undefined,
    scaling: undefined,
    scaleend: undefined,
  };

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);

    const transform = new ol_interaction_Transform(opts);
    map.addInteraction(transform);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      transform.on(eventName, olEvents[eventName]);
    }

    for (let styleName in transformStyles) {
      transform.setStyle(styleName, transformStyles[styleName]);
    }

    return () => map.removeInteraction(transform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
