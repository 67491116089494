import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IProfileCreate, IProfileUpdate } from './types';

import { profileService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const profilesQuery = (page: number) => ({
  queryKey: ['profiles', 'list', page],
  queryFn: () => profileService.getProfiles(page),
  staleTime: 1000 * 60 * 24,
});

export const profilesLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = profilesQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type ProfilesLoaderType = Awaited<ReturnType<ReturnType<typeof profilesLoader>>>;

export const useProfilesQuery = (initialData: ProfilesLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...profilesQuery(page),
    initialData,
  });

  return queryInfo;
};

const profileQuery = (id: number) => ({
  queryKey: ['profiles', 'details', id],
  queryFn: () => profileService.getProfile(id),
});

export const profileLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = profileQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type ProfileLoaderType = Awaited<ReturnType<ReturnType<typeof profileLoader>>>;

export const useProfileQuery = (initialData: ProfileLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...profileQuery(id),
    initialData,
  });

  return queryInfo;
};

export const useProfileCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IProfileCreate) => profileService.createProfile(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles', 'list'] });
      successNotification('profiles.notification.updateMessage');
      navigate(routes.profiles());
    },
  });

  return mutationInfo;
};

export const useProfileUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IProfileUpdate) => profileService.updateProfile(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['profiles', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['profiles', 'details', variables.id] });
      successNotification('profiles.notification.updateMessage');
      navigate(routes.profiles());
    },
  });

  return mutationInfo;
};

export const useProfileDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => profileService.deleteProfile(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles', 'list'] });
      successNotification('profiles.notification.updateMessage');
    },
  });

  return mutationInfo;
};
