import { useTranslation } from 'react-i18next';

import { LoadingBlock } from '@/ui/Loading';

import { useCategoryBasicData } from '../../useCategoryBasicData';

interface CategoryObjectProps {
  objectId: number;
}

export const CategoryObject = ({ objectId }: CategoryObjectProps) => {
  const { t } = useTranslation();
  const { categoryBasic, categoryBasicLoading } = useCategoryBasicData(objectId);

  return <LoadingBlock loading={categoryBasicLoading}>{categoryBasic ?? t('global.noData')}</LoadingBlock>;
};
