import { Select } from 'antd';

import { LoadingBlock } from '@/ui/Loading';

import { useDevicesNoPaginateQuery } from './queries';
import { useEffect } from 'react';

interface SelectDeviceProps {
  disabled: boolean;
}

export const SelectDevice = ({ disabled, ...restProps }: SelectDeviceProps) => {
  const { data: devices, isLoading } = useDevicesNoPaginateQuery();

  useEffect(() => {
    if ('onChange' in restProps && typeof restProps.onChange === 'function') {
      if ('value' in restProps && typeof restProps.value === 'string') {
        restProps.onChange([]);
      }
    }
  }, [restProps]);

  return (
    <LoadingBlock loading={isLoading}>
      <Select
        showSearch
        allowClear
        mode='multiple'
        options={devices || []}
        disabled={disabled}
        filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
        {...restProps}
      />
    </LoadingBlock>
  );
};
