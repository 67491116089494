import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IBeaconUpdate } from './types';

import { beaconService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { routes } from '@/app/router/routes';
import { profileService } from '@/pages/Profiles/service';
import {
  addParamToSearchParams,
  getIdFromParams,
  getSearchParamsFromRequest,
  useIdFromParams,
  usePageFromSearchParams,
} from '@/utils/params';

const getBeaconsQueryKey = (searchParams: URLSearchParams) => {
  const arr = [];

  const page = searchParams.get('page');
  const search = searchParams.get('search');
  const building = searchParams.get('building');
  const batteryScope = searchParams.get('batteryScope');
  const orderBy = searchParams.get('orderBy');
  const orderType = searchParams.get('orderType');

  page && arr.push(page);
  search && arr.push(search);
  building && arr.push(building);
  batteryScope && arr.push(batteryScope);
  orderBy && arr.push(orderBy);
  orderType && arr.push(orderType);

  return arr;
};

const beaconsQuery = (searchParams: URLSearchParams) => ({
  queryKey: ['beacons', 'list', ...getBeaconsQueryKey(searchParams)],
  queryFn: () => beaconService.getBeacons(searchParams),
  staleTime: 0,
});

export const beaconsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const searchParams = getSearchParamsFromRequest(request, { name: 'page', value: '1' });
    const query = beaconsQuery(searchParams);
    return await queryClient.ensureQueryData(query);
  };

export type BeaconsLoaderType = Awaited<ReturnType<ReturnType<typeof beaconsLoader>>>;

export const useBeaconsQuery = (initialData: BeaconsLoaderType) => {
  const [searchParams] = useSearchParams();
  const page = usePageFromSearchParams();

  const queryInfo = useQuery({
    ...beaconsQuery(addParamToSearchParams(searchParams, { name: 'page', value: page.toString() })),
    initialData,
  });

  return queryInfo;
};

const beaconQuery = (id: number) => ({
  queryKey: ['beacons', 'details', id],
  queryFn: () => beaconService.getBeacon(id),
});

export const beaconLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = beaconQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type BeaconLoaderType = Awaited<ReturnType<ReturnType<typeof beaconLoader>>>;

export const useBeaconQuery = (initialData: BeaconLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...beaconQuery(id),
    initialData,
  });

  return queryInfo;
};

// TODO: logic to navigate both to list and map, depends on previous location
export const useBeaconUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IBeaconUpdate) => beaconService.updateBeacon(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['beacons', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['beacons', 'details', variables.id] });
      successNotification('beacons.notification.updateMessage');
      navigate(routes.beacons());
    },
  });

  return mutationInfo;
};

// TODO: maybe second delete to delete from map
export const useBeaconDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => beaconService.deleteBeacon(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['beacons', 'list'] });
      successNotification('beacons.notification.updateMessage');
    },
  });

  return mutationInfo;
};

export const useBuildingsQuery = () => {
  const queryInfo = useQuery({
    queryKey: ['beacons', 'buildings'],
    queryFn: () => beaconService.getBuildings(),
    select: buildings => buildings.map(building => ({ label: building.name, value: building.name })),
  });

  return queryInfo;
};

// map

const getBeaconsMapQueryKey = (searchParams: URLSearchParams) => {
  const arr = [];

  const profile = searchParams.get('profile');
  const batteryScope = searchParams.get('batteryScope');

  profile && arr.push(profile);
  batteryScope && arr.push(batteryScope);

  return arr;
};

const beaconsMapQuery = (searchParams: URLSearchParams) => ({
  queryKey: ['beacons', 'map', ...getBeaconsMapQueryKey(searchParams)],
  queryFn: () => beaconService.getBeaconsMap(searchParams),
  staleTime: 0,
});

export const beaconsMapLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const searchParams = getSearchParamsFromRequest(request, { name: 'profile', value: '0' });
    const query = beaconsMapQuery(searchParams);
    return await queryClient.ensureQueryData(query);
  };

export type BeaconsMapLoaderType = Awaited<ReturnType<ReturnType<typeof beaconsMapLoader>>>;

export const useBeaconsMapQuery = (initialData: BeaconsMapLoaderType) => {
  const [searchParams] = useSearchParams();

  const queryInfo = useQuery({
    ...beaconsMapQuery(searchParams),
    initialData,
  });

  return queryInfo;
};

export const useBeaconMapQuery = () => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    queryKey: ['beacons', 'details', 'geo', id],
    queryFn: () => beaconService.getBeaconMap(id),
  });

  return queryInfo;
};

export const useMapProfilesQuery = () => {
  const queryInfo = useQuery({
    queryKey: ['beacons', 'profiles'],
    queryFn: () => profileService.getProfilesNoPaginate(),
  });

  return queryInfo;
};
