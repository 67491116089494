import { Skeleton, Spin } from 'antd';

import type { ReactNode } from 'react';
import { LoadingMaskContainer } from './style';
import { LoadingOutlined } from '@ant-design/icons';

interface LoadingCommonProps {
  loading: boolean;
  children: ReactNode;
}

interface LoadingProps extends LoadingCommonProps {
  rows?: number;
}

export const Loading = ({ loading, rows = 3, children }: LoadingProps): JSX.Element => (
  <Skeleton active loading={loading} paragraph={{ rows }}>
    {children}
  </Skeleton>
);

export const LoadingBlock = ({ loading, children }: LoadingCommonProps): JSX.Element =>
  loading ? <Skeleton.Button active block={true} /> : <>{children}</>;

export const LoadingMask = ({ loading, children }: LoadingCommonProps): JSX.Element =>
  loading ? (
    <>
      <LoadingMaskContainer>
        <Spin size='large' indicator={<LoadingOutlined spin />} />
      </LoadingMaskContainer>
      {children}
    </>
  ) : (
    <>{children}</>
  );
