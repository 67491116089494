import { theme } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { BatteryScope } from '../../../types';
import { useMapDispatchContext } from '../../useMapContext';

import { DropdownContent, DropdownStyled } from './style';

export const BatteryScopeMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { handleChangeBatteryScope } = useMapDispatchContext();
  const { token } = theme.useToken();

  const batteryScope = useMemo(() => searchParams.get('batteryScope') ?? '', [searchParams]);

  const getBatteryScopeTranslate = () => {
    const translationKey = Object.keys(BatteryScope).find(key => BatteryScope[key as keyof typeof BatteryScope] === batteryScope);

    return translationKey ? t(`beacons.batteryScope.${translationKey as keyof typeof BatteryScope}`) : t('beacons.battery');
  };

  const options = [
    {
      key: '',
      label: t(`global.noValue`),
      onClick: () => handleChangeBatteryScope(),
    },
    ...Object.entries(BatteryScope).map(([key, value]) => ({
      key: value,
      label: t(`beacons.batteryScope.${key as keyof typeof BatteryScope}`),
      onClick: () => handleChangeBatteryScope(value),
    })),
  ];

  return (
    <DropdownStyled
      menu={{
        items: options,
        selectable: true,
        selectedKeys: [batteryScope],
      }}
      trigger={['click']}
    >
      <DropdownContent token={token}>{getBatteryScopeTranslate()}</DropdownContent>
    </DropdownStyled>
  );
};
