import { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import type { ILocationBasic } from '@/types/global';

import { useLocationsQuery } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { useDebounce } from '@/hooks/useDebounce';

interface FormCommonProps {
  initialLocation?: ILocationBasic;
}

export const FormCommon = ({ initialLocation }: FormCommonProps) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { data: locations, isFetching, isLoading } = useLocationsQuery(debouncedSearch, initialLocation);

  return (
    <>
      <Form.Item name='location' label={t('popularLocations.location')} rules={validate('required')}>
        <Select
          showSearch
          loading={isFetching && isLoading}
          options={locations}
          defaultActiveFirstOption={false}
          onSearch={setSearch}
          filterOption={false}
        />
      </Form.Item>
    </>
  );
};
