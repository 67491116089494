import styled from 'styled-components';

import type { GlobalToken } from 'antd';

interface ZoomDesignTokenProps {
  token: GlobalToken;
}

export const ZoomButton = styled.span<ZoomDesignTokenProps>`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${props => props.token.colorPrimary};
  cursor: pointer;

  .anticon {
    color: ${props => props.token.colorWhite};
    font-size: 2.4rem;
  }
`;

export const ZoomInput = styled.span<ZoomDesignTokenProps>`
  width: 5.4rem;
  height: 3.2rem;
  text-align: center;
  font-size: 2rem;
  background-color: ${props => props.token.colorPrimary};
  color: ${props => props.token.colorWhite};
`;
