import isEmpty from 'lodash.isempty';

import { MapObjectComponentType } from '@/pages/Map/enums';
import { formatDayjsToDataDTO, getDayjs } from '@/utils/dateFormatter';

export const getFeaturePropertiesToDTO = (attrs, values) => {
  if (isEmpty(values)) {
    return values;
  }

  const data = { ...values };

  attrs.forEach(attr => {
    if (attr.type === MapObjectComponentType.dateTime && values[attr.key]) {
      data[attr.key] = formatDayjsToDataDTO(values[attr.key]);
    }
  });

  return data;
};

export const getFeaturePropertiesFromDTO = (attrs, values) => {
  if (isEmpty(values)) {
    return values;
  }

  const data = { ...values };

  attrs.forEach(attr => {
    if (attr.type === MapObjectComponentType.dateTime && values[attr.key]) {
      data[attr.key] = getDayjs(values[attr.key]);
    }
  });

  return data;
};
