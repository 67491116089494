import { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import type { ILocationBasic } from '@/types/global';

import { useLocationsQuery } from '../queries';
import { useDebounce } from '@/hooks/useDebounce';
import { useValidation } from '@/hooks/useValidation';
import { useStatusOptions } from '@/hooks/selectOptions';

import { Upload } from '@/ui/Upload';

import { FileType } from '@/ui/Upload/types';

interface FormCommonProps {
  initialPhotoUrl?: string;
  initialLocation?: ILocationBasic;
}

export const FormCommon = ({ initialPhotoUrl, initialLocation }: FormCommonProps) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const { t } = useTranslation();
  const { validate } = useValidation();
  const statusOptions = useStatusOptions(t);
  const { data: locations, isFetching, isLoading } = useLocationsQuery(debouncedSearch, initialLocation);

  return (
    <>
      <Form.Item name='status' label={t('placeNotifications.status')} rules={validate('required')}>
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item name='namePl' label={t('placeNotifications.namePL')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='nameEn' label={t('placeNotifications.nameEN')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='descriptionPl' label={t('placeNotifications.textPL')} rules={validate('required')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name='descriptionEn' label={t('placeNotifications.textEN')} rules={validate('required')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name='location' label={t('placeNotifications.location')} rules={validate('required')}>
        <Select
          showSearch
          loading={isFetching && isLoading}
          options={locations}
          defaultActiveFirstOption={false}
          onSearch={setSearch}
          filterOption={false}
        />
      </Form.Item>
      <Form.Item name='photo' label={t('placeNotifications.photo')} rules={validate('required')}>
        <Upload fileType={FileType.photo} initialUrl={initialPhotoUrl} />
      </Form.Item>
    </>
  );
};
