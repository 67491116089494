import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { INavigationCueCreate, INavigationCueUpdate } from './types';

import { navigationCuesService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const navigationCuesQuery = (page: number) => ({
  queryKey: ['navigationCues', 'list', page],
  queryFn: () => navigationCuesService.getNavigationCues(page),
  staleTime: 1000 * 60 * 24,
});

export const navigationCuesLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = navigationCuesQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type NavigationCuesLoaderType = Awaited<ReturnType<ReturnType<typeof navigationCuesLoader>>>;

export const useNavigationCuesQuery = (initialData: NavigationCuesLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...navigationCuesQuery(page),
    initialData,
  });

  return queryInfo;
};

const navigationCueQuery = (id: number) => ({
  queryKey: ['navigationCues', 'details', id],
  queryFn: () => navigationCuesService.getNavigationCue(id),
});

export const navigationCueLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = navigationCueQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type NavigationCueLoaderType = Awaited<ReturnType<ReturnType<typeof navigationCueLoader>>>;

export const useNavigationCueQuery = (initialData: NavigationCueLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...navigationCueQuery(id),
    initialData,
  });

  return queryInfo;
};

export const useNavigationCueCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: INavigationCueCreate) => navigationCuesService.createNavigationCue(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['navigationCues', 'list'] });
      successNotification('navigationCues.notification.createMessage');
      navigate(routes.navigationCues());
    },
  });

  return mutationInfo;
};

export const useNavigationCueUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: INavigationCueUpdate) => navigationCuesService.updateNavigationCue(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['navigationCues', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['navigationCues', 'details', variables.id] });
      successNotification('navigationCues.notification.updateMessage');
      navigate(routes.navigationCues());
    },
  });

  return mutationInfo;
};

export const useNavigationCueDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => navigationCuesService.deleteNavigationCue(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['navigationCues', 'list'] });
      successNotification('navigationCues.notification.deleteMessage');
    },
  });

  return mutationInfo;
};
