import actions from './actions';
import { MapObjectFilterValues } from '@/pages/Map/enums';
import { GeoJsonFeaturesChanger } from '../../utils/GeoJsonFeaturesChanger';

const initState = {
  isLoading: false,
  features: null,
  newFeatures: [],
  editFeatures: [],
  deleteFeatures: [],
  extent: null,
  filtersToFeatures: MapObjectFilterValues,
  filteredFeatures: null,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FEATURES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_FEATURES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const filteredData = payload.data
        ? {
            ...payload.data,
            features: getFilterFeatures(payload.data.features, state.filtersToFeatures),
          }
        : initState.filteredFeatures;
      return {
        ...state,
        isLoading: false,
        features: payload.data,
        extent: payload.extent,
        filteredFeatures: filteredData,
      };
    case actions.LOAD_FEATURES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.SEND_FEATURES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.SEND_FEATURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SEND_FEATURES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actions.ADD_FEATURE_TO_NEW_FEATURES:
      return {
        ...state,
        newFeatures: [...state.newFeatures, payload.feature],
      };
    case actions.ADD_FEATURE_TO_EDIT_FEATURES:
      return {
        ...state,
        editFeatures: [...state.editFeatures, payload.feature],
      };
    case actions.ADD_FEATURE_TO_DELETE_FEATURES:
      return {
        ...state,
        deleteFeatures: [...state.deleteFeatures, payload.feature],
      };

    case actions.SWAP_NEW_FEATURES:
      return {
        ...state,
        newFeatures: payload.data,
      };
    case actions.SWAP_EDIT_FEATURES:
      return {
        ...state,
        editFeatures: payload.data,
      };

    case actions.CLEAR_COLLECTIONS:
      return {
        ...state,
        newFeatures: [],
        editFeatures: [],
        deleteFeatures: [],
      };

    case actions.ADD_TO_FILTERS_FEATURES:
      return {
        ...state,
        filtersToFeatures: [...state.filtersToFeatures, payload.filter],
      };
    case actions.REMOVE_FROM_FILTERS_FEATURES: {
      const newFilters = [...state.filtersToFeatures].filter(filter => filter !== payload.filter);
      return {
        ...state,
        filtersToFeatures: newFilters,
      };
    }
    case actions.CHECK_ALL_FEATURES:
      return {
        ...state,
        filtersToFeatures: MapObjectFilterValues.every(type => state.filtersToFeatures.includes(type))
          ? []
          : MapObjectFilterValues,
      };

    case actions.CHANGE_FILTER_FEATURES: {
      const filteredData = state.features
        ? {
            ...state.features,
            features: getFeaturesWithCollections(
              state.features.features,
              state.filtersToFeatures,
              state.newFeatures,
              state.editFeatures,
              state.deleteFeatures,
            ),
          }
        : initState.filteredFeatures;

      return {
        ...state,
        filteredFeatures: filteredData,
      };
    }

    default:
      return state;
  }
}

const getFilterFeatures = (features, filters) =>
  features ? features.filter(feature => filters.includes(feature.properties.type)) : null;

const getFeaturesWithCollections = (features, filtersToFeatures, newFeatures, editFeatures, deleteFeatures) => {
  const newFeaturesData = JSON.parse(GeoJsonFeaturesChanger.featureObjectsToGeoJson(newFeatures)).features;
  const editFeaturesData = JSON.parse(GeoJsonFeaturesChanger.featureObjectsToGeoJson(editFeatures)).features;
  const deleteFeaturesData = JSON.parse(GeoJsonFeaturesChanger.featureObjectsToGeoJson(deleteFeatures)).features;
  const featuresData = [];

  features = getFilterFeatures(features, filtersToFeatures);

  [...features, ...newFeaturesData].forEach(feature => {
    const featureInEditFeatures = editFeaturesData.find(value => value.id === feature.id);
    if (featureInEditFeatures) {
      featuresData.push(featureInEditFeatures);
    } else if (!deleteFeaturesData.some(value => value.id === feature.id)) {
      featuresData.push(feature);
    }
  });

  return featuresData;
};
