import { profileService } from '@/pages/Profiles/service';
import { useQuery } from '@tanstack/react-query';

export const useFloorsMenuQuery = (handleChangeMapProfile: (profile: number) => void) => {
  const queryInfo = useQuery({
    queryKey: ['profiles', 'floorMenu'],
    queryFn: () => profileService.getProfilesNoPaginate(),
    select: profiles =>
      profiles.map(profile => ({
        label: profile.label,
        key: profile.key,
        onClick: () => handleChangeMapProfile(profile.key),
      })),
  });

  return queryInfo;
};
