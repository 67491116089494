import { Button, Form, theme } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IMapCategoryCreate } from '../types';

import { useMapCategoryCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

export const MapCategoryCreate = (): JSX.Element => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const [form] = Form.useForm<IMapCategoryCreate>();
  const { mutate: mapCategoryCreate, isLoading } = useMapCategoryCreate();

  const submitHandler = (data: IMapCategoryCreate) => {
    mapCategoryCreate(data);
  };

  return (
    <>
      <PageHeader
        title={t('mapCategories.form.createTitle')}
        description={t('mapCategories.form.createDescription')}
        navigateBackUrl={routes.mapCategories()}
      />
      <Form
        name='mapCategoryCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
        initialValues={{
          isEnabled: true,
          isVisible: true,
          isVisibleIcon: true,
          isVisibleFilter: true,
          color: token.colorPrimary,
        }}
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
