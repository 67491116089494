import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { NoAccess } from '../NoAccess';
import { NotFound } from '../NotFound';
import { UnknownError } from '../UnknownError';

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 403:
        return <NoAccess />;

      case 404:
        return <NotFound />;
    }
  }

  return <UnknownError />;
};
