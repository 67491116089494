import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IPopularLocationCreate, IPopularLocationUpdate } from './types';
import type { ILocationBasic } from '@/types/global';

import { popularLocationService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const popularLocationsQuery = (page: number) => ({
  queryKey: ['popularLocations', 'list', page],
  queryFn: () => popularLocationService.getPopularLocations(page),
  staleTime: 1000 * 60 * 24,
});

export const popularLocationsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = popularLocationsQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type PopularLocationsLoaderType = Awaited<ReturnType<ReturnType<typeof popularLocationsLoader>>>;

export const usePopularLocationsQuery = (initialData: PopularLocationsLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...popularLocationsQuery(page),
    initialData,
  });

  return queryInfo;
};

const popularLocationQuery = (id: number) => ({
  queryKey: ['popularLocations', 'details', id],
  queryFn: () => popularLocationService.getPopularLocation(id),
});

export const popularLocationLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = popularLocationQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type PopularLocationLoaderType = Awaited<ReturnType<ReturnType<typeof popularLocationLoader>>>;

export const usePopularLocationQuery = (initialData: PopularLocationLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...popularLocationQuery(id),
    initialData,
  });

  return queryInfo;
};

export const usePopularLocationCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPopularLocationCreate) => popularLocationService.createPopularLocation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['popularLocations', 'list'] });
      successNotification('popularLocations.notification.createMessage');
      navigate(routes.popularLocations());
    },
  });

  return mutationInfo;
};

export const usePopularLocationUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPopularLocationUpdate) => popularLocationService.updatePopularLocation(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['popularLocations', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['popularLocations', 'details', variables.id] });
      successNotification('popularLocations.notification.updateMessage');
      navigate(routes.popularLocations());
    },
  });

  return mutationInfo;
};

export const usePopularLocationDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => popularLocationService.deletePopularLocation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['popularLocations', 'list'] });
      successNotification('popularLocations.notification.deleteMessage');
    },
  });

  return mutationInfo;
};

export const useLocationsQuery = (search: string, initialLocation?: ILocationBasic) => {
  const queryInfo = useQuery({
    queryKey: ['popularLocations', 'locations', search],
    placeholderData: initialLocation ? [initialLocation] : [],
    queryFn: () => popularLocationService.getLocations(search, 100),
    select: locations => locations.map(location => ({ label: location.name, value: location.id })),
    enabled: !!search,
  });

  return queryInfo;
};
