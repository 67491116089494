import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IMapCategoryCreate, IMapCategoryUpdate } from './types';

import { mapCategoriesService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const mapCategoriesQuery = (page: number) => ({
  queryKey: ['mapCategories', 'list', page],
  queryFn: () => mapCategoriesService.getMapCategories(page),
  staleTime: 1000 * 60 * 24,
});

export const mapCategoriesLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = mapCategoriesQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type MapCategoriesLoaderType = Awaited<ReturnType<ReturnType<typeof mapCategoriesLoader>>>;

export const useMapCategoriesQuery = (initialData: MapCategoriesLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...mapCategoriesQuery(page),
    initialData,
  });

  return queryInfo;
};

const mapCategoryQuery = (id: number) => ({
  queryKey: ['mapCategories', 'details', id],
  queryFn: () => mapCategoriesService.getMapCategory(id),
});

export const mapCategoryLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = mapCategoryQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type MapCategoryLoaderType = Awaited<ReturnType<ReturnType<typeof mapCategoryLoader>>>;

export const useMapCategoryQuery = (initialData: MapCategoryLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...mapCategoryQuery(id),
    initialData,
  });

  return queryInfo;
};

export const useMapCategoryCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IMapCategoryCreate) => mapCategoriesService.createMapCategory(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mapCategories', 'list'] });
      successNotification('mapCategories.notification.createMessage');
      navigate(routes.mapCategories());
    },
  });

  return mutationInfo;
};

export const useMapCategoryUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IMapCategoryUpdate) => mapCategoriesService.updateMapCategory(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['mapCategories', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['mapCategories', 'details', variables.id] });
      successNotification('mapCategories.notification.updateMessage');
      navigate(routes.mapCategories());
    },
  });

  return mutationInfo;
};

export const useMapCategoryDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => mapCategoriesService.deleteMapCategory(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mapCategories', 'list'] });
      successNotification('mapCategories.notification.deleteMessage');
    },
  });

  return mutationInfo;
};
