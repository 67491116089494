import { routes } from '@/app/router/routes';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

export const UnknownError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Result
      status='error'
      title={t('unknownError.title')}
      subTitle={t('unknownError.description')}
      extra={
        <Button type='primary' href={routes.base()}>
          {t('notFound.extraTitle')}
        </Button>
      }
    />
  );
};
