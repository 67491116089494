import { useTranslation } from 'react-i18next';

import type { MenuProps } from 'antd';

import { useInteractionsMenuItemsActions } from './useInteractionsMenuItemsActions';
import { DrawShape } from '../../enums';

import {
  BoxIcon,
  CopyIcon,
  DeleteIcon,
  DifferenceIcon,
  HelpIcon,
  LineIcon,
  MergeIcon,
  ModifyIcon,
  PasteIcon,
  PointIcon,
  PolygonIcon,
  SelectIcon,
  SquareIcon,
  TypeMergeIcon,
  TypeShapesIcon,
} from '../../assets';

export const useInteractionsMenuItems = () => {
  const { t } = useTranslation();
  const {
    handleClickSelect,
    handleClickDraw,
    handleClickModify,
    handleClickCopy,
    handleClickPaste,
    handleClickMerge,
    handleClickDifference,
    handleClickDelete,
    handleClickHelp,
  } = useInteractionsMenuItemsActions();

  const items: MenuProps['items'] = [
    {
      key: 'select',
      icon: <SelectIcon />,
      onClick: handleClickSelect,
    },
    {
      key: 'draw',
      icon: <TypeShapesIcon />,
      children: [
        {
          key: 'draw-square',
          label: t('map.editMode.interactions.drawSquare'),
          icon: <SquareIcon />,
          onClick: () => handleClickDraw(DrawShape.square),
        },
        {
          key: 'draw-box',
          label: t('map.editMode.interactions.drawBox'),
          icon: <BoxIcon />,
          onClick: () => handleClickDraw(DrawShape.box),
        },
        {
          key: 'draw-polygon',
          label: t('map.editMode.interactions.drawPolygon'),
          icon: <PolygonIcon />,
          onClick: () => handleClickDraw(DrawShape.polygon),
        },
      ],
    },
    {
      key: 'draw-line',
      icon: <LineIcon />,
      onClick: () => handleClickDraw(DrawShape.line),
    },
    {
      key: 'draw-point',
      icon: <PointIcon />,
      onClick: () => handleClickDraw(DrawShape.point),
    },
    {
      key: 'modify',
      icon: <ModifyIcon />,
      onClick: handleClickModify,
    },
    {
      key: 'copy-cut',
      icon: <CopyIcon />,
      onClick: handleClickCopy,
    },
    {
      key: 'paste',
      icon: <PasteIcon />,
      onClick: handleClickPaste,
    },
    {
      key: 'operation',
      icon: <TypeMergeIcon />,
      children: [
        {
          key: 'operation-merge',
          label: t('map.editMode.interactions.merge'),
          icon: <MergeIcon />,
          onClick: handleClickMerge,
        },
        {
          key: 'operation-difference',
          label: t('map.editMode.interactions.difference'),
          icon: <DifferenceIcon />,
          onClick: handleClickDifference,
        },
      ],
    },
    {
      key: 'delete',
      icon: <DeleteIcon />,
      onClick: handleClickDelete,
    },
    {
      key: 'help',
      icon: <HelpIcon />,
      onClick: handleClickHelp,
    },
  ];

  return items;
};
