import { Switch, theme } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { MapSwitchContainer } from './style';

interface MapSwitchProps {
  label: string;
  handleChange: () => void;
  value: boolean;
  topDistance: number;
}

export const MapSwitch = ({ label, handleChange, value, topDistance }: MapSwitchProps) => {
  const { token } = theme.useToken();

  return (
    <MapSwitchContainer token={token} topDistance={topDistance}>
      {label}
      <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={handleChange} checked={value} />
    </MapSwitchContainer>
  );
};
