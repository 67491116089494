import { useQuery } from '@tanstack/react-query';

import { unitService } from '@/pages/Units/service';

export const useUnitsNoPaginateQuery = () => {
  const queryInfo = useQuery({
    queryKey: ['units', 'select'],
    queryFn: () => unitService.getUnitsNoPaginate(),
    select: units => units.map(unit => ({ label: unit.symbol, value: unit.id })),
  });

  return queryInfo;
};
