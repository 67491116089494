import { getAuthToken } from './auth';

import { apiUrl } from '@/settings';
import { getDefaultLanguage } from '@/settings/i18n/i18n';

import { httpErrorHandler } from './httpErrorHandler';

type RequestType = 'get' | 'post' | 'put' | 'delete';

export type ErrorResponse = { message: string; errors: Record<string, string[]> }; // TODO: error handling
export type SuccessResponse<T> = { status: 'success'; data: T };
export type SuccessResponseGeo<T, G> = G & { status: 'success'; data: T };

const customHeader = (isFormData: boolean) => {
  const token = getAuthToken();
  const lang = getDefaultLanguage();

  const header = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Accept-Language': lang,
  });

  if (!isFormData) {
    header.set('Content-Type', 'application/json');
  }
  return header;
};

const processData = (data?: object | string, isGeoJSON?: boolean) => {
  if (!data) {
    return undefined;
  } else if (data instanceof FormData) {
    return data;
  } else if (isGeoJSON) {
    return data as string;
  }

  return JSON.stringify(data);
};

const fetchData = async (method: RequestType, url: string, data?: object | string, isGeoJSON?: boolean): Promise<Response> => {
  const isFormData = data instanceof FormData;

  const response = await fetch(`${apiUrl}${url}`, {
    method,
    headers: customHeader(isFormData),
    body: processData(data, isGeoJSON),
  });

  if (response.ok) {
    return response;
  }

  throw await httpErrorHandler(response);
};

const processJSON = async <ResponseObjectType>(response: Response) =>
  (await response.json()) as SuccessResponse<ResponseObjectType>;

const apiWrapper =
  (method: RequestType) =>
  async <ResponseObjectType>(url: string, data?: object) => {
    const response = await fetchData(method, url, data);
    return await processJSON<ResponseObjectType>(response);
  };

const processGeoJSON = async <ResponseObjectType, ResponseGeoType>(response: Response) =>
  (await response.json()) as SuccessResponseGeo<ResponseObjectType, ResponseGeoType>;

const apiWrapperGeo =
  (method: RequestType) =>
  async <ResponseObjectType, ResponseGeoType>(url: string, data?: object) => {
    const response = await fetchData(method, url, data, true);
    return await processGeoJSON<ResponseObjectType, ResponseGeoType>(response);
  };

class HTTP {
  static get = apiWrapper('get');
  static post = apiWrapper('post');
  static put = apiWrapper('put');
  static delete = apiWrapper('delete');

  static getGeo = apiWrapperGeo('get');
  static postGeo = apiWrapperGeo('post');
  static putGeo = apiWrapperGeo('put');
  static deleteGeo = apiWrapperGeo('delete');
}

export default HTTP;
