import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IPlaceNotificationUpdate } from '../types';
import type { PlaceNotificationLoaderType } from '../queries';

import { usePlaceNotificationQuery, usePlaceNotificationUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';
import { NotificationType } from '@/types/global';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const PlaceNotificationUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPlaceNotificationUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as PlaceNotificationLoaderType;
  const { data: placeNotification, isLoading: isLoadingQuery } = usePlaceNotificationQuery(initialData);
  const { mutate: placeNotificationUpdate, isLoading: isLoadingMutation } = usePlaceNotificationUpdate();

  const submitHandler = (data: IPlaceNotificationUpdate) => {
    placeNotificationUpdate({ ...data, type: NotificationType.place });
  };

  return (
    <>
      <PageHeader
        title={t('placeNotifications.form.updateTitle')}
        description={t('placeNotifications.form.updateDescription')}
        navigateBackUrl={routes.placeNotifications()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='placeNotificationUpdate'
          form={form}
          initialValues={{ ...placeNotification, photo: placeNotification.photo.id, location: placeNotification.location.id }}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon initialPhotoUrl={placeNotification.photo.path} initialLocation={placeNotification.location} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
