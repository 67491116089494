import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IPlaceNotification } from './types';

import { usePlaceNotificationDelete } from './queries';
import { routes } from '@/app/router/routes';

import { Status } from '@/ui/Table/Status';

export const useColumns = (): ColumnsType<IPlaceNotification> => {
  const { t } = useTranslation();
  const { mutate: placeNotificationDelete } = usePlaceNotificationDelete();

  return [
    {
      title: t('placeNotifications.namePL'),
      dataIndex: 'namePl',
      key: 'namePl',
    },
    {
      title: t('placeNotifications.nameEN'),
      dataIndex: 'nameEn',
      key: 'nameEn',
    },
    {
      title: t('placeNotifications.location'),
      dataIndex: 'location',
      key: 'location',
      render: (text: IPlaceNotification['location']) => text.name,
      align: 'center' as const,
    },
    {
      title: t('placeNotifications.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: IPlaceNotification['status']) => <Status status={text} />,
      align: 'center' as const,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IPlaceNotification) => (
        <Space>
          <Link to={routes.placeNotificationUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('placeNotifications.delete.title')}
            okText={t('placeNotifications.delete.okText')}
            cancelText={t('placeNotifications.delete.cancelText')}
            placement='left'
            onConfirm={() => placeNotificationDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
