import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import type { UploadProps as UploadAntdProps } from 'antd/es/upload/interface';
import type { IFile } from '@/types/global';

import { useUpload } from './queries';
import { useBeforeUpload } from './useBeforeUpload';
import { FileType } from './types';

import { UploadStyled } from './style';

interface UploadProps {
  fileType: FileType;
  initialUrl?: string;
  disabled?: boolean;
  onMapForm?: boolean;
}

export const Upload = ({ fileType, initialUrl, disabled, onMapForm, ...restProps }: UploadProps) => {
  const { t } = useTranslation();
  const [deleted, setDeleted] = useState(false);

  const onSuccess = (file: IFile) => {
    if ('onChange' in restProps && typeof restProps.onChange === 'function') {
      onMapForm ? restProps.onChange(file) : restProps.onChange(file.id);
    }
  };
  const { mutate: upload, data, isLoading } = useUpload(onSuccess);
  const beforeUpload = useBeforeUpload(fileType);

  // due to the default behavior of the library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChange: UploadAntdProps['onChange'] = info => {
    if (info.file.status === 'done' && info.file.originFileObj) {
      const formData = new FormData();
      formData.append('file', info.file.originFileObj);
      upload(formData);
      setDeleted(false);
    }
  };

  const handleDelete = () => {
    if ('onChange' in restProps && typeof restProps.onChange === 'function') {
      restProps.onChange(null);
      setDeleted(true);
    }
  };

  return (
    <>
      <UploadStyled
        accept={beforeUpload.accept}
        listType='picture-card'
        showUploadList={false}
        beforeUpload={beforeUpload.beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
        disabled={disabled}
      >
        {isLoading ? (
          <LoadingOutlined />
        ) : deleted ? (
          <PlusOutlined />
        ) : data?.path ? (
          <img src={data.path} />
        ) : initialUrl ? (
          <img src={initialUrl} />
        ) : (
          <PlusOutlined />
        )}
      </UploadStyled>
      {onMapForm && !deleted && (initialUrl || data?.path) && (
        <Button block={true} onClick={handleDelete}>
          {t('global.delete')}
        </Button>
      )}
    </>
  );
};
