import { useContext } from 'react';
import { MapContext } from '../../MapContext';

const useDuplicate = (deltaX = 5, deltaY = -5) => {
  const { selectCollection, vectorSource, addToNewFeatures } = useContext(MapContext);

  const duplicate = () => {
    const len = selectCollection.getLength();
    if (len < 1) {
      return;
    }

    let feature;
    selectCollection.forEach(item => {
      feature = item.clone();
      feature.setStyle(null); // TODO set style from categories
      feature.getGeometry().translate(deltaX, deltaY);
      vectorSource.addFeature(feature);
      addToNewFeatures(feature);
    });
  };

  return duplicate;
};

export default useDuplicate;
