import { Select } from 'antd';

import { LoadingBlock } from '@/ui/Loading';

import { useAccessControlsQuery } from './queries';

interface SelectDeviceProps {
  disabled: boolean;
}

export const SelectAccessControl = ({ disabled, ...restProps }: SelectDeviceProps) => {
  const { data: accessControls, isLoading } = useAccessControlsQuery();

  return (
    <LoadingBlock loading={isLoading}>
      <Select options={accessControls || []} disabled={disabled} {...restProps} />
    </LoadingBlock>
  );
};
