import { Layout } from 'antd';
import styled from 'styled-components';

export const HeaderStyled = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 32px;
`;
