import { routeParam, routes } from '@/app/router/routes';
import { casLoginUrl } from '@/settings';
import { AuthAPI } from '@/services/auth';

enum Auth {
  token = 'token',
}

export const getAuthToken = () => localStorage.getItem(Auth.token);

export const setAuthToken = (token: string) => {
  localStorage.setItem(Auth.token, token);
};

export const removeAuthToken = () => {
  localStorage.removeItem(Auth.token);
};

export const getTicketFromUrl = () => new URLSearchParams(globalThis.location.search).get('ticket');

export const checkTicket = async (): Promise<{ token: string }> => {
  const ticket = getTicketFromUrl();

  if (ticket) {
    const { data } = await AuthAPI.checkTicket(ticket);

    return data;
  }

  throw new Error('Invalid ticket'); // TODO: error
};

export const getCasUrlWithReturnUrl = (returnUrl: string | null, redirectCounter: number) => {
  const returnTo = new URL(routes.auth(), globalThis.location.origin);
  if (returnUrl) {
    returnTo.searchParams.set(routeParam.returnUrl, returnUrl);
  }

  returnTo.searchParams.set(routeParam.redirectCounter, redirectCounter.toString());

  const urlWithReturnUrl = new URL(`${casLoginUrl}`);
  urlWithReturnUrl.searchParams.set('service', returnTo.toString());
  return urlWithReturnUrl.toString();
};
