import { useContext, useEffect } from 'react';
import { MapContext } from '../MapContext';

import Snap from 'ol/interaction/Snap';

import { CompUtils, commonOptions, commonEvents } from '../utils';

export const SnapInteraction = props => {
  const { map } = useContext(MapContext);

  const options = {
    edge: undefined,
    vertex: undefined,
    pixelTolerance: 12,
    source: undefined,
  };

  const events = {};

  Object.assign(options, commonOptions);
  Object.assign(events, commonEvents);

  useEffect(() => {
    if (!map) {
      return;
    }

    const arrayFeatures = props.source.getFeatures();

    const opts = CompUtils.getOptions(options, props);
    const snapInteraction = new Snap(opts);
    map.addInteraction(snapInteraction);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      snapInteraction.on(eventName, olEvents[eventName]);
    }

    for (const element of arrayFeatures) {
      snapInteraction.addFeature(element);
    }

    return () => map.removeInteraction(snapInteraction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
