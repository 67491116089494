import styled from 'styled-components';

export const LoadingMaskContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
`;
