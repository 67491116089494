import { useContext, useEffect } from 'react';
import { Vector as VectorLayerOl } from 'ol/layer';
import { Vector as VectorSourceOl } from 'ol/source';
import { Style } from 'ol/style';

import { MapContext } from '../MapContext';

interface VectorLayerProps {
  source: VectorSourceOl;
  zIndex: number;
  style: Style;
}

export const VectorLayer = ({ source, style, zIndex }: VectorLayerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { map } = useContext(MapContext) as any;

  useEffect(() => {
    if (!map) {
      return;
    }

    const vectorLayer = new VectorLayerOl({
      source,
      style,
    });

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, source, style, zIndex]);

  return null;
};
