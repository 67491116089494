import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IPlaceNotificationCreate, IPlaceNotificationUpdate } from './types';
import type { ILocationBasic } from '@/types/global';

import { placeNotificationService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const placeNotificationsQuery = (page: number) => ({
  queryKey: ['placeNotifications', 'list', page],
  queryFn: () => placeNotificationService.getPlaceNotifications(page),
  staleTime: 1000 * 60 * 24,
});

export const placeNotificationsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = placeNotificationsQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type PlaceNotificationsLoaderType = Awaited<ReturnType<ReturnType<typeof placeNotificationsLoader>>>;

export const usePlaceNotificationsQuery = (initialData: PlaceNotificationsLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...placeNotificationsQuery(page),
    initialData,
  });

  return queryInfo;
};

const placeNotificationQuery = (id: number) => ({
  queryKey: ['placeNotifications', 'details', id],
  queryFn: () => placeNotificationService.getPlaceNotification(id),
});

export const placeNotificationLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = placeNotificationQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type PlaceNotificationLoaderType = Awaited<ReturnType<ReturnType<typeof placeNotificationLoader>>>;

export const usePlaceNotificationQuery = (initialData: PlaceNotificationLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...placeNotificationQuery(id),
    initialData,
  });

  return queryInfo;
};

export const usePlaceNotificationCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPlaceNotificationCreate) => placeNotificationService.createPlaceNotification(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['placeNotifications', 'list'] });
      successNotification('placeNotifications.notification.createMessage');
      navigate(routes.placeNotifications());
    },
  });

  return mutationInfo;
};

export const usePlaceNotificationUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPlaceNotificationUpdate) => placeNotificationService.updatePlaceNotification(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['placeNotifications', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['placeNotifications', 'details', variables.id] });
      successNotification('placeNotifications.notification.updateMessage');
      navigate(routes.placeNotifications());
    },
  });

  return mutationInfo;
};

export const usePlaceNotificationDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => placeNotificationService.deletePlaceNotification(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['placeNotifications', 'list'] });
      successNotification('placeNotifications.notification.deleteMessage');
    },
  });

  return mutationInfo;
};

export const useLocationsQuery = (search: string, initialLocation?: ILocationBasic) => {
  const queryInfo = useQuery({
    queryKey: ['placeNotifications', 'locations', search],
    placeholderData: initialLocation ? [initialLocation] : [],
    queryFn: () => placeNotificationService.getLocations(search, 100),
    select: locations => locations.map(location => ({ label: location.name, value: location.id })),
    enabled: !!search,
  });

  return queryInfo;
};
