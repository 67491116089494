/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import difference from '@turf/difference';
import { GeoJsonUtils } from '../../utils';
import { MapContext } from '../../MapContext';

export const useDifference = (deleteAfter = false) => {
  const { selectCollection, vectorSource, addToNewFeatures, addToDeleteFeatures } = useContext(MapContext) as any;
  const geoJson = GeoJsonUtils.createGeoJson();

  const merge = (geoJsonCollection: any) => {
    const { features } = geoJsonCollection;
    for (let i = 1; i < features.length; i++) {
      try {
        features[0] = difference(features[0], features[i]);
      } catch (error) {
        // TODO: notification error merge
        return false;
      }
    }
    return features[0];
  };

  const handleClickDifference = () => {
    if (selectCollection.getLength() < 2) {
      return;
    }

    const geoJsonFeatures = geoJson.writeFeaturesObject(selectCollection.getArray());
    const merged = merge(geoJsonFeatures);

    if (!merged) {
      return;
    }

    if (deleteAfter) {
      selectCollection.forEach((item: any) => {
        vectorSource.removeFeature(item);
      });

      addToDeleteFeatures(selectCollection);
    }

    const feature = geoJson.readFeature(merged);
    vectorSource.addFeature(feature);
    addToNewFeatures(feature);

    selectCollection.clear();
  };

  return handleClickDifference;
};
