import { useState } from 'react';
import { theme } from 'antd';

import { useInteractionsMenuItems } from './useInteractionsMenuItems';

import { MenuIcon } from '../../assets';
import { DropdownIcon, DropdownStyled } from './style';

export const InteractionsMenu = () => {
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const menuItems = useInteractionsMenuItems();

  return (
    <DropdownStyled
      menu={{ items: menuItems, selectable: true, expandIcon: <span /> }}
      trigger={['click']}
      open={open}
      onOpenChange={() => setOpen(prevState => !prevState)}
    >
      <DropdownIcon token={token}>
        <MenuIcon />
      </DropdownIcon>
    </DropdownStyled>
  );
};
