import { useCallback, useEffect } from 'react';
import { Select as SelectOl } from 'ol/interaction';
import { SelectEvent } from 'ol/interaction/Select';
import BaseEvent from 'ol/events/Event';

import type { IBeacon } from '../../types';

import { useMapDispatchContext, useMapStateContext } from '../useMapContext';
import { selectStyle } from '../config';

export const SelectInteraction = () => {
  const { map } = useMapStateContext();
  const { setSelectedFeature } = useMapDispatchContext();

  const handleSelect = useCallback(
    (e: BaseEvent | Event) => {
      if (!(e instanceof SelectEvent)) {
        setSelectedFeature(null);
        return;
      }
      const selectedFeature = e.selected.at(0);

      if (!selectedFeature) {
        setSelectedFeature(null);
        return;
      }

      setSelectedFeature({ id: selectedFeature.getId(), ...selectedFeature.getProperties() } as IBeacon);
    },
    [setSelectedFeature],
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    const select = new SelectOl({
      style: selectStyle,
    });

    map.addInteraction(select);

    select.on(['select'], handleSelect);

    return () => {
      if (map) {
        map.removeInteraction(select);
      }
    };
  }, [map, handleSelect]);

  return null;
};
