import { useContext } from 'react';
import { MapBeaconStateContext } from './MapBeaconContext';

export const useMapStateContext = () => {
  const mapStateContext = useContext(MapBeaconStateContext);

  if (!mapStateContext) {
    throw new Error('Component beyond MapBeaconStateContext');
  }

  return mapStateContext;
};
