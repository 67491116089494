import { useContext, useMemo, useState } from 'react';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import { MapContext } from '@/pages/Map/MapContext';

import { MapObjectForm } from './MapObjectForm';
import { SelectObjectType } from './SelectObjectType';
import { AsideContainer } from '../../AsideContainer';
import { Loading } from '@/ui/Loading';

import { useTypesData } from '../useTypesData';
import { findGeoCenter } from '@/pages/Map/utils/findGeoCenter';
import { successNotification } from '@/utils/notification';
import { getFeaturePropertiesToDTO } from './dto';

import { FormStyled } from './style';

export const MapObjectCreate = ({ shape, open, mask, handleClose }) => {
  const { drawnFeature, addToNewFeatures } = useContext(MapContext);
  const [selectedType, setSelectedType] = useState();
  const [form] = Form.useForm();
  const { types, typesLoading } = useTypesData();
  const { t } = useTranslation();

  const geoCenter = useMemo(() => {
    if (isEmpty(drawnFeature)) {
      return [];
    }

    return findGeoCenter(drawnFeature);
  }, [drawnFeature]);

  const handleChange = value => {
    setSelectedType(types.find(element => element.key === value));
  };

  const onCloseAside = () => {
    form.resetFields();
    handleChange();
    handleClose();
  };

  // !!! do not change the order of calls
  const handleSubmit = values => {
    drawnFeature.setProperties({ type: selectedType.key });
    drawnFeature.setProperties(getFeaturePropertiesToDTO(selectedType.geometry_attributes, values));
    onCloseAside();
    addToNewFeatures(drawnFeature);
    successNotification(t('map.editMode.mapObjectAside.successCreate'));
  };

  return (
    <AsideContainer title='' mask={mask} maskClosable={false} open={open} handleClose={onCloseAside}>
      <Loading loading={typesLoading}>
        <SelectObjectType shape={shape} handleChange={handleChange} />
        <FormStyled form={form} onFinish={handleSubmit} layout='vertical' requiredMark='optional' scrollToFirstError>
          {selectedType?.geometry_attributes && (
            <>
              <MapObjectForm
                selectedTypeAttr={selectedType.geometry_attributes}
                geoCenter={geoCenter}
                featureId={null}
                featureToEdit={null}
              />
              <Button type='primary' htmlType='submit'>
                {t('map.editMode.mapObjectAside.submit')}
              </Button>
            </>
          )}
        </FormStyled>
      </Loading>
    </AsideContainer>
  );
};
