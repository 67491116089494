import { theme } from 'antd';

import type { RefObject } from 'react';

import { RotateInput } from './components/RotateInput';
import { RotateReset } from './components/RotateReset';
import { Fullscreen } from './components/Fullscreen';
import { Zoom } from './components/Zoom';

import { BottomMenuContainer } from './style';

interface BottomMenuProps {
  mapContainerRef: RefObject<HTMLDivElement>;
}

export const BottomMenu = ({ mapContainerRef }: BottomMenuProps) => {
  const { token } = theme.useToken();

  return (
    <BottomMenuContainer token={token}>
      <RotateInput />
      <RotateReset />
      <Fullscreen mapContainerRef={mapContainerRef} />
      <Zoom />
    </BottomMenuContainer>
  );
};
