import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingBlock } from '@/ui/Loading';

import { useConnectorValuesData } from '../../useConnectorValuesData';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

interface ConnectorValuesObjectProps {
  dataKey: string;
  propsValue: unknown;
}

export const ConnectorValuesObject = ({ dataKey, propsValue }: ConnectorValuesObjectProps) => {
  const { t } = useTranslation();
  const { translateLabel } = useMapObjectTranslation();
  const { selectOptions, selectOptionsLoading } = useConnectorValuesData(dataKey);

  const label = useMemo(
    () =>
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      selectOptions.find((element: any) => element.key === dataKey)?.find((element: any) => element.value === propsValue)?.label,
    [selectOptions, dataKey, propsValue],
  );

  return <LoadingBlock loading={selectOptionsLoading}>{label ? translateLabel(label) : t('global.noData')}</LoadingBlock>;
};
