import { createGlobalStyle } from 'styled-components';

import 'antd/dist/reset.css';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 10px;
    min-height: 100vh;
  }
  body {
    margin: 0;
    min-height: 100vh;
    overflow-y: hidden;
  }

  .ant-dropdown-menu-title-content {
    text-align: center;
  }

  .ant-app {
    height: 100%;
  }

  .ant-input-number,
  .ant-input-number-group-wrapper {
    width: 100%;
  }
`;
