import { useContext, useEffect } from 'react';
import { Input } from 'antd';

import { MapContext } from '@/pages/Map/MapContext';
import { deleteFirstLetter } from '@/utils/deleteFirstLetter';

interface InputProfileMapProps {
  disabled: boolean;
}

export const InputProfileMap = ({ disabled, ...restProps }: InputProfileMapProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { currentFloor } = useContext(MapContext) as any;

  useEffect(() => {
    if ('onChange' in restProps && typeof restProps.onChange === 'function') {
      restProps.onChange(deleteFirstLetter(currentFloor));
    }
  }, [currentFloor, restProps]);

  return <Input disabled={disabled || currentFloor ? true : false} value={deleteFirstLetter(currentFloor)} {...restProps} />;
};
