import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IAnnouncement } from './types';

import { useAnnouncementDelete } from './queries';
import { routes } from '@/app/router/routes';

import { Check } from '@/ui/Table/Check';
import { Language } from '@/ui/Table/Language';
import { Status } from '@/ui/Table/Status';

export const useColumns = (): ColumnsType<IAnnouncement> => {
  const { t } = useTranslation();
  const { mutate: announcementDelete } = useAnnouncementDelete();

  return [
    {
      title: t('announcements.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('announcements.language'),
      dataIndex: 'lang',
      key: 'lang',
      render: (text: IAnnouncement['lang']) => <Language language={text} />,
      align: 'center' as const,
    },
    {
      title: t('announcements.dateOfPublication'),
      dataIndex: 'dateOfPublication',
      key: 'dateOfPublication',
      align: 'center' as const,
    },
    {
      title: t('announcements.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: IAnnouncement['status']) => <Status status={text} />,
      align: 'center' as const,
    },
    {
      title: t('announcements.isNotification'),
      dataIndex: 'isNotification',
      key: 'isNotification',
      render: (text: IAnnouncement['isNotification']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IAnnouncement) => (
        <Space>
          <Link to={routes.announcementUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('announcements.delete.title')}
            okText={t('announcements.delete.okText')}
            cancelText={t('announcements.delete.cancelText')}
            placement='left'
            onConfirm={() => announcementDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
