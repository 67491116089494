import type { IFile } from '@/types/global';

import { Upload } from '@/ui/Upload';
import { FileType } from '@/ui/Upload/types';

interface UploadMapProps {
  disabled: boolean;
  initialValue: IFile | null;
}

export const UploadMap = ({ initialValue, ...restProps }: UploadMapProps) => {
  return <Upload fileType={FileType.photo} initialUrl={initialValue?.path} onMapForm={true} {...restProps} />;
};
