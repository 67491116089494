import React, { useContext } from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { MapContext } from './MapContext';

import { LoadingMask } from '@/ui/Loading';

import { Layers } from './Layers';
import { TileLayer } from './Layers/TileLayer';
import { VectorLayer } from './Layers/VectorLayer';

import { Interactions } from './Interactions';
import { DragRotateInteraction } from './Interactions/DragRotateInteraction';
import { DrawInt } from './Interactions/DrawInt';
import { ModifyInt } from './Interactions/ModifyInt';
import { MouseZoom } from './Interactions/MouseZoom';
import { SelectInteraction } from './Interactions/SelectInteraction';
import { Transform } from './Interactions/Transform';
import { CopyPaste } from './Interactions/CopyPaste';

import { Controls } from './Controls';
import { InteractionsMenu } from './Controls/InteractionsMenu';
import { SwitchEditMode } from './Controls/SwitchEditMode';
import { SwitchSnap } from './Controls/SwitchSnap';
import { BottomMenu } from './Controls/BottomMenu';
import { FloorsMenu } from './Controls/FloorsMenu';
import { ButtonSaveChanges } from './Controls/ButtonSaveChanges';
import { ButtonGenerateGraph } from './Controls/ButtonGenerateGraph';
import { FeaturesFilter } from './Controls/FeaturesFilter/index.tsx';
import { DeleteModal } from './Controls/DeleteModal';
import { NotEmptyCollectionsModal } from './Controls/NotEmptyCollectionsModal';
import { HelpContent } from './Controls/InteractionsMenu/HelpContent';

import { MapObjectInfo } from './components/MapObject/Info';
import { MapObjectCreate } from './components/MapObject/Modify/MapObjectCreate';
import { MapObjectUpdate } from './components/MapObject/Modify/MapObjectUpdate';

import styles from './Styles/shapeStyles';

import { MapContainer } from './style';
import 'ol/ol.css';

export const MapView = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    vectorSource,
    selectCollection,
    mapSource,
    visibleDrawerDraw,
    visibleDrawerSelect,
    visibleDrawerEdit,
    modalDeleteVisible,
    setVisibleModalDelete,
    editMode,
    mapMenuState,
    modalNotEmptyCollectionsVisible,
    setVisibleNotEmptyCollectionsModal,
    clearCollectionsOnConfirm,
    visibleHelpModal,
    setVisibleHelpModal,
  } = useContext(MapContext);

  const {
    children,
    storeFeatures,
    handleModalDeleteConfirm,
    switchEditMode,
    changeFloor,
    onSelect,
    setVisibleDraw,
    setVisibleSelect,
    setVisibleEdit,
    onDrawend,
    onModifyend,
    generateGraph,
    isBlockingWhenPrompt,
  } = props;
  usePrompt('', isBlockingWhenPrompt);

  return (
    <>
      <MapContainer ref={ref} className='ol-map' id='map'>
        <LoadingMask loading={props.loading}>
          <Layers>
            <TileLayer source={mapSource} zIndex={0} />
            <VectorLayer source={vectorSource} zIndex={1} style={styles['poj']} />
          </Layers>
          <Controls>
            <SwitchEditMode value={editMode} handleChange={switchEditMode} />
            {editMode && <SwitchSnap />}
            {editMode && <InteractionsMenu />}
            {editMode && <ButtonSaveChanges storeFeatures={storeFeatures} />}
            {editMode && <ButtonGenerateGraph generateGraph={generateGraph} />}
            {editMode && <FeaturesFilter />}
            <FloorsMenu changeFloor={changeFloor} />
            <BottomMenu mapContainerRef={ref} />
          </Controls>
          <Interactions>
            <MouseZoom />
            <DragRotateInteraction />
            <CopyPaste />
            {mapMenuState.draw && (
              <DrawInt type={mapMenuState.draw} source={vectorSource} onDrawend={onDrawend} isSnap={mapMenuState.snap} />
            )}
            {mapMenuState.modify && <Transform onRotateend={onModifyend} onTranslateend={onModifyend} onScaleend={onModifyend} />}
            {mapMenuState.modify && <ModifyInt source={vectorSource} isSnap={mapMenuState.snap} onModifyend={onModifyend} />}
            {mapMenuState.select && !mapMenuState.draw && <SelectInteraction onSelect={onSelect} features={selectCollection} />}
          </Interactions>
          {children}
        </LoadingMask>
      </MapContainer>
      <MapObjectInfo
        selected={[...selectCollection.getArray()]}
        mask={false}
        open={visibleDrawerSelect}
        handleClose={() => setVisibleSelect(true)}
      />
      <MapObjectCreate shape={mapMenuState.draw} mask={true} open={visibleDrawerDraw} handleClose={() => setVisibleDraw(true)} />
      <MapObjectUpdate mask={true} open={visibleDrawerEdit} handleClose={() => setVisibleEdit(true)} />
      <DeleteModal
        setVisible={setVisibleModalDelete}
        handleOk={handleModalDeleteConfirm}
        visible={modalDeleteVisible}
        delete={setVisibleModalDelete}
        selected={selectCollection.getArray()}
      />
      <NotEmptyCollectionsModal
        setVisible={setVisibleNotEmptyCollectionsModal}
        clearCollections={clearCollectionsOnConfirm}
        visible={modalNotEmptyCollectionsVisible}
      />
      <Modal
        open={visibleHelpModal}
        title={t('map.editMode.modalHelp.title')}
        onOk={setVisibleHelpModal}
        cancelButtonProps={{ hidden: true }}
      >
        <HelpContent />
      </Modal>
    </>
  );
});
