import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IMapCategoryUpdate } from '../types';
import type { MapCategoryLoaderType } from '../queries';

import { useMapCategoryQuery, useMapCategoryUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const MapCategoryUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IMapCategoryUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as MapCategoryLoaderType;
  const { data: mapCategory, isLoading: isLoadingQuery } = useMapCategoryQuery(initialData);
  const { mutate: mapCategoryUpdate, isLoading: isLoadingMutation } = useMapCategoryUpdate();

  const submitHandler = (data: IMapCategoryUpdate) => {
    mapCategoryUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('mapCategories.form.updateTitle')}
        description={t('mapCategories.form.updateDescription')}
        navigateBackUrl={routes.mapCategories()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='mapCategoryUpdate'
          form={form}
          initialValues={{ ...mapCategory, icon: mapCategory.icon.id }}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon initialIconUrl={mapCategory.icon.path} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
