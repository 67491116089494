import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IDevice } from './types';

import { routes } from '@/app/router/routes';

import { useDeviceDelete } from './queries';

export const useColumns = (): ColumnsType<IDevice> => {
  const { t } = useTranslation();
  const { mutate: deviceDelete } = useDeviceDelete();

  return [
    {
      title: t('devices.namePL'),
      dataIndex: 'namePl',
      key: 'namePl',
    },
    {
      title: t('devices.nameEN'),
      dataIndex: 'nameEn',
      key: 'nameEn',
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IDevice) => (
        <Space>
          <Link to={routes.deviceUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('devices.delete.title')}
            okText={t('devices.delete.okText')}
            cancelText={t('devices.delete.cancelText')}
            placement='left'
            onConfirm={() => deviceDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
