export enum routeParam {
  returnUrl = 'returnUrl',
  floor = 'floor',
  id = 'id',
  redirectCounter = 'redirectCounter',
}

export const subRoutes = {
  base: '/',
  auth: 'auth',
  noAccess: 'no-access',
  notFound: '*',

  create: 'create',
  update: 'update',

  map: 'map',
  mapCategories: 'map-categories',
  announcements: 'announcements',
  placeNotifications: 'place-notifications',
  pushNotifications: 'push-notifications',
  units: 'units',
  devices: 'devices',
  popularLocations: 'popular-locations',
  admins: 'admins',
  profiles: 'profiles',
  navigationCues: 'navigation-cues',
  beacons: 'beacons',
  beaconsMap: 'beacons-map',
};

export const routes = {
  base: () => subRoutes.base,
  auth: () => `${routes.base()}${subRoutes.auth}`,
  noAccess: () => `${routes.base()}${subRoutes.noAccess}`,

  map: () => `${routes.base()}${subRoutes.map}`,
  mapFloor: () => `${routes.map()}/:${routeParam.floor}`,

  mapCategories: () => `${routes.base()}${subRoutes.mapCategories}`,
  mapCategoryCreate: () => `${routes.mapCategories()}/${subRoutes.create}`,
  mapCategoryUpdate: (id: number) => `${routes.mapCategories()}/${subRoutes.update}/${id}`,

  announcements: () => `${routes.base()}${subRoutes.announcements}`,
  announcementCreate: () => `${routes.announcements()}/${subRoutes.create}`,
  announcementUpdate: (id: number) => `${routes.announcements()}/${subRoutes.update}/${id}`,

  placeNotifications: () => `${routes.base()}${subRoutes.placeNotifications}`,
  placeNotificationCreate: () => `${routes.placeNotifications()}/${subRoutes.create}`,
  placeNotificationUpdate: (id: number) => `${routes.placeNotifications()}/${subRoutes.update}/${id}`,

  pushNotifications: () => `${routes.base()}${subRoutes.pushNotifications}`,
  pushNotificationCreate: () => `${routes.pushNotifications()}/${subRoutes.create}`,
  pushNotificationUpdate: (id: number) => `${routes.pushNotifications()}/${subRoutes.update}/${id}`,

  units: () => `${routes.base()}${subRoutes.units}`,
  unitCreate: () => `${routes.units()}/${subRoutes.create}`,
  unitUpdate: (id: number) => `${routes.units()}/${subRoutes.update}/${id}`,

  devices: () => `${routes.base()}${subRoutes.devices}`,
  deviceCreate: () => `${routes.devices()}/${subRoutes.create}`,
  deviceUpdate: (id: number) => `${routes.devices()}/${subRoutes.update}/${id}`,

  popularLocations: () => `${routes.base()}${subRoutes.popularLocations}`,
  popularLocationCreate: () => `${routes.popularLocations()}/${subRoutes.create}`,
  popularLocationUpdate: (id: number) => `${routes.popularLocations()}/${subRoutes.update}/${id}`,

  admins: () => `${routes.base()}${subRoutes.admins}`,

  profiles: () => `${routes.base()}${subRoutes.profiles}`,
  profileCreate: () => `${routes.profiles()}/${subRoutes.create}`,
  profileUpdate: (id: number) => `${routes.profiles()}/${subRoutes.update}/${id}`,

  navigationCues: () => `${routes.base()}${subRoutes.navigationCues}`,
  navigationCueCreate: () => `${routes.navigationCues()}/${subRoutes.create}`,
  navigationCueUpdate: (id: number) => `${routes.navigationCues()}/${subRoutes.update}/${id}`,

  beacons: () => `${routes.base()}${subRoutes.beacons}`,
  beaconUpdate: (id: number) => `${routes.beacons()}/${subRoutes.update}/${id}`,

  beaconsMap: () => `${routes.base()}${subRoutes.beaconsMap}`,
} as const;
