import { useContext, useEffect } from 'react';
import { Tile as TileLayerOl } from 'ol/layer';
import { Tile as TileSourceOl } from 'ol/source';

import { MapContext } from '../MapContext';

interface TileLayerProps {
  source: TileSourceOl;
  zIndex: number;
}

export const TileLayer = ({ source, zIndex }: TileLayerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { map } = useContext(MapContext) as any;

  useEffect(() => {
    if (!map) {
      return;
    }

    const tileLayer = new TileLayerOl({
      source,
      zIndex,
    });

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, source, zIndex]);

  return null;
};
