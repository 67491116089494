import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IDeviceCreate, IDeviceUpdate } from './types';

import { deviceService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const devicesQuery = (page: number) => ({
  queryKey: ['devices', 'list', page],
  queryFn: () => deviceService.getDevices(page),
  staleTime: 1000 * 60 * 24,
});

export const devicesLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = devicesQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type DevicesLoaderType = Awaited<ReturnType<ReturnType<typeof devicesLoader>>>;

export const useDevicesQuery = (initialData: DevicesLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...devicesQuery(page),
    initialData,
  });

  return queryInfo;
};

const deviceQuery = (id: number) => ({
  queryKey: ['devices', 'details', id],
  queryFn: () => deviceService.getDevice(id),
});

export const deviceLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = deviceQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type DeviceLoaderType = Awaited<ReturnType<ReturnType<typeof deviceLoader>>>;

export const useDeviceQuery = (initialData: DeviceLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...deviceQuery(id),
    initialData,
  });

  return queryInfo;
};

export const useDeviceCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IDeviceCreate) => deviceService.createDevice(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices', 'list'] });
      successNotification('devices.notification.createMessage');
      navigate(routes.devices());
    },
  });

  return mutationInfo;
};

export const useDeviceUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IDeviceUpdate) => deviceService.updateDevice(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['devices', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['devices', 'details', variables.id] });
      successNotification('devices.notification.updateMessage');
      navigate(routes.devices());
    },
  });

  return mutationInfo;
};

export const useDeviceDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => deviceService.deleteDevice(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['devices', 'list'] });
      successNotification('devices.notification.deleteMessage');
    },
  });

  return mutationInfo;
};
