import type { IPagination } from '@/types/global';
import type { IAdmin, IAdminCreate } from './types';

import HTTP from '@/utils/http';

export const adminService = {
  getAdmins: (page: number) => HTTP.get<IPagination<IAdmin>>(`admins?page=${page}`).then(response => response.data),
  createAdmin: (adminCreate: IAdminCreate) => HTTP.post('admins', adminCreate),
  deleteAdmin: (id: number) => HTTP.delete(`admins/${id}`),

  getUsers: (search: string, limit: number) =>
    HTTP.get<IAdmin[]>(`users?search=${search}&limit=${limit}`).then(response => response.data),
};
