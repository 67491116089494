import { useRef, useState, useEffect, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Descriptions } from 'antd';
import { Map as MapOl, View as ViewOl } from 'ol';
import { XYZ as XYZSourceOl, Vector as VectorSourceOl } from 'ol/source';
import { GeoJSON } from 'ol/format';
import { fromLonLat } from 'ol/proj';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import type { IBeacon, IBeaconMapCollection } from '../types';

import { mapConfig, tileConfig } from './config';
import { MapDispatchContext, MapStateContext } from './MapContext';
import { changeBeaconColorToBattery } from './utils';

import { AsideContainer } from '@/pages/Map/components/AsideContainer';

import { MapStyled } from './styles';
import 'ol/ol.css';

interface MapContainerProps {
  vectorData: IBeaconMapCollection;
  children: ReactNode;
}

export const MapContainer = ({ vectorData, children }: MapContainerProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [selectedFeature, setSelectedFeature] = useState<IBeacon | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapProfile, setMapProfile] = useState(searchParams.get('profile') ?? mapConfig.defaultProfile);
  const [map, setMap] = useState<MapOl>();
  const tileSource = useMemo<XYZSourceOl>(
    () =>
      new XYZSourceOl({
        url: `${mapConfig.xyz}/${`p${mapProfile}`}/${mapConfig.xyzEnd}`,
        minZoom: tileConfig.minZoom,
        maxZoom: tileConfig.maxZoom,
      }),
    [mapProfile],
  );
  const vectorSource = useMemo<VectorSourceOl>(
    () =>
      new VectorSourceOl({
        format: new GeoJSON(),
      }),
    [],
  );

  const handleChangeMapProfile = (profile: number) => {
    const oldValues = Object.fromEntries(searchParams);
    setSearchParams({ ...oldValues, profile: profile.toString() });
    setMapProfile(profile.toString());
  };

  const handleChangeBatteryScope = (batteryScope?: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!batteryScope) {
      delete oldValues.batteryScope;
      setSearchParams({ ...oldValues });
    } else {
      setSearchParams({ ...oldValues, batteryScope });
    }
  };

  useEffect(() => {
    const options = {
      view: new ViewOl({
        center: fromLonLat(mapConfig.center),
        zoom: mapConfig.zoom,
        minZoom: mapConfig.minZoom,
        maxZoom: mapConfig.maxZoom,
        constrainResolution: mapConfig.constrainResolution,
      }),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new MapOl(options);

    if (mapRef.current) {
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
    }

    return () => {
      mapObject.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    const features = new GeoJSON().readFeatures(vectorData, { featureProjection: mapConfig.featureProjection });
    vectorSource.clear();
    vectorSource.addFeatures(features);

    features.forEach(feature => {
      changeBeaconColorToBattery(feature, 'map');
    });
  }, [vectorData, vectorSource]);

  return (
    <MapStateContext.Provider value={{ map, tileSource, vectorSource, mapProfile }}>
      <MapDispatchContext.Provider value={{ handleChangeMapProfile, handleChangeBatteryScope, setSelectedFeature }}>
        <MapStyled ref={mapRef}>{children}</MapStyled>

        <AsideContainer
          title={selectedFeature?.name ?? ''}
          open={!!selectedFeature}
          handleClose={() => setSelectedFeature(null)}
          mask={false}
        >
          {selectedFeature && (
            <Descriptions column={1} layout='vertical'>
              <Descriptions.Item label={t('beacons.mac')}>{selectedFeature.mac}</Descriptions.Item>
              <Descriptions.Item label={t('beacons.battery')}>{selectedFeature.battery}%</Descriptions.Item>
              <Descriptions.Item label={t('beacons.profile')}>{selectedFeature.profile}</Descriptions.Item>
              <Descriptions.Item label={t('beacons.interval')}>{selectedFeature.interval}ms</Descriptions.Item>
              <Descriptions.Item label={t('beacons.power')}>{selectedFeature.power}dBm</Descriptions.Item>
              <Descriptions.Item label={t('beacons.rssi')}>{selectedFeature.rssi}dBm</Descriptions.Item>
              <Descriptions.Item label={t('beacons.isOutside')}>
                {selectedFeature.isOutside ? <CheckOutlined /> : <CloseOutlined />}
              </Descriptions.Item>
            </Descriptions>
          )}
        </AsideContainer>
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  );
};
