import { useEffect } from 'react';
import { Tile as TileLayerOl } from 'ol/layer';

import { useMapStateContext } from '../useMapContext';

interface TileLayerProps {
  zIndex: number;
}

export const TileLayer = ({ zIndex }: TileLayerProps) => {
  const { map, tileSource } = useMapStateContext();

  useEffect(() => {
    if (!map) {
      return;
    }

    const tileLayer = new TileLayerOl({
      source: tileSource,
      zIndex,
    });

    map.addLayer(tileLayer);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, tileSource, zIndex]);

  return null;
};
