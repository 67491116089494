import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { AdminsLoaderType } from './queries';
import type { IAdmin } from './types';

import { useAdminsQuery } from './queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './Form/FormCommon';

export const Admins = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const initialData = useLoaderData() as AdminsLoaderType;
  const { data: admins, isLoading: isLoading } = useAdminsQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('admins.title')}
        description={t('admins.description')}
        extra={
          <Button type='primary' onClick={() => setOpen(true)}>
            {t('admins.createButtonText')}
          </Button>
        }
      />
      <Table<IAdmin>
        rowKey='id'
        dataSource={admins.data ?? []}
        loading={isLoading}
        columns={useColumns()}
        pagination={usePagination(admins.total, admins.per_page)}
        scroll={{ x: 'max-content' }}
      />
      <FormCommon closeModal={() => setOpen(false)} open={open} />
    </>
  );
};
