import { useTranslation } from 'react-i18next';

import { MapButton } from '../../components/MapButton';

interface ButtonSaveChangesProps {
  storeFeatures: () => void;
}

export const ButtonSaveChanges = ({ storeFeatures }: ButtonSaveChangesProps) => {
  const { t } = useTranslation();

  return <MapButton label={t('map.editMode.saveChanges')} handleClick={storeFeatures} rightDistance={18} />;
};
