import { Logo } from './Logo';
import { Logout } from './Logout';

import { HeaderStyled } from './style';

export const Header = (): JSX.Element => (
  <HeaderStyled>
    <Logo />
    <Logout />
  </HeaderStyled>
);
