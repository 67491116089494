import { useContext } from 'react';

import GeoJsonUtils from '../../utils/GeoJsonUtils';
import { MapContext } from '../../MapContext';
import { InteractionFeatures } from '../../enums';

const useCopy = (cut, onCopy) => {
  const { selectCollection, vectorSource, closeVisibleDrawerSelect } = useContext(MapContext);
  const geoJson = GeoJsonUtils.createGeoJson();

  const copy = () => {
    const len = selectCollection.getLength();
    if (len < 1) {
      return;
    }
    const selected = selectCollection.getArray();
    const features = geoJson.writeFeatures(selected);
    sessionStorage.setItem(InteractionFeatures.copiedFeatures, features);

    if (cut) {
      closeVisibleDrawerSelect();
      selectCollection.forEach(item => {
        vectorSource.removeFeature(item);
      });
      selectCollection.clear();
    }

    if (typeof onCopy === 'function') {
      onCopy({ type: cut ? InteractionFeatures.cut : InteractionFeatures.copy, features: selected });
    }
  };

  return copy;
};

export default useCopy;
