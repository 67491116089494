import { useContext, useEffect } from 'react';
import { MapContext } from '../MapContext';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import { CompUtils, commonEvents } from '../utils';

export const MouseZoom = props => {
  const { map } = useContext(MapContext);

  const options = {
    condition: undefined,
    onFocusOnly: undefined,
    maxDelta: undefined,
    duration: undefined,
    timeout: undefined,
    useAnchor: undefined,
    constrainResolution: undefined,
  };

  const events = {};
  Object.assign(events, commonEvents);

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);

    const mouseZoom = new MouseWheelZoom(opts);
    map.addInteraction(mouseZoom);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      mouseZoom.on(eventName, olEvents[eventName]);
    }

    return () => map.removeInteraction(mouseZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
};
