import { Select } from 'antd';

import { LoadingBlock } from '@/ui/Loading';

import { useUnitsNoPaginateQuery } from './queries';

interface SelectUnitProps {
  disabled: boolean;
}

export const SelectUnit = ({ disabled, ...restProps }: SelectUnitProps) => {
  const { data: units, isLoading } = useUnitsNoPaginateQuery();

  return (
    <LoadingBlock loading={isLoading}>
      <Select
        showSearch
        allowClear
        options={units || []}
        disabled={disabled}
        filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
        {...restProps}
      />
    </LoadingBlock>
  );
};
