import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const shortCuts = {
  copy: 'CTRL + ALT + C',
  paste: 'CTRL + ALT + V',
  cut: 'CTRL + ALT + X',
  duplicate: 'CTRL + ALT + Z',
  scale: 'CTRL',
  rotate: 'SHIFT',
} as const;

export const HelpContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography.Text strong>{t('map.editMode.modalHelp.header')}</Typography.Text>
      <Typography>{`${shortCuts.copy} - ${t('map.editMode.modalHelp.copy')}`}</Typography>
      <Typography>{`${shortCuts.paste} - ${t('map.editMode.modalHelp.paste')}`}</Typography>
      <Typography>{`${shortCuts.cut} - ${t('map.editMode.modalHelp.cut')}`}</Typography>
      <Typography>{`${shortCuts.duplicate} - ${t('map.editMode.modalHelp.duplicate')}`}</Typography>
      <Typography>{`${shortCuts.scale} - ${t('map.editMode.modalHelp.scale')}`}</Typography>
      <Typography>{`${shortCuts.rotate} - ${t('map.editMode.modalHelp.rotate')}`}</Typography>
    </>
  );
};
