import { useQuery } from '@tanstack/react-query';

import { LoadingBlock } from '@/ui/Loading';

import { deviceService } from '@/pages/Devices/service';

interface DeviceObjectProps {
  ids: number[];
}

export const DeviceObject = ({ ids }: DeviceObjectProps) => {
  const {
    data: devices,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['devices', 'mapObject'],
    queryFn: () => deviceService.getDevicesNoPaginate(),
    select: devices => devices.filter(device => ids.includes(device.id)),
    enabled: !!ids,
  });

  return <LoadingBlock loading={isLoading && isFetching}>{devices?.map(device => device.namePl).join(', ')}</LoadingBlock>;
};
