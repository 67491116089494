import { useEffect, useState } from 'react';
import { Descriptions } from 'antd';
import isEmpty from 'lodash.isempty';

import { Loading } from '@/ui/Loading';
import { ActionButtons } from './ActionButtons';
import { BooleanObject } from './ObjectComponents/BooleanObject';
import { CategoryObject } from './ObjectComponents/CategoryObject';
import { ExternalObject } from './ObjectComponents/ExternalObject';
import { ConnectorValuesObject } from './ObjectComponents/ConnectorValuesObject';
import { DeviceObject } from './ObjectComponents/DeviceObject';
import { UnitObject } from './ObjectComponents/UnitObject';
import { DateTimeObject } from './ObjectComponents/DateTimeObject';
import { AccessControlObject } from './ObjectComponents/AccessControlObject';
import { PhotoObject } from './ObjectComponents/PhotoObject';
import { AsideContainer } from '../../AsideContainer';

import { useTypesData } from '../useTypesData';
import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

import { MapObjectComponentType } from '@/pages/Map/enums';

export const MapObjectInfo = ({ selected, mask, open, handleClose }) => {
  const [showAble, setShowAble] = useState(null);
  const [featureProps, setFeatureProps] = useState(null);
  const [selectedTypeAttributes, setSelectedTypeAttributes] = useState(null);
  const { types, typesLoading } = useTypesData();
  const { translate, translateLabel } = useMapObjectTranslation();

  useEffect(() => {
    if (isEmpty(selected) || isEmpty(types)) {
      return;
    }

    const featureProps = selected.at(0).getProperties();
    const selectedTypeAttributes = types.find(element => element.key === featureProps.type)?.geometry_attributes;

    setFeatureProps(featureProps);
    setSelectedTypeAttributes(selectedTypeAttributes);
    setShowAble(selectedTypeAttributes.some(element => element.showable === true));
  }, [selected, types]);

  const componentSwitcher = attr => {
    switch (attr.type) {
      case MapObjectComponentType.select:
        return <ExternalObject objectId={Number.parseInt(featureProps[attr.key])} featureType={attr.key} />;

      case MapObjectComponentType.category:
        return <CategoryObject objectId={Number.parseInt(featureProps[attr.key])} />;

      case MapObjectComponentType.switch:
        return <BooleanObject value={featureProps[attr.key]} />;

      case MapObjectComponentType.selectConnectors:
        return <ConnectorValuesObject propsValue={featureProps[attr.key]} dataKey={attr.key} />;

      case MapObjectComponentType.device:
        return <DeviceObject ids={featureProps[attr.key]} />;

      case MapObjectComponentType.unit:
        return <UnitObject id={featureProps[attr.key]} />;

      case MapObjectComponentType.dateTime:
        return <DateTimeObject value={featureProps[attr.key]} />;

      case MapObjectComponentType.accessControl:
        return <AccessControlObject value={featureProps[attr.key]} />;

      case MapObjectComponentType.photo:
        return <PhotoObject value={featureProps[attr.key]} />;

      default:
        return featureProps[attr.key];
    }
  };

  return (
    <AsideContainer title={featureProps && translate(featureProps.type)} mask={mask} open={open} handleClose={handleClose}>
      <Loading loading={typesLoading}>
        <Descriptions column={1} layout='vertical'>
          {selectedTypeAttributes &&
            selectedTypeAttributes.map(attr =>
              attr.showable ? (
                <Descriptions.Item key={attr.key} label={translateLabel(attr.label)}>
                  {componentSwitcher(attr)}
                </Descriptions.Item>
              ) : null,
            )}
        </Descriptions>
        <ActionButtons showAble={showAble} />
      </Loading>
    </AsideContainer>
  );
};
