import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space, theme } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IMapCategory } from './types';

import { useMapCategoryDelete } from './queries';
import { routes } from '@/app/router/routes';

import { Check } from '@/ui/Table/Check';
import { Color } from '@/ui/Table/Color';
import { Image } from '@/ui/Table/Image';

export const useColumns = (): ColumnsType<IMapCategory> => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { mutate: mapCategoryDelete } = useMapCategoryDelete();

  return [
    {
      title: t('mapCategories.namePL'),
      dataIndex: 'namePl',
      key: 'namePl',
    },
    {
      title: t('mapCategories.nameEN'),
      dataIndex: 'nameEn',
      key: 'nameEn',
    },
    {
      title: t('mapCategories.color'),
      dataIndex: 'color',
      key: 'color',
      render: (text: IMapCategory['color']) => <Color token={token} color={text} />,
    },
    {
      title: t('mapCategories.icon'),
      dataIndex: 'icon',
      key: 'icon',
      render: (text: IMapCategory['icon']) => <Image path={text.path} />,
      align: 'center' as const,
    },
    {
      title: t('mapCategories.activity'),
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (text: IMapCategory['isEnabled']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: t('mapCategories.visibility'),
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (text: IMapCategory['isVisible']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: t('mapCategories.visibilityIcon'),
      dataIndex: 'isVisibleIcon',
      key: 'isVisibleIcon',
      render: (text: IMapCategory['isVisibleIcon']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: t('mapCategories.visibilityCategoryBar'),
      dataIndex: 'isVisibleFilter',
      key: 'isVisibleFilter',
      render: (text: IMapCategory['isVisibleFilter']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: t('mapCategories.range'),
      dataIndex: 'range',
      key: 'range',
      align: 'center' as const,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IMapCategory) => (
        <Space>
          <Link to={routes.mapCategoryUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('mapCategories.delete.title')}
            okText={t('mapCategories.delete.okText')}
            cancelText={t('mapCategories.delete.cancelText')}
            placement='left'
            onConfirm={() => mapCategoryDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
