export enum MapObject {
  campus = 'campus',
  building = 'building',
  level = 'level',
  room = 'room',
  beacon = 'beaconplace',
  poi = 'poi',
  roi = 'roi',
  route = 'route',
  wall = 'wall',
  stairs = 'stairs',
  background = 'background',
  door = 'door',
  furniture = 'furniture',
  parking = 'parking',
  windows = 'windows',
  connector = 'connector',
  zone = 'zone',
}

export enum DrawShape {
  point = 'Point',
  polygon = 'Polygon',
  circle = 'Circle',
  square = 'Square',
  box = 'Box',
  line = 'LineString',
}

export enum MapSettings {
  defaultFloor = 'map',
  startingFloor = 'p0',
  projection = 'EPSG:3857',
}

export enum MapObjectGroup {
  polygon = 'polygon',
  point = 'point',
  line = 'line',
}

export const MapObjectGroupValues = {
  [MapObjectGroup.polygon]: [
    MapObject.campus,
    MapObject.building,
    MapObject.level,
    MapObject.roi,
    MapObject.wall,
    MapObject.zone,
  ],
  [MapObjectGroup.point]: [MapObject.poi, MapObject.beacon, MapObject.connector],
  [MapObjectGroup.line]: [MapObject.route, MapObject.door, MapObject.stairs],
} as const;

export const MapObjectFilterValues = [
  ...MapObjectGroupValues.polygon,
  ...MapObjectGroupValues.point,
  ...MapObjectGroupValues.line,
] as const;

export enum MapObjectComponentType {
  input = 'string',
  inputNumber = 'integer',
  inputProfile = 'integer_profile',
  switch = 'boolean',
  select = 'external_id',
  selectConnectors = 'select',
  category = 'category',
  device = 'multimedia',
  unit = 'unit',
  dateTime = 'datetime',
  accessControl = 'accessControl',
  photo = 'photo',
}

export enum InteractionMenuActionType {
  clickDraw = 'clickDraw',
  clickModify = 'clickModify',
  clickSelect = 'clickSelect',
  clickSnap = 'clickSnap',
}

export enum InteractionFeatures {
  difference = 'difference',
  union = 'union',
  copy = 'copy',
  cut = 'cut',
  paste = 'paste',
  copiedFeatures = 'copiedFeatures',
}
