import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IUnitUpdate } from '../types';
import type { UnitLoaderType } from '../queries';

import { useUnitQuery, useUnitUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const UnitUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IUnitUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as UnitLoaderType;
  const { data: unit, isLoading: isLoadingQuery } = useUnitQuery(initialData);
  const { mutate: unitUpdate, isLoading: isLoadingMutation } = useUnitUpdate();

  const submitHandler = (data: IUnitUpdate) => {
    unitUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('units.form.updateTitle')}
        description={t('units.form.updateDescription')}
        navigateBackUrl={routes.units()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='unitUpdate'
          form={form}
          initialValues={unit}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
