import { useTranslation } from 'react-i18next';

import { MapSwitch } from '../../components/MapSwitch';
import { useContext } from 'react';
import { MapContext } from '../../MapContext';
import { InteractionMenuActionType } from '../../enums';

export const SwitchSnap = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setMapMenuState, mapMenuState } = useContext(MapContext) as any;
  const { t } = useTranslation();

  const changeSwitch = () => setMapMenuState({ type: InteractionMenuActionType.clickSnap });

  return <MapSwitch label={t('map.editMode.snap')} handleChange={changeSwitch} value={mapMenuState.snap} topDistance={5} />;
};
