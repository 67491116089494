import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import connectorValuesActions from '@/redux/connectorValues/actions';
import { getLoadingStatus, getSelectOptions } from '@/redux/connectorValues/selectors';

export const useConnectorValuesData = (dataKey: string) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(getSelectOptions);
  const selectOptionsLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!dataKey) {
      return;
    }

    dispatch(connectorValuesActions.loadSelectOptions(dataKey));
  }, [dispatch, dataKey]);

  return { selectOptions, selectOptionsLoading };
};
