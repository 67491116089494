import { useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAdminCreate, useUsersQuery } from '../queries';
import { useDebounce } from '@/hooks/useDebounce';

import { ModalStyled } from './styles';

interface FormCommonProps {
  open: boolean;
  closeModal: () => void;
}

export const FormCommon = ({ closeModal, open }: FormCommonProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [userId, setUserId] = useState<number | null>(null);
  const debouncedSearch = useDebounce(search, 500);
  const { data: users, isFetching: isFetchingQuery, isLoading: isLoadingQuery } = useUsersQuery(debouncedSearch);
  const { mutate: adminCreate, isLoading: isLoadingMutation } = useAdminCreate();

  return (
    <ModalStyled
      title={t('admins.createButtonText')}
      open={open}
      okText={t('global.add')}
      okButtonProps={{ loading: isLoadingMutation, disabled: !userId }}
      onOk={() => {
        adminCreate({ userId: userId ?? 0 });
        closeModal();
      }}
      onCancel={() => closeModal()}
      afterClose={() => setUserId(null)}
    >
      <Select
        showSearch
        loading={isFetchingQuery && isLoadingQuery}
        options={users}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={setSearch}
        onChange={setUserId}
        value={userId}
      />
    </ModalStyled>
  );
};
