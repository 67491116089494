import { Link } from 'react-router-dom';

import { routes } from '@/app/router/routes';

import { LogoContainer, LogoIcon } from './style';
import logoIcon from './logo.png';

export const Logo = () => (
  <LogoContainer>
    <Link to={routes.base()}>
      <LogoIcon src={logoIcon} />
    </Link>
  </LogoContainer>
);
