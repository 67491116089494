import { useContext, useEffect, useState, useCallback } from 'react';
import { View } from 'ol';
import { theme } from 'antd';
import { ObjectEvent } from 'ol/Object';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { MapContext } from '@/pages/Map/MapContext';
import { viewConfig } from '@/pages/Map/utils';

import { ZoomButton, ZoomInput } from './style';

interface ZoomProps {
  deltaInPrc?: number;
}

export const Zoom = ({ deltaInPrc = 10 }: ZoomProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { map } = useContext(MapContext) as any;
  const { token } = theme.useToken();
  const [view, setView] = useState<View>();
  const [zoom, setZoom] = useState('');
  const [minZoom, setMinZoom] = useState(viewConfig.minZoom);
  const [maxZoom, setMaxZoom] = useState(viewConfig.maxZoom);
  const [delta, setDelta] = useState(0);

  const getRoundedZoom = useCallback((zoom: number) => `${Math.round((zoom * 100.0) / minZoom)}%`, [minZoom]);

  const handleChange = (value: number) => {
    if (!view) {
      return;
    }

    const oldZoom = view.getZoom() ?? 0;
    const newZoom = oldZoom + value;
    view.cancelAnimations();

    if (newZoom >= maxZoom) {
      view.animate({ zoom: oldZoom + (maxZoom - oldZoom) });
    } else if (newZoom <= minZoom) {
      view.animate({ zoom: oldZoom - (oldZoom - minZoom) });
    } else {
      view.animate({ zoom: oldZoom + value });
    }
  };

  const handleMouseWheel = useCallback(
    (e: ObjectEvent) => {
      setZoom(getRoundedZoom(e.target.getZoom()));
    },
    [getRoundedZoom],
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    const mapView = map.getView();

    if (!mapView) {
      return;
    }

    setView(mapView);
    setZoom(getRoundedZoom(mapView.getZoom()));
    setMinZoom(mapView.getMinZoom());
    setMaxZoom(mapView.getMaxZoom());

    mapView.on('change:resolution', handleMouseWheel);

    return () => mapView.un('change:resolution', handleMouseWheel);
  }, [map, getRoundedZoom, handleMouseWheel]);

  useEffect(() => {
    setDelta((minZoom * deltaInPrc) / 100.0);
  }, [deltaInPrc, minZoom]);

  return (
    <>
      <ZoomButton onClick={() => handleChange(delta)} token={token}>
        <PlusCircleOutlined />
      </ZoomButton>
      <ZoomInput token={token}>{zoom}</ZoomInput>
      <ZoomButton onClick={() => handleChange(-delta)} token={token}>
        <MinusCircleOutlined />
      </ZoomButton>
    </>
  );
};
