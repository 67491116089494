import { useTranslation } from 'react-i18next';

interface ValidationOptions {
  required: undefined;
}

export const useValidation = () => {
  const { t } = useTranslation();

  const requiredField = {
    required: true,
    message: t('validation.required'),
  };

  const validationFn = <T extends keyof ValidationOptions>(key: T) => {
    switch (key) {
      case 'required':
        return [requiredField];
    }
  };

  return { validate: validationFn };
};
