import { useContext, useState, useEffect } from 'react';
import { View } from 'ol';
import { theme } from 'antd';
import { degreesToRadians, radiansToDegrees } from '@turf/helpers';

import type { ObjectEvent } from 'ol/Object';

import { MapContext } from '@/pages/Map/MapContext';
import { viewConfig } from '@/pages/Map/utils/viewConfig';

import { InputNumberStyled } from './style';

export const RotateInput = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { map } = useContext(MapContext) as any;
  const { token } = theme.useToken();
  const [view, setView] = useState<View>();
  const [rotation, setRotation] = useState(0);

  const handleChange = (value: number | string | null) => {
    if (!view || !value) {
      return;
    }

    const parsedValue = typeof value === 'number' ? value : Number.parseInt(value);

    const delta = rotation - parsedValue;

    if (parsedValue > viewConfig.maxRotation || parsedValue < viewConfig.minRotation) {
      return;
    }

    view.adjustRotation(degreesToRadians(-delta));
    setRotation(parsedValue);
  };

  const onChangeRotation = (e: ObjectEvent) => {
    setRotation(Math.trunc(radiansToDegrees(e.target.getRotation())));
  };

  useEffect(() => {
    if (!map) {
      return;
    }

    setView(map.getView());
  }, [map]);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.on('change:rotation', onChangeRotation);
    setRotation(Math.round(radiansToDegrees(view.getRotation())));

    return () => view.un('change:rotation', onChangeRotation);
  }, [view]);

  return (
    <InputNumberStyled min={0} max={359} step={1} value={rotation} onChange={handleChange} prefix={<>&deg;</>} token={token} />
  );
};

export default RotateInput;
