import { theme } from 'antd';

import { useFloorsMenuQuery } from './queries';
import { useMapDispatchContext, useMapStateContext } from '../../useMapContext';

import { FloorsIcon } from '@/pages/Map/assets';
import { DropdownStyled, DropdownIcon } from './style';

export const FloorsMenu = (): JSX.Element => {
  const { mapProfile } = useMapStateContext();
  const { handleChangeMapProfile } = useMapDispatchContext();
  const { data: floorsMenu, isLoading } = useFloorsMenuQuery(handleChangeMapProfile);
  const { token } = theme.useToken();

  return (
    <DropdownStyled
      menu={{
        items: floorsMenu,
        selectable: true,
        selectedKeys: [mapProfile],
      }}
      trigger={['click']}
      disabled={isLoading}
    >
      <DropdownIcon token={token}>
        <FloorsIcon />
      </DropdownIcon>
    </DropdownStyled>
  );
};
