import { combineReducers } from 'redux';

import Features from './features/reducer';
import Types from './types/reducer';
import GeoBasic from './geoBasic/reducer';
import CategoriesBasic from './categoriesBasic/reducer';
import ConnectorValues from './connectorValues/reducer';

export const rootReducer = combineReducers({
  features: Features,
  types: Types,
  geoBasic: GeoBasic,
  categoriesBasic: CategoriesBasic,
  connectorValues: ConnectorValues,
});
