import { Link, useLoaderData } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { DevicesLoaderType } from './queries';
import type { IDevice } from './types';

import { useDevicesQuery } from './queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';

export const Devices = () => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as DevicesLoaderType;
  const { data: devices, isLoading } = useDevicesQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('devices.title')}
        description={t('devices.description')}
        extra={
          <Link to={routes.deviceCreate()}>
            <Button type='primary'>{t('devices.createButtonText')}</Button>
          </Link>
        }
      />
      <Table<IDevice>
        rowKey='id'
        dataSource={devices.data ?? []}
        loading={isLoading}
        columns={useColumns()}
        pagination={usePagination(devices.total, devices.per_page)}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};
