import HTTP from '@/utils/http';

const GeoBasic = {
  getSelectOptions: (type, centerPoint = [null, null], profile, withId = null, withoutId = null) =>
    HTTP.get(
      `geo-basic?geomType=${type}&p=${centerPoint[0]},${centerPoint[1]}&profile=${profile}&with=${withId}&without=${withoutId}`,
    ).then(response => response.data),

  getSingleObjectName: (type, id) => HTTP.get(`geo-basic?geomType=${type}&id=${id}`).then(response => response.data),
};

export default GeoBasic;
