import type { IPagination } from '@/types/global';
import type { IBeacon, IBeaconMapCollection, IBeaconUpdate, IBuilding, ISingleBeaconMap } from './types';
import HTTP from '@/utils/http';

export const beaconService = {
  getBeacons: (searchParams?: URLSearchParams) =>
    HTTP.get<IPagination<IBeacon>>(`beacons-paginated?${searchParams}`).then(response => response.data),
  getBeacon: (id: number) => HTTP.get<IBeacon>(`beacons-paginated/${id}`).then(response => response.data),
  updateBeacon: (beaconUpdate: IBeaconUpdate) => HTTP.put(`beacons/${beaconUpdate.id}`, beaconUpdate),
  deleteBeacon: (id: number) => HTTP.delete(`beacons/${id}`),

  getBuildings: () => HTTP.get<IBuilding[]>(`geo-by-type?searchType=building`).then(response => response.data),

  getBeaconsMap: (searchParams?: URLSearchParams) => HTTP.getGeo<never, IBeaconMapCollection>(`beacons?${searchParams}`),
  getBeaconMap: (id: number) => HTTP.get<ISingleBeaconMap>(`beacons/${id}`).then(response => response.data),
};
