import type { GlobalToken } from 'antd';

import { ColorSquare, ColorContainer } from './style';

interface ColorProps {
  token: GlobalToken;
  color: string;
}

export const Color = ({ token, color }: ColorProps) => (
  <ColorContainer>
    <ColorSquare token={token} color={color} />
    {color}
  </ColorContainer>
);
