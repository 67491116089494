import { IPagination } from '@/types/global';
import type { IMapCategory, IMapCategoryCreate, IMapCategoryUpdate } from './types';

import HTTP from '@/utils/http';

export const mapCategoriesService = {
  getMapCategories: (page: number) =>
    HTTP.get<IPagination<IMapCategory>>(`category-geo?page=${page}`).then(response => response.data),
  getMapCategory: (id: number) => HTTP.get<IMapCategory>(`category-geo/${id}`).then(response => response.data),
  createMapCategory: (category: IMapCategoryCreate) => HTTP.post('category-geo', category),
  updateMapCategory: (category: IMapCategoryUpdate) => HTTP.put(`category-geo/${category.id}`, category),
  deleteMapCategory: (id: number) => HTTP.delete(`category-geo/${id}`),
};
