import { useNavigate, useParams } from 'react-router-dom';

import { MapView } from './MapView';
import withMapContainer from './withMapContainer';

const MainMap = withMapContainer(MapView);

export const Map = () => {
  const { floor } = useParams();
  const navigate = useNavigate();

  return <MainMap floor={floor} navigate={navigate} />;
};
