import { Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';
import { useLanguageOptions } from '@/hooks/selectOptions';

import { Upload } from '@/ui/Upload';
import { FileType } from '@/ui/Upload/types';

interface FormCommonProps {
  initialPhotoUrl?: string;
}

export const FormCommon = ({ initialPhotoUrl }: FormCommonProps) => {
  const { t } = useTranslation();
  const { validate } = useValidation();
  const languageOptions = useLanguageOptions(t);

  return (
    <>
      <Form.Item name='language' label={t('navigationCues.language')} rules={validate('required')}>
        <Select options={languageOptions} />
      </Form.Item>
      <Form.Item name='routerKey' label={t('navigationCues.routerKey')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='translation' label={t('navigationCues.translation')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='photo' label={t('navigationCues.photo')} rules={validate('required')}>
        <Upload fileType={FileType.icon} initialUrl={initialPhotoUrl} />
      </Form.Item>
    </>
  );
};
