import type { IPagination } from '@/types/global';
import type { INavigationCue, INavigationCueCreate, INavigationCueUpdate } from './types';

import HTTP from '@/utils/http';

export const navigationCuesService = {
  getNavigationCues: (page: number) =>
    HTTP.get<IPagination<INavigationCue>>(`router-dictionary?page=${page}`).then(response => response.data),
  getNavigationCue: (id: number) => HTTP.get<INavigationCue>(`router-dictionary/${id}`).then(response => response.data),
  createNavigationCue: (navigationCueCreate: INavigationCueCreate) => HTTP.post('router-dictionary', navigationCueCreate),
  updateNavigationCue: (navigationCueUpdate: INavigationCueUpdate) =>
    HTTP.put(`router-dictionary/${navigationCueUpdate.id}`, navigationCueUpdate),
  deleteNavigationCue: (id: number) => HTTP.delete(`router-dictionary/${id}`),
};
