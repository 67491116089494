import { useMapObjectTranslation } from '@/hooks/useMapObjectTranslation';

interface AccessControlObjectProps {
  value: string;
}

export const AccessControlObject = ({ value }: AccessControlObjectProps) => {
  const { translateLabel } = useMapObjectTranslation();

  return value && translateLabel(value);
};
