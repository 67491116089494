import { Form, Input, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from '@/hooks/useValidation';
import { useLanguageOptions, useStatusOptions } from '@/hooks/selectOptions';

import { Upload } from '@/ui/Upload';
import { FileType } from '@/ui/Upload/types';
import { WysiwygEditor } from '@/ui/WysiwygEditor';

interface FormCommonProps {
  initialPhotoUrl?: string;
}

export const FormCommon = ({ initialPhotoUrl }: FormCommonProps) => {
  const { t } = useTranslation();
  const { validate } = useValidation();
  const form = Form.useFormInstance();
  const languageOptions = useLanguageOptions(t);
  const statusOptions = useStatusOptions(t);

  return (
    <>
      <Form.Item name='status' label={t('announcements.status')} rules={validate('required')}>
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item name='lang' label={t('announcements.language')} rules={validate('required')}>
        <Select options={languageOptions} />
      </Form.Item>
      <Form.Item name='name' label={t('announcements.name')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='description' label={t('announcements.text')} rules={validate('required')}>
        <WysiwygEditor
          value={form.getFieldValue('description')}
          setValue={(value: string) => form.setFieldValue('description', value)}
        />
      </Form.Item>
      <Form.Item
        name='isNotification'
        label={t('announcements.isNotification')}
        valuePropName='checked'
        rules={validate('required')}
      >
        <Switch />
      </Form.Item>
      <Form.Item name='photo' label={t('announcements.photo')} rules={validate('required')}>
        <Upload fileType={FileType.photo} initialUrl={initialPhotoUrl} />
      </Form.Item>
    </>
  );
};
