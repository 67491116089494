/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import union from '@turf/union';

import { GeoJsonUtils } from '../../utils';
import { MapContext } from '../../MapContext';

export const useMerge = (deleteAfter = true) => {
  const { selectCollection, vectorSource, addToNewFeatures, addToDeleteFeatures } = useContext(MapContext) as any;
  const geoJson = GeoJsonUtils.createGeoJson();

  const merge = (geoJsonCollection: any, props: any) => {
    const { features } = geoJsonCollection;
    const len = features.length;
    for (let i = 1; i < len; i++) {
      try {
        features[0] = union(features[0], features[i], props);
      } catch (error) {
        // TODO: notification error merge
        return false;
      }
    }
    return features[0];
  };

  const handleClickMerge = () => {
    if (selectCollection.getLength() < 2) {
      return;
    }

    const geoJsonFeatures = geoJson.writeFeaturesObject(selectCollection.getArray());
    const merged = merge(geoJsonFeatures, geoJsonFeatures.features[0]);

    if (!merged) {
      return;
    }

    if (deleteAfter) {
      selectCollection.forEach((item: any) => {
        vectorSource.removeFeature(item);
      });

      addToDeleteFeatures(selectCollection);
    }

    const feature = geoJson.readFeature(merged);
    vectorSource.addFeature(feature);
    addToNewFeatures(feature);

    selectCollection.clear();
  };

  return handleClickMerge;
};
