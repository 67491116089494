import { MapContext } from '@/pages/Map/MapContext';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useContext } from 'react';
import { SpaceStyled } from './style';
import { useTranslation } from 'react-i18next';

interface ActionButtonsProps {
  showAble: boolean;
}

export const ActionButtons = ({ showAble }: ActionButtonsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { selectCollection, setFeatureToEdit, setVisibleModalDelete } = useContext(MapContext) as any;
  const { t } = useTranslation();

  const handleClickEdit = () => setFeatureToEdit(selectCollection.getArray()[0]);

  return (
    <SpaceStyled direction='vertical'>
      <Button type='primary' onClick={handleClickEdit} disabled={!showAble} block icon={<EditOutlined />}>
        {t('map.editMode.info.edit')}
      </Button>
      <Button onClick={setVisibleModalDelete} block icon={<DeleteOutlined />}>
        {t('map.editMode.info.delete')}
      </Button>
    </SpaceStyled>
  );
};
