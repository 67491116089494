import { Link, useLoaderData } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { PushNotificationsLoaderType } from './queries';
import type { IPushNotification } from './types';

import { usePushNotificationsQuery } from './queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';

export const PushNotifications = () => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as PushNotificationsLoaderType;
  const { data: pushNotifications, isLoading } = usePushNotificationsQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('pushNotifications.title')}
        description={t('pushNotifications.description')}
        extra={
          <Link to={routes.pushNotificationCreate()}>
            <Button type='primary'>{t('pushNotifications.createButtonText')}</Button>
          </Link>
        }
      />
      <Table<IPushNotification>
        rowKey='id'
        dataSource={pushNotifications.data ?? []}
        loading={isLoading}
        columns={useColumns()}
        pagination={usePagination(pushNotifications.total, pushNotifications.per_page)}
        scroll={{ x: 'max-content' }}
      />
    </>
  );
};
