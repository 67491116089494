import { useTranslation } from 'react-i18next';

import type { RcFile } from 'antd/lib/upload/interface';

import { FileType } from './types';
import { errorNotification } from '@/utils/notification';
import { assertUnreachable } from '@/utils/assertUnreachable';

export const useBeforeUpload = (fileType: FileType) => {
  const { t } = useTranslation();

  switch (fileType) {
    case FileType.icon:
      return {
        accept: '.png',
        beforeUpload: (file: RcFile) => {
          const isValidFormat = file.type === 'image/png'; // PNG
          const isValidSize = file.size / 1024 / 1024 < 0.02; // 20kB

          if (!isValidFormat) {
            errorNotification(t('upload.validation.icon.notValidFormat'));
          }

          if (!isValidSize) {
            errorNotification(t('upload.validation.icon.notValidSize'));
          }

          return isValidFormat && isValidSize;
        },
      };

    case FileType.photo:
      return {
        accept: '.jpg, .jpeg, .png',
        beforeUpload: (file: RcFile) => {
          const isValidFormat = ['image/png', 'image/jpeg'].includes(file.type); // PNG and JPEG
          const isValidSize = file.size / 1024 / 1024 < 2; // 2MB

          if (!isValidFormat) {
            errorNotification(t('upload.validation.photo.notValidFormat'));
          }

          if (!isValidSize) {
            errorNotification(t('upload.validation.photo.notValidSize'));
          }

          return isValidFormat && isValidSize;
        },
      };

    default:
      return assertUnreachable(fileType);
  }
};
