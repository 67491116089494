import { Menu as MenuAntd } from 'antd';

import { useCurrentKey } from './useCurrentKey';
import { useMenuItemList } from './useMenuItemList';

import { StyledSider } from './style';

export const Menu = (): JSX.Element => {
  const menuItems = useMenuItemList();
  const currentKey = useCurrentKey();

  return (
    <StyledSider>
      <MenuAntd mode='inline' selectedKeys={currentKey} items={menuItems} />
    </StyledSider>
  );
};
