import styled from 'styled-components';

export const LogoIcon = styled.img`
  height: 100%;
  padding: 8px;
  object-fit: cover;
`;

export const LogoContainer = styled.section`
  height: 100%;
`;
