import { useTranslation } from 'react-i18next';

import { MapButton } from '../../components/MapButton';

interface ButtonGenerateGraphProps {
  generateGraph: () => void;
}

export const ButtonGenerateGraph = ({ generateGraph }: ButtonGenerateGraphProps) => {
  const { t } = useTranslation();

  return <MapButton label={t('map.editMode.generateGraph')} handleClick={generateGraph} rightDistance={35} />;
};
