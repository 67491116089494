import ReactQuill from 'react-quill';

import { QuillToolbar, modules, formats } from './Toolbar';

import { WysiwygEditorContainer } from './style';
import 'react-quill/dist/quill.snow.css';

interface WysiwygEditorProps {
  value: string;
  setValue: (value: string) => void;
}

export const WysiwygEditor = ({ value, setValue }: WysiwygEditorProps): JSX.Element => (
  <WysiwygEditorContainer>
    <QuillToolbar />
    <ReactQuill theme='snow' value={value} onChange={setValue} modules={modules} formats={formats} />
  </WysiwygEditorContainer>
);
