import type { IPagination } from '@/types/global';
import type { IPushNotification, IPushNotificationCreate, IPushNotificationUpdate } from './types';

import HTTP from '@/utils/http';

export const pushNotificationService = {
  getPushNotifications: (page: number) =>
    HTTP.get<IPagination<IPushNotification>>(`push-notifications?page=${page}`).then(response => response.data),
  getPushNotification: (id: number) => HTTP.get<IPushNotification>(`notification/${id}`).then(response => response.data),
  createPushNotification: (pushNotificationCreate: IPushNotificationCreate) => HTTP.post('notification', pushNotificationCreate),
  updatePushNotification: (pushNotificationUpdate: IPushNotificationUpdate) =>
    HTTP.put(`notification/${pushNotificationUpdate.id}`, pushNotificationUpdate),
  deletePushNotification: (id: number) => HTTP.delete(`notification/${id}`),
};
