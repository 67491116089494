import { useContext, useEffect, useState } from 'react';
import { MapContext } from '../MapContext';

import Modify from 'ol/interaction/Modify';

import { CompUtils, commonOptions, commonEvents } from '../utils';
import { SnapInteraction } from './SnapInteraction';

export const ModifyInt = props => {
  const [snapElement, setSnapElement] = useState(null);
  const { map } = useContext(MapContext);

  const options = {
    condition: undefined,
    deleteCondition: undefined,
    insertVertexCondition: undefined,
    pixelTolerance: 12,
    style: undefined,
    source: undefined,
    hitDetection: undefined,
    wrapX: undefined,
  };

  const events = {
    modifyend: undefined,
    modifystart: undefined,
  };

  Object.assign(options, commonOptions);
  Object.assign(events, commonEvents);

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);
    const modifyInteraction = new Modify(opts);
    map.addInteraction(modifyInteraction);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      modifyInteraction.on(eventName, olEvents[eventName]);
    }

    setSnapElement(<SnapInteraction source={props.source} />);

    return () => map.removeInteraction(modifyInteraction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return <>{props.isSnap && snapElement}</>;
};
