import type { IPagination } from '@/types/global';
import type { IProfile, IProfileCreate, IProfileUpdate } from './types';

import HTTP from '@/utils/http';

export const profileService = {
  getProfiles: (page: number) => HTTP.get<IPagination<IProfile>>(`availableprofile?page=${page}`).then(response => response.data),
  getProfile: (id: number) => HTTP.get<IProfile>(`availableprofile/${id}`).then(response => response.data),
  createProfile: (profileCreate: IProfileCreate) => HTTP.post('availableprofile', profileCreate),
  updateProfile: (profileUpdate: IProfileUpdate) => HTTP.put(`availableprofile/${profileUpdate.id}`, profileUpdate),
  deleteProfile: (id: number) => HTTP.delete(`availableprofile/${id}`),

  getProfilesNoPaginate: () => HTTP.get<IProfile[]>(`availableprofile?paginated=0`).then(response => response.data),
};
