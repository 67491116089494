import { useTranslation } from 'react-i18next';

import { MapObject } from '../../enums';

export const useObjectTypesFeaturesFilter = () => {
  const { t } = useTranslation();

  return [
    { label: t('map.editMode.filter.campus'), value: MapObject.campus },
    { label: t('map.editMode.filter.building'), value: MapObject.building },
    { label: t('map.editMode.filter.level'), value: MapObject.level },
    { label: t('map.editMode.filter.wall'), value: MapObject.wall },
    { label: t('map.editMode.filter.roi'), value: MapObject.roi },
    { label: t('map.editMode.filter.beacon'), value: MapObject.beacon },
    { label: t('map.editMode.filter.route'), value: MapObject.route },
    { label: t('map.editMode.filter.connector'), value: MapObject.connector },
    { label: t('map.editMode.filter.poi'), value: MapObject.poi },
    { label: t('map.editMode.filter.stairs'), value: MapObject.stairs },
    { label: t('map.editMode.filter.door'), value: MapObject.door },
  ];
};
