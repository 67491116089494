import { createBrowserRouter, redirect } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import type { RouteObject } from 'react-router-dom';

import { routeParam, routes, subRoutes } from './routes';
import { authenticate, requireUserSession } from './loaders';

import { Map } from '@/pages/Map';
import { NoAccess } from '@/pages/NoAccess';
import { NotFound } from '@/pages/NotFound';
import { ErrorBoundary } from '@/pages/ErrorBoundary';
import { AuthenticatedLayout } from '@/pages/AuthenticatedLayout';
import { PageLayout } from '@/pages/PageLayout';
import { MapCategories } from '@/pages/MapCategories';
import { MapCategoryCreate } from '@/pages/MapCategories/Form/MapCategoryCreate';
import { MapCategoryUpdate } from '@/pages/MapCategories/Form/MapCategoryUpdate';
import { Announcements } from '@/pages/Announcements';
import { AnnouncementCreate } from '@/pages/Announcements/Form/AnnouncementCreate';
import { AnnouncementUpdate } from '@/pages/Announcements/Form/AnnouncementUpdate';
import { PlaceNotifications } from '@/pages/PlaceNotifications';
import { PlaceNotificationCreate } from '@/pages/PlaceNotifications/Form/PlaceNotificationCreate';
import { PlaceNotificationUpdate } from '@/pages/PlaceNotifications/Form/PlaceNotificationUpdate';
import { PushNotifications } from '@/pages/PushNotifications';
import { PushNotificationCreate } from '@/pages/PushNotifications/Form/PushNotificationCreate';
import { PushNotificationUpdate } from '@/pages/PushNotifications/Form/PushNotificationUpdate';
import { Units } from '@/pages/Units';
import { UnitCreate } from '@/pages/Units/Form/UnitCreate';
import { UnitUpdate } from '@/pages/Units/Form/UnitUpdate';
import { Devices } from '@/pages/Devices';
import { DeviceCreate } from '@/pages/Devices/Form/DeviceCreate';
import { DeviceUpdate } from '@/pages/Devices/Form/DeviceUpdate';
import { PopularLocations } from '@/pages/PopularLocations';
import { PopularLocationCreate } from '@/pages/PopularLocations/Form/PopularLocationCreate';
import { PopularLocationUpdate } from '@/pages/PopularLocations/Form/PopularLocationUpdate';
import { Admins } from '@/pages/Admins';
import { Profiles } from '@/pages/Profiles';
import { ProfileCreate } from '@/pages/Profiles/Form/ProfileCreate';
import { ProfileUpdate } from '@/pages/Profiles/Form/ProfileUpdate';
import { NavigationCues } from '@/pages/NavigationCues';
import { NavigationCueCreate } from '@/pages/NavigationCues/Form/NavigationCueCreate';
import { NavigationCueUpdate } from '@/pages/NavigationCues/Form/NavigationCueUpdate';
import { Beacons } from '@/pages/Beacons/List';
import { BeaconUpdate } from '@/pages/Beacons/Form/BeaconUpdate';
import { BeaconsMap } from '@/pages/Beacons/Map';

import { mapCategoriesLoader, mapCategoryLoader } from '@/pages/MapCategories/queries';
import { announcementsLoader, announcementLoader } from '@/pages/Announcements/queries';
import { placeNotificationsLoader, placeNotificationLoader } from '@/pages/PlaceNotifications/queries';
import { pushNotificationsLoader, pushNotificationLoader } from '@/pages/PushNotifications/queries';
import { unitsLoader, unitLoader } from '@/pages/Units/queries';
import { devicesLoader, deviceLoader } from '@/pages/Devices/queries';
import { popularLocationsLoader, popularLocationLoader } from '@/pages/PopularLocations/queries';
import { adminsLoader } from '@/pages/Admins/queries';
import { profilesLoader, profileLoader } from '@/pages/Profiles/queries';
import { navigationCuesLoader, navigationCueLoader } from '@/pages/NavigationCues/queries';
import { beaconsLoader, beaconLoader, beaconsMapLoader } from '@/pages/Beacons/queries';

const queryClient = new QueryClient();

const paths: RouteObject[] = [
  {
    path: routes.auth(),
    loader: authenticate,
  },
  {
    path: routes.base(),
    element: <AuthenticatedLayout />,
    loader: requireUserSession,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        loader: () => redirect(routes.map()),
      },
      {
        path: subRoutes.map,
        element: <Map />,
        children: [
          {
            path: `:${routeParam.floor}`,
            element: <Map />,
          },
        ],
      },
      {
        path: subRoutes.mapCategories,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <MapCategories />,
            loader: mapCategoriesLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <MapCategoryCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <MapCategoryUpdate />,
            loader: mapCategoryLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.announcements,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Announcements />,
            loader: announcementsLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <AnnouncementCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <AnnouncementUpdate />,
            loader: announcementLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.placeNotifications,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <PlaceNotifications />,
            loader: placeNotificationsLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <PlaceNotificationCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <PlaceNotificationUpdate />,
            loader: placeNotificationLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.pushNotifications,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <PushNotifications />,
            loader: pushNotificationsLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <PushNotificationCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <PushNotificationUpdate />,
            loader: pushNotificationLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.units,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Units />,
            loader: unitsLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <UnitCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <UnitUpdate />,
            loader: unitLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.devices,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Devices />,
            loader: devicesLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <DeviceCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <DeviceUpdate />,
            loader: deviceLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.popularLocations,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <PopularLocations />,
            loader: popularLocationsLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <PopularLocationCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <PopularLocationUpdate />,
            loader: popularLocationLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.admins,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Admins />,
            loader: adminsLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.profiles,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Profiles />,
            loader: profilesLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <ProfileCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <ProfileUpdate />,
            loader: profileLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.navigationCues,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <NavigationCues />,
            loader: navigationCuesLoader(queryClient),
          },
          {
            path: `${subRoutes.create}`,
            element: <NavigationCueCreate />,
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <NavigationCueUpdate />,
            loader: navigationCueLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.beacons,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <Beacons />,
            loader: beaconsLoader(queryClient),
          },
          {
            path: `${subRoutes.update}/:${routeParam.id}`,
            element: <BeaconUpdate />,
            loader: beaconLoader(queryClient),
          },
        ],
      },
      {
        path: subRoutes.beaconsMap,
        element: <PageLayout />,
        children: [
          {
            index: true,
            element: <BeaconsMap />,
            loader: beaconsMapLoader(queryClient),
          },
        ],
      },
    ],
  },
  {
    path: routes.noAccess(),
    element: <NoAccess />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export const browserRouter = createBrowserRouter(paths);
