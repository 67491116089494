import { useMemo } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface BooleanObjectProps {
  value: string | boolean;
}

export const BooleanObject = ({ value }: BooleanObjectProps) => {
  const boolValue = useMemo(() => {
    if (!value) {
      return false;
    }

    if (typeof value === 'boolean') {
      return value;
    }

    return Boolean(Number.parseInt(value));
  }, [value]);

  return boolValue ? <CheckOutlined /> : <CloseOutlined />;
};
