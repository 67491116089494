import { Upload } from 'antd';
import styled from 'styled-components';

export const UploadStyled = styled(Upload)`
  img {
    max-height: 220px;
  }

  &.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 100%;
    height: 240px;
  }

  .anticon {
    font-size: 2.4rem;
  }
`;
