import type { IPagination, IRemoteSelect } from '@/types/global';
import type { IPopularLocation, IPopularLocationCreate, IPopularLocationUpdate } from './types';

import HTTP from '@/utils/http';

export const popularLocationService = {
  getPopularLocations: (page: number) =>
    HTTP.get<IPagination<IPopularLocation>>(`popular?page=${page}`).then(response => response.data),
  getPopularLocation: (id: number) => HTTP.get<IPopularLocation>(`popular/${id}`).then(response => response.data),
  createPopularLocation: (popularLocationCreate: IPopularLocationCreate) => HTTP.post('popular', popularLocationCreate),
  updatePopularLocation: (popularLocationUpdate: IPopularLocationUpdate) =>
    HTTP.put(`popular/${popularLocationUpdate.id}`, popularLocationUpdate),
  deletePopularLocation: (id: number) => HTTP.delete(`popular/${id}`),

  getLocations: (search: string, limit: number) =>
    HTTP.get<IRemoteSelect[]>(`geo-by-type?search=${search}&limit=${limit}`).then(response => response.data),
};
