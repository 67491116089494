import { Link, useLoaderData, useSearchParams } from 'react-router-dom';
import { Button, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import type { SorterResult } from 'antd/es/table/interface';
import type { BeaconsLoaderType } from '../queries';
import type { IBeacon } from '../types';

import { Filters } from './Filters';
import { PageHeader } from '@/ui/PageHeader';

import { useBeaconsQuery } from '../queries';
import { usePagination } from '@/hooks/usePagination';
import { useColumns } from './useColumns';
import { setSortingSearchParams, getSortingTable } from '@/utils/sorting';
import { routes } from '@/app/router/routes';

export const Beacons = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialData = useLoaderData() as BeaconsLoaderType;
  const { data: beacons, isLoading } = useBeaconsQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('beacons.title')}
        description={t('beacons.description')}
        extra={
          <Link to={routes.beaconsMap()}>
            <Button type='primary'>{t('beacons.navigateToMapView')}</Button>
          </Link>
        }
      />
      <Filters />
      <Table<IBeacon>
        rowKey='id'
        dataSource={beacons.data ?? []}
        loading={isLoading}
        columns={useColumns(getSortingTable(searchParams)) ?? []}
        pagination={usePagination(beacons.total, beacons.per_page, false)}
        scroll={{ x: 'max-content' }}
        onChange={(pagination, _, sorter) => {
          setSortingSearchParams(sorter as SorterResult<IBeacon>, pagination.current, searchParams, setSearchParams);
        }}
      />
    </>
  );
};
