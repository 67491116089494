import { Layout } from 'antd';
import styled from 'styled-components';

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  width: 100%;
`;

export const LayoutContentStyled = styled(Layout.Content)`
  position: relative;
`;
