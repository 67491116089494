import type { IPagination } from '@/types/global';
import type { IUnit, IUnitCreate, IUnitUpdate } from './types';

import HTTP from '@/utils/http';

export const unitService = {
  getUnits: (page: number) => HTTP.get<IPagination<IUnit>>(`units?page=${page}`).then(response => response.data),
  getUnit: (id: number) => HTTP.get<IUnit>(`units/${id}`).then(response => response.data),
  createUnit: (unitCreate: IUnitCreate) => HTTP.post('units', unitCreate),
  updateUnit: (unitUpdate: IUnitUpdate) => HTTP.put(`units/${unitUpdate.id}`, unitUpdate),
  deleteUnit: (id: number) => HTTP.delete(`units/${id}`),

  getUnitsNoPaginate: () => HTTP.get<IUnit[]>(`units?paginated=0`).then(response => response.data),
};
