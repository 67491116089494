import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import type { IBeacon } from '../types';

import { routes } from '@/app/router/routes';
import { useBeaconDelete } from '../queries';

import { Check } from '@/ui/Table/Check';

export const useColumns = (sorterResult?: SorterResult<IBeacon>): ColumnsType<IBeacon> => {
  const { t } = useTranslation();
  const { mutate: beaconDelete } = useBeaconDelete();

  return [
    {
      title: t('beacons.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('beacons.mac'),
      dataIndex: 'mac',
      key: 'mac',
      align: 'center' as const,
    },
    {
      title: t('beacons.battery'),
      dataIndex: 'battery',
      key: 'battery',
      align: 'center' as const,
      render: (text: IBeacon['battery']) => text && `${text}%`,
      sorter: true,
      sortOrder: sorterResult?.field === 'battery' ? sorterResult?.order : undefined,
    },
    {
      title: t('beacons.profile'),
      dataIndex: 'profile',
      key: 'profile',
      align: 'center' as const,
      sorter: true,
      sortOrder: sorterResult?.field === 'profile' ? sorterResult?.order : undefined,
    },
    {
      title: t('beacons.building'),
      dataIndex: 'building',
      key: 'building',
      align: 'center' as const,
    },
    {
      title: t('beacons.isOutside'),
      dataIndex: 'isOutside',
      key: 'isOutside',
      render: (text: IBeacon['isOutside']) => <Check value={text} />,
      align: 'center' as const,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IBeacon) => (
        <Space>
          <Link to={routes.beaconUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('beacons.delete.title')}
            okText={t('beacons.delete.okText')}
            cancelText={t('beacons.delete.cancelText')}
            placement='left'
            onConfirm={() => beaconDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
