import { redirect } from 'react-router-dom';

import type { LoaderFunction } from 'react-router-dom';

import { AuthAPI } from '@/services/auth';
import { checkTicket, getAuthToken, getCasUrlWithReturnUrl, setAuthToken } from '@/utils/auth';
import { routeParam, routes } from './routes';

export const requireUserSession: LoaderFunction = async ({ request }) => {
  const redirectUrl = new URL(routes.auth(), globalThis.location.origin);
  redirectUrl.searchParams.set(routeParam.returnUrl, request.url);

  try {
    const token = getAuthToken();
    if (token) {
      await AuthAPI.whoAmI();
      return null;
    }

    return redirect(redirectUrl.toString());
  } catch (error) {
    return redirect(redirectUrl.toString());
  }
};

export const authenticate: LoaderFunction = async ({ request }) => {
  const requestUrl = new URL(request.url);
  const returnUrl = requestUrl.searchParams.get(routeParam.returnUrl);
  const redirectCounter = Number.parseInt(requestUrl.searchParams.get(routeParam.redirectCounter) ?? '0');

  try {
    const { token } = await checkTicket();
    setAuthToken(token);
    await AuthAPI.whoAmI();

    return redirect(returnUrl || routes.map());
  } catch (error) {
    if (redirectCounter > 3) {
      return redirect(routes.noAccess());
    }

    throw redirect(getCasUrlWithReturnUrl(returnUrl, redirectCounter + 1));
  }
};
