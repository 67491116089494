import { ColorPicker, Form, Input, InputNumber, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useValidation } from 'hooks/useValidation';

import { Upload } from '@/ui/Upload';
import { FileType } from '@/ui/Upload/types';

interface FormCommonProps {
  initialIconUrl?: string;
}

export const FormCommon = ({ initialIconUrl }: FormCommonProps) => {
  const { t } = useTranslation();
  const { validate } = useValidation();
  const form = Form.useFormInstance();

  return (
    <>
      <Form.Item name='namePl' label={t('mapCategories.namePL')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='nameEn' label={t('mapCategories.nameEN')} rules={validate('required')}>
        <Input />
      </Form.Item>
      <Form.Item name='range' label={t('mapCategories.range')} rules={validate('required')}>
        <InputNumber min={0} step={1} />
      </Form.Item>
      <Form.Item name='isEnabled' label={t('mapCategories.activity')} valuePropName='checked' rules={validate('required')}>
        <Switch />
      </Form.Item>
      <Form.Item name='isVisible' label={t('mapCategories.visibility')} valuePropName='checked' rules={validate('required')}>
        <Switch />
      </Form.Item>
      <Form.Item
        name='isVisibleIcon'
        label={t('mapCategories.visibilityIcon')}
        valuePropName='checked'
        rules={validate('required')}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name='isVisibleFilter'
        label={t('mapCategories.visibilityCategoryBar')}
        valuePropName='checked'
        rules={validate('required')}
      >
        <Switch />
      </Form.Item>
      <Form.Item name='color' label={t('mapCategories.color')} rules={validate('required')}>
        <ColorPicker onChange={(_, hex) => form.setFieldValue('color', hex)} />
      </Form.Item>
      <Form.Item name='icon' label={t('mapCategories.icon')} rules={validate('required')}>
        <Upload fileType={FileType.icon} initialUrl={initialIconUrl} />
      </Form.Item>
    </>
  );
};
