import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import geoBasicActions from '@/redux/geoBasic/actions';
import { getLoadingStatus, geSingleObjectName } from '@/redux/geoBasic/selectors';

export const useGeoBasicSingleData = (objectId: number, featureType: string) => {
  const dispatch = useDispatch();
  const singleObjectName = useSelector(geSingleObjectName);
  const singleObjectNameLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!objectId || !featureType) {
      return;
    }

    dispatch(geoBasicActions.loadSingleObjectName(featureType, objectId));

    return () => {
      dispatch(geoBasicActions.removeSingleObjectName());
    };
  }, [dispatch, objectId, featureType]);

  return { singleObjectName, singleObjectNameLoading };
};
