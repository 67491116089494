import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IAdmin } from './types';

import { useAdminDelete } from './queries';

export const useColumns = (): ColumnsType<IAdmin> => {
  const { t } = useTranslation();
  const { mutate: adminDelete } = useAdminDelete();

  return [
    {
      title: t('admins.employeeId'),
      dataIndex: 'employeeid',
      key: 'employeeid',
    },
    {
      title: t('admins.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('admins.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IAdmin) => (
        <Popconfirm
          title={t('admins.delete.title')}
          okText={t('admins.delete.okText')}
          cancelText={t('admins.delete.cancelText')}
          placement='left'
          onConfirm={() => adminDelete(text.id)}
        >
          <Button>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
  ];
};
