import { useEffect, useState } from 'react';
import { useMatches } from 'react-router-dom';

export const useCurrentKey = (): string[] | undefined => {
  const [key, setKey] = useState<string[]>([]);
  const matches = useMatches();

  useEffect(() => {
    const lastMatch = matches.at(-1);
    const splitted = lastMatch?.pathname.split('/').filter(item => item !== '');
    const key = splitted?.join('-');

    setKey(key ? [key] : []);
  }, [matches]);

  return key;
};
