import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IProfileUpdate } from '../types';
import type { ProfileLoaderType } from '../queries';

import { useProfileQuery, useProfileUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const ProfileUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IProfileUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as ProfileLoaderType;
  const { data: profile, isLoading: isLoadingQuery } = useProfileQuery(initialData);
  const { mutate: profileUpdate, isLoading: isLoadingMutation } = useProfileUpdate();

  const submitHandler = (data: IProfileUpdate) => {
    profileUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('profiles.form.updateTitle')}
        description={t('profiles.form.updateDescription')}
        navigateBackUrl={routes.profiles()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='profileUpdate'
          form={form}
          initialValues={profile}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
