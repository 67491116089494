import { Form, Space } from 'antd';
import styled from 'styled-components';

export const SpaceStyled = styled(Space)`
  width: 100%;
`;

export const FormStyled = styled(Form)`
  .ant-picker {
    width: 100%;
  }
`;
