export interface IPagination<T> {
  data: T[];
  current_page: number;
  from: number;
  last_page: number;
  path: URL;
  per_page: number;
  to: number;
  total: number;
  first_page_url: URL;
  last_page_url: URL;
  next_page_url: URL;
  prev_page_url: URL;
}

export interface IFile {
  id: number;
  name: string;
  path: string;
}

export interface ILocationBasic {
  id: number;
  name: string;
  type: string;
}

export interface IRemoteSelect {
  id: number;
  name: string;
}

export enum ContentLanguage {
  pl = 'pl',
  en = 'en',
}

export enum ContentStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum NotificationType {
  place = 'place',
  push = 'push',
}
