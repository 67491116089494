/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useMemo } from 'react';
import { Select } from 'antd';

import { LoadingBlock } from '@/ui/Loading';

import { MapContext } from '@/pages/Map/MapContext';
import { useGeoBasicOptionsData } from '../../../useGeoBasicOptionsData';
import { deleteFirstLetter } from '@/utils/deleteFirstLetter';

interface SelectGeoMapProps {
  fieldType: string; //TODO: clarify type
  disabled: boolean;
  geoCenter: [number, number];
  initialValue: unknown;
  featureId: number;
}

export const SelectGeoMap = ({ fieldType, disabled, geoCenter, initialValue, featureId, ...restProps }: SelectGeoMapProps) => {
  const { currentFloor } = useContext(MapContext) as any;
  const changedFieldType = fieldType === 'parent_roi' ? 'roi' : fieldType;
  const { selectOptions, selectOptionsLoading } = useGeoBasicOptionsData(
    changedFieldType,
    geoCenter,
    Number.parseInt(deleteFirstLetter(currentFloor)),
    initialValue === '' ? null : initialValue,
    featureId,
  );

  const options = useMemo(
    () =>
      selectOptions
        .find((element: any) => element.key === changedFieldType)
        ?.map((option: any) => ({
          key: option.id,
          value: option.id,
          label: option.name, //TODO: maybe tooltip
        })),
    [selectOptions, changedFieldType],
  );

  return (
    <LoadingBlock loading={selectOptionsLoading}>
      <Select options={options || []} disabled={disabled} filterOption={false} defaultActiveFirstOption={false} {...restProps} />
    </LoadingBlock>
  );
};
