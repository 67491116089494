import { mapsUrl } from '@/settings';

export const mapConfig = {
  xyz: mapsUrl,
  xyzEnd: '{z}/{x}/{y}.png',
  zoom: 21,
  minZoom: 13,
  maxZoom: 28,
  constrainResolution: false,
  featureProjection: 'EPSG:3857',
};

export const tileConfig = {
  minZoom: 12,
  maxZoom: 22,
};
