import type { IPagination } from '@/types/global';
import type { IDevice, IDeviceCreate, IDeviceUpdate } from './types';

import HTTP from '@/utils/http';

export const deviceService = {
  getDevices: (page: number) => HTTP.get<IPagination<IDevice>>(`multimedia?page=${page}`).then(response => response.data),
  getDevice: (id: number) => HTTP.get<IDevice>(`multimedia/${id}`).then(response => response.data),
  createDevice: (deviceCreate: IDeviceCreate) => HTTP.post('multimedia', deviceCreate),
  updateDevice: (deviceUpdate: IDeviceUpdate) => HTTP.put(`multimedia/${deviceUpdate.id}`, deviceUpdate),
  deleteDevice: (id: number) => HTTP.delete(`multimedia/${id}`),

  getDevicesNoPaginate: () => HTTP.get<IDevice[]>(`multimedia?paginated=0`).then(response => response.data),
};
