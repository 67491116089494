import { useTranslation } from 'react-i18next';

import { LoadingBlock } from '@/ui/Loading';

import { useGeoBasicSingleData } from '../../useGeoBasicSingleData';

interface ExternalObjectProps {
  objectId: number;
  featureType: string;
}

export const ExternalObject = ({ objectId, featureType }: ExternalObjectProps) => {
  const { t } = useTranslation();
  const { singleObjectName, singleObjectNameLoading } = useGeoBasicSingleData(objectId, featureType);

  return (
    <LoadingBlock loading={singleObjectNameLoading}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {singleObjectName.find((element: any) => element.key === featureType)?.name ?? t('global.noData')}
    </LoadingBlock>
  );
};
