import { Button } from 'antd';
import { Link, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { BeaconsMapLoaderType } from '../queries';

import { PageHeader } from '@/ui/PageHeader';

import { useBeaconsMapQuery } from '../queries';
import { routes } from '@/app/router/routes';

import { LoadingMask } from '@/ui/Loading';
import { MapContainer } from './MapContainer';
import { Layers } from './Layers';
import { TileLayer } from './Layers/TileLayer';
import { VectorLayer } from './Layers/VectorLayer';
import { Controls } from './Controls';
import { FloorsMenu } from './Controls/FloorsMenu';
import { BatteryScopeMenu } from './Controls/BatteryScopeMenu';
import { Interactions } from './Interactions';
import { SelectInteraction } from './Interactions/SelectInteraction';

export const BeaconsMap = (): JSX.Element => {
  const { t } = useTranslation();
  const initialData = useLoaderData() as BeaconsMapLoaderType;
  const { data: beaconsMap, isLoading } = useBeaconsMapQuery(initialData);

  return (
    <>
      <PageHeader
        title={t('beacons.map.title')}
        description={t('beacons.map.description')}
        extra={
          <Link to={routes.beacons()}>
            <Button type='primary'>{t('beacons.map.navigateToListView')}</Button>
          </Link>
        }
      />
      <MapContainer vectorData={beaconsMap}>
        <LoadingMask loading={isLoading}>
          <Layers>
            <TileLayer zIndex={0} />
            <VectorLayer zIndex={1} />
          </Layers>
          <Controls>
            <FloorsMenu />
            <BatteryScopeMenu />
          </Controls>
          <Interactions>
            <SelectInteraction />
          </Interactions>
        </LoadingMask>
      </MapContainer>
    </>
  );
};
