import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons';

import { ContentStatus } from '@/types/global';

interface StatusProps {
  status: ContentStatus;
}

export const Status = ({ status }: StatusProps) => {
  switch (status) {
    case ContentStatus.active:
      return <CheckOutlined />;
    case ContentStatus.inactive:
      return <CloseOutlined />;
    default:
      return <MinusOutlined />;
  }
};
