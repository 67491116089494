import { useContext, useEffect } from 'react';
import { MapContext } from '../MapContext';

import Draw from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Polygon from 'ol/geom/Polygon';
import MultiPoint from 'ol/geom/MultiPoint';
import { getCenter } from 'ol/extent';
import { never } from 'ol/events/condition';

import { CompUtils, commonOptions, commonEvents } from '../utils';

import mapStyles from '../Styles/shapeStyles';
import { DrawShape } from '../enums';

export const DrawInt = props => {
  const { map, mapMenuState } = useContext(MapContext);

  const options = {
    type: undefined,
    clickTolerance: undefined,
    source: undefined,
    dragVertexDelay: undefined,
    snapTolerance: undefined,
    stopClick: undefined,
    maxPoints: undefined,
    minPoints: undefined,
    finishCondition: undefined,
    style: mapStyles.select,
    geometryFunction: undefined,
    geometryName: undefined,
    condition: undefined,
    freehand: undefined,
    freehandCondition: never,
    wrapX: undefined,
  };

  const events = {
    drawabort: undefined,
    drawend: undefined,
    drawstart: undefined,
  };

  Object.assign(options, commonOptions);
  Object.assign(events, commonEvents);

  useEffect(() => {
    if (!map) {
      return;
    }

    const opts = CompUtils.getOptions(options, props);

    if (props.type === DrawShape.square) {
      opts.type = DrawShape.circle;
      opts.geometryFunction = squareCustomGeometryFunction;
    } else if (props.type === DrawShape.box) {
      opts.type = DrawShape.circle;
      opts.geometryFunction = rectangleCustomGeometryFunction;
    }

    const drawInteraction = new Draw(opts);
    map.addInteraction(drawInteraction);

    const olEvents = CompUtils.getEvents(events, props);
    for (let eventName in olEvents) {
      drawInteraction.on(eventName, olEvents[eventName]);
    }

    const snapInteraction = new Snap({
      source: props.source,
    });

    if (mapMenuState.snap) {
      const arrayFeatures = props.source.getFeatures();
      map.addInteraction(snapInteraction);

      for (const element of arrayFeatures) {
        snapInteraction.addFeature(element);
      }
    }

    return () => {
      if (mapMenuState.snap && map.getInteractions().getArray().includes(snapInteraction)) {
        map.removeInteraction(snapInteraction);
      }
      map.removeInteraction(drawInteraction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, props.type, mapMenuState]);

  const squareCustomGeometryFunction = (coordinates, geometry) => {
    const start = coordinates[0];
    const last = coordinates[coordinates.length - 1];
    const dx = last[0] - start[0];
    const dy = last[1] - start[1];
    const diagonal = Math.sqrt(dx * dx + dy * dy);
    const rotation = Math.atan2(dy, dx);
    const numPoints = 4;
    let newCoordinates = [];

    for (let i = 0; i < numPoints; i++) {
      const angle = rotation + (i * 2 * Math.PI) / numPoints;
      const offsetX = (diagonal * Math.cos(angle)) / 2;
      const offsetY = (diagonal * Math.sin(angle)) / 2;
      const points = new MultiPoint([start, last]);
      const centerOfRotation = getCenter(points.getExtent());

      switch (i) {
        case 0:
          newCoordinates.push([start[0], start[1]]);
          break;

        case 2:
          newCoordinates.push([last[0], last[1]]);
          break;

        default:
          newCoordinates.push([centerOfRotation[0] + offsetX, centerOfRotation[1] + offsetY]);
          break;
      }
    }

    newCoordinates.push(newCoordinates[0].slice());

    if (!geometry) {
      geometry = new Polygon([newCoordinates]);
    } else {
      geometry.setCoordinates([newCoordinates]);
    }

    return geometry;
  };

  const rectangleCustomGeometryFunction = (coordiantes, geometry) => {
    let firstPoint = coordiantes[0];
    let thirdPoint = coordiantes[1];

    let secondPoint = [firstPoint[0], thirdPoint[1]];
    let forthPoint = [thirdPoint[0], firstPoint[1]];

    let currentMapRotation = map.getView().getRotation();

    if (currentMapRotation !== 0) {
      let pointsToRotate = new MultiPoint([secondPoint, forthPoint]);
      let centerOfRotation = getCenter(pointsToRotate.getExtent());
      pointsToRotate.rotate(2 * currentMapRotation, centerOfRotation);
      secondPoint = pointsToRotate.getCoordinates()[0];
      forthPoint = pointsToRotate.getCoordinates()[1];
    }

    let newCoordinates = [firstPoint, secondPoint, thirdPoint, forthPoint, firstPoint];

    if (!geometry) {
      geometry = new Polygon([newCoordinates]);
    } else {
      geometry.setCoordinates([newCoordinates]);
    }

    return geometry;
  };

  return null;
};
