import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

export const DeleteModal = ({ selected, visible, setVisible, handleOk }) => {
  const { t } = useTranslation();

  const isEmptyTranslationHelper = () => (isEmpty(selected) ? 'empty' : 'notEmpty');

  return (
    <Modal
      title={t(`map.editMode.modalDelete.title.${isEmptyTranslationHelper()}`)}
      okText={t(`map.editMode.modalDelete.confirmButtonTitle.${isEmptyTranslationHelper()}`)}
      cancelText={isEmpty(selected) ? null : t('map.editMode.modalDelete.cancelButtonTitle.notEmpty')}
      open={visible}
      onOk={isEmpty(selected) ? setVisible : handleOk}
      onCancel={setVisible}
      getContainer={false}
      cancelButtonProps={isEmpty(selected) && { hidden: true }}
      centered
    >
      {t(`map.editMode.modalDelete.text.${isEmptyTranslationHelper()}`)}
    </Modal>
  );
};
