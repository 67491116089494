import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { IBeaconUpdate } from '../types';
import type { BeaconLoaderType } from '../queries';

import { useBeaconQuery, useBeaconUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';
import { MapBeacon } from './MapBeacon';

export const BeaconUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IBeaconUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as BeaconLoaderType;
  const { data: beacon, isLoading: isLoadingQuery } = useBeaconQuery(initialData);
  const { mutate: beaconUpdate, isLoading: isLoadingMutation } = useBeaconUpdate();

  const submitHandler = (data: IBeaconUpdate) => {
    beaconUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('beacons.form.updateTitle')}
        description={t('beacons.form.updateDescription')}
        navigateBackUrl={routes.beacons()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='beaconUpdate'
          form={form}
          initialValues={beacon}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
        <MapBeacon />
      </Loading>
    </>
  );
};
