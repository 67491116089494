import styled from 'styled-components';

import type { GlobalToken } from 'antd';

export const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const TextContainer = styled.section`
  display: flex;
  flex-direction: column;

  h1.ant-typography {
    font-size: 2.4rem;
    margin: 0 0 0.2rem 0;
  }

  span.ant-typography {
    font-size: 1.4rem;
  }
`;

interface TitleContainerProps {
  token: GlobalToken;
}

export const TitleContainer = styled.section<TitleContainerProps>`
  display: flex;
  column-gap: 1rem;
  align-items: center;

  .anticon {
    font-size: 1.8rem;
    color: ${props => props.token.colorPrimary};
  }
`;
