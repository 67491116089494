import { Drawer } from 'antd';

import type { ReactNode } from 'react';

interface AsideContainerProps {
  title: string;
  mask: boolean;
  open: boolean;
  handleClose: () => void;
  maskClosable?: boolean;
  children: ReactNode;
}

export const AsideContainer = ({ title, mask, maskClosable, open, handleClose, children }: AsideContainerProps) => (
  <Drawer
    title={title}
    placement='left'
    open={open}
    closable={true}
    onClose={handleClose}
    mask={mask}
    maskClosable={mask && maskClosable}
    getContainer={false}
    destroyOnClose={true}
  >
    {children}
  </Drawer>
);
