import styled from 'styled-components';
import type { GlobalToken } from 'antd';

interface MapSwitchContainerProps {
  token: GlobalToken;
  topDistance: number;
}

export const MapSwitchContainer = styled.div<MapSwitchContainerProps>`
  position: absolute;
  right: 1rem;
  top: ${props => props.topDistance}rem;
  width: 16rem;
  height: 3.6rem;
  font-size: 1.6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  z-index: 100;

  border-radius: ${props => props.token.borderRadiusLG}px;
  color: ${props => props.token.colorWhite};
  background-color: ${props => props.token.colorPrimary};

  .ant-switch {
    background-color: ${props => props.token.colorPrimaryActive};
  }
`;
