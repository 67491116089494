import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import type { INavigationCueUpdate } from '../types';
import type { NavigationCueLoaderType } from '../queries';

import { useNavigationCueQuery, useNavigationCueUpdate } from '../queries';
import { useValidation } from '@/hooks/useValidation';
import { routes } from '@/app/router/routes';

import { FormCommon } from './FormCommon';
import { PageHeader } from '@/ui/PageHeader';
import { Loading } from '@/ui/Loading';

export const NavigationCueUpdate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<INavigationCueUpdate>();
  const { validate } = useValidation();
  const initialData = useLoaderData() as NavigationCueLoaderType;
  const { data: navigationCue, isLoading: isLoadingQuery } = useNavigationCueQuery(initialData);
  const { mutate: navigationCueUpdate, isLoading: isLoadingMutation } = useNavigationCueUpdate();

  const submitHandler = (data: INavigationCueUpdate) => {
    navigationCueUpdate(data);
  };

  return (
    <>
      <PageHeader
        title={t('navigationCues.form.updateTitle')}
        description={t('navigationCues.form.updateDescription')}
        navigateBackUrl={routes.navigationCues()}
      />
      <Loading loading={isLoadingQuery}>
        <Form
          name='navigationCueUpdate'
          form={form}
          initialValues={{ ...navigationCue, photo: navigationCue.photo.id }}
          requiredMark='optional'
          layout='vertical'
          onFinish={submitHandler}
          scrollToFirstError
        >
          <Form.Item name='id' rules={validate('required')} hidden>
            <Input />
          </Form.Item>

          <FormCommon initialPhotoUrl={navigationCue.photo.path} />

          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isLoadingMutation}>
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
      </Loading>
    </>
  );
};
