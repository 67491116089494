import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { IProfile } from './types';

import { routes } from '@/app/router/routes';

import { useProfileDelete } from './queries';

export const useColumns = (): ColumnsType<IProfile> => {
  const { t } = useTranslation();
  const { mutate: profileDelete } = useProfileDelete();

  return [
    {
      title: t('profiles.key'),
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: t('profiles.label'),
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: IProfile) => (
        <Space>
          <Link to={routes.profileUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('profiles.delete.title')}
            okText={t('profiles.delete.okText')}
            cancelText={t('profiles.delete.cancelText')}
            placement='left'
            onConfirm={() => profileDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
