import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { LoaderFunctionArgs } from 'react-router-dom';
import type { IPushNotificationCreate, IPushNotificationUpdate } from './types';

import { pushNotificationService } from './service';
import { useNotification } from '@/hooks/useNotification';
import { getIdFromParams, getPageFromRequest, useIdFromParams, usePageFromSearchParams } from '@/utils/params';
import { routes } from '@/app/router/routes';

const pushNotificationsQuery = (page: number) => ({
  queryKey: ['pushNotifications', 'list', page],
  queryFn: () => pushNotificationService.getPushNotifications(page),
  staleTime: 1000 * 60 * 24,
});

export const pushNotificationsLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const page = getPageFromRequest(request);
    const query = pushNotificationsQuery(page);
    return await queryClient.ensureQueryData(query);
  };

export type PushNotificationsLoaderType = Awaited<ReturnType<ReturnType<typeof pushNotificationsLoader>>>;

export const usePushNotificationsQuery = (initialData: PushNotificationsLoaderType) => {
  const page = usePageFromSearchParams();
  const queryInfo = useQuery({
    ...pushNotificationsQuery(page),
    initialData,
  });

  return queryInfo;
};

const pushNotificationQuery = (id: number) => ({
  queryKey: ['pushNotifications', 'details', id],
  queryFn: () => pushNotificationService.getPushNotification(id),
});

export const pushNotificationLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const id = getIdFromParams(params);
    const query = pushNotificationQuery(id);
    return await queryClient.ensureQueryData(query);
  };

export type PushNotificationLoaderType = Awaited<ReturnType<ReturnType<typeof pushNotificationLoader>>>;

export const usePushNotificationQuery = (initialData: PushNotificationLoaderType) => {
  const id = useIdFromParams();
  const queryInfo = useQuery({
    ...pushNotificationQuery(id),
    initialData,
  });

  return queryInfo;
};

export const usePushNotificationCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPushNotificationCreate) => pushNotificationService.createPushNotification(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pushNotifications', 'list'] });
      successNotification('pushNotifications.notification.createMessage');
      navigate(routes.pushNotifications());
    },
  });

  return mutationInfo;
};

export const usePushNotificationUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (data: IPushNotificationUpdate) => pushNotificationService.updatePushNotification(data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pushNotifications', 'list'] });
      queryClient.invalidateQueries({ queryKey: ['pushNotifications', 'details', variables.id] });
      successNotification('pushNotifications.notification.updateMessage');
      navigate(routes.pushNotifications());
    },
  });

  return mutationInfo;
};

export const usePushNotificationDelete = () => {
  const queryClient = useQueryClient();
  const { successNotification } = useNotification();

  const mutationInfo = useMutation({
    mutationFn: (id: number) => pushNotificationService.deletePushNotification(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pushNotifications', 'list'] });
      successNotification('pushNotifications.notification.deleteMessage');
    },
  });

  return mutationInfo;
};
