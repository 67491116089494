import { useState } from 'react';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

import type { RefObject } from 'react';

import { FullscreenButton } from './style';

interface FullscreenProps {
  mapContainerRef: RefObject<HTMLDivElement>;
}

export const Fullscreen = ({ mapContainerRef }: FullscreenProps) => {
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const handleClick = () => {
    if (mapContainerRef.current) {
      fullscreenMode ? document.exitFullscreen() : mapContainerRef.current.requestFullscreen();
      setFullscreenMode(prevState => !prevState);
    }
  };

  document.onfullscreenchange = () => {
    if (!document.fullscreenElement && fullscreenMode) {
      setFullscreenMode(false);
    }
  };

  return (
    <FullscreenButton onClick={handleClick}>
      {fullscreenMode ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
    </FullscreenButton>
  );
};
