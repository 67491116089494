import type { IPagination, IRemoteSelect } from '@/types/global';
import type { IPlaceNotification, IPlaceNotificationCreate, IPlaceNotificationUpdate } from './types';

import HTTP from '@/utils/http';

export const placeNotificationService = {
  getPlaceNotifications: (page: number) =>
    HTTP.get<IPagination<IPlaceNotification>>(`notification?page=${page}`).then(response => response.data),
  getPlaceNotification: (id: number) => HTTP.get<IPlaceNotification>(`notification/${id}`).then(response => response.data),
  createPlaceNotification: (placeNotificationCreate: IPlaceNotificationCreate) =>
    HTTP.post('notification', placeNotificationCreate),
  updatePlaceNotification: (placeNotificationUpdate: IPlaceNotificationUpdate) =>
    HTTP.put(`notification/${placeNotificationUpdate.id}`, placeNotificationUpdate),
  deletePlaceNotification: (id: number) => HTTP.delete(`notification/${id}`),

  getLocations: (search: string, limit: number) =>
    HTTP.get<IRemoteSelect[]>(`geo-by-type?search=${search}&limit=${limit}`).then(response => response.data),
};
