import { MinusOutlined } from '@ant-design/icons';

import { ContentLanguage } from '@/types/global';

interface LanguageProps {
  language: ContentLanguage;
}

export const Language = ({ language }: LanguageProps) => {
  switch (language) {
    case ContentLanguage.pl:
      return <>PL</>;
    case ContentLanguage.en:
      return <>EN</>;
    default:
      return <MinusOutlined />;
  }
};
