export const viewConfig = {
  zoom: 16,
  minZoom: 13,
  maxZoom: 28,
  defaultRotation: 6.2831853072,
  constrainRotation: 360,
  constrainResolution: false,
  minRotation: 0,
  maxRotation: 359,
} as const;
