import { Circle, Fill, Stroke, Style } from 'ol/style';

import { mapsUrl } from '@/settings';

export const mapConfig = {
  center: [19.453383223199438, 51.750138932202276],
  xyz: mapsUrl,
  xyzEnd: '{z}/{x}/{y}.png',
  zoom: 15,
  minZoom: 13,
  maxZoom: 28,
  constrainResolution: false,
  defaultProfile: '0',
  featureProjection: 'EPSG:3857',
};

export const tileConfig = {
  minZoom: 12,
  maxZoom: 22,
};

export const mapStyles = {
  defaultBeaconColor: '#69686D30',
  beaconStroke: '#69686D',
  beaconStrokeWidth: 1,
  beaconRadius: 4,
  singleBeaconRadius: 8,
  selectColor: '#8B231D90',
  selectStroke: '#8B231D',
};

export const selectStyle = new Style({
  image: new Circle({
    radius: mapStyles.beaconRadius,
    fill: new Fill({ color: mapStyles.selectColor }),
    stroke: new Stroke({ color: mapStyles.selectStroke, width: mapStyles.beaconStrokeWidth }),
  }),
});
