import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { BatteryScope } from '../../types';

interface SelectBatteryScopeProps {
  onChange: (value: string) => void;
}

export const SelectBatteryScope = ({ onChange, ...restProps }: SelectBatteryScopeProps): JSX.Element => {
  const { t } = useTranslation();
  const options = Object.entries(BatteryScope).map(([key, value]) => ({
    value,
    label: t(`beacons.batteryScope.${key as keyof typeof BatteryScope}`),
  }));

  return <Select allowClear options={options || []} onChange={onChange} {...restProps} />;
};
