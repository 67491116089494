import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import type { ColumnsType } from 'antd/es/table';
import type { INavigationCue } from './types';

import { useNavigationCueDelete } from './queries';
import { routes } from '@/app/router/routes';

import { Language } from '@/ui/Table/Language';
import { Image } from '@/ui/Table/Image';

export const useColumns = (): ColumnsType<INavigationCue> => {
  const { t } = useTranslation();
  const { mutate: navigationCueDelete } = useNavigationCueDelete();

  return [
    {
      title: t('navigationCues.routerKey'),
      dataIndex: 'routerKey',
      key: 'routerKey',
    },
    {
      title: t('navigationCues.language'),
      dataIndex: 'language',
      key: 'language',
      render: (text: INavigationCue['language']) => <Language language={text} />,
      align: 'center' as const,
    },
    {
      title: t('navigationCues.translation'),
      dataIndex: 'translation',
      key: 'translation',
      align: 'center' as const,
    },
    {
      title: t('navigationCues.photo'),
      dataIndex: 'photo',
      key: 'photo',
      render: (text: INavigationCue['photo']) => <Image path={text.path} />,
      align: 'center' as const,
    },
    {
      title: '',
      key: 'actions',
      align: 'center' as const,
      render: (text: INavigationCue) => (
        <Space>
          <Link to={routes.navigationCueUpdate(text.id)}>
            <Button>
              <EditOutlined />
            </Button>
          </Link>
          <Popconfirm
            title={t('navigationCues.delete.title')}
            okText={t('navigationCues.delete.okText')}
            cancelText={t('navigationCues.delete.cancelText')}
            placement='left'
            onConfirm={() => navigationCueDelete(text.id)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};
