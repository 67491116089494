import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IPopularLocationCreate } from '../types';

import { usePopularLocationCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

export const PopularLocationCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPopularLocationCreate>();
  const { mutate: popularLocationCreate, isLoading } = usePopularLocationCreate();

  const submitHandler = (data: IPopularLocationCreate) => {
    popularLocationCreate(data);
  };

  return (
    <>
      <PageHeader
        title={t('popularLocations.form.createTitle')}
        description={t('popularLocations.form.createDescription')}
        navigateBackUrl={routes.popularLocations()}
      />
      <Form
        name='popularLocationCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
