import { Dropdown } from 'antd';
import styled from 'styled-components';

import type { GlobalToken } from 'antd';

interface DropdownDesignTokenProps {
  token: GlobalToken;
}

export const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 10rem;
  left: 8rem;
  z-index: 1000;

  .ant-dropdown-menu-item {
    text-align: center;
  }
`;

export const DropdownContent = styled.div<DropdownDesignTokenProps>`
  cursor: pointer;
  height: 3.6rem;
  width: 9.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.token.colorPrimary};
  border-radius: ${props => props.token.borderRadiusLG}px;
  color: ${props => props.token.colorWhite};
`;
