import styled from 'styled-components';

import type { GlobalToken } from 'antd';

export const ColorContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ColorSquareProps {
  token: GlobalToken;
  color: string;
}

export const ColorSquare = styled.div<ColorSquareProps>`
  margin-right: 4px;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: ${props => props.token.borderRadiusSM}px;
  background-color: ${props => props.color};
  border: 1px solid ${props => props.token.colorBorder};
`;
