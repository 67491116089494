import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IPushNotificationCreate } from '../types';

import { usePushNotificationCreate } from '../queries';
import { routes } from '@/app/router/routes';

import { PageHeader } from '@/ui/PageHeader';
import { FormCommon } from './FormCommon';

import { NotificationType } from '@/types/global';

export const PushNotificationCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IPushNotificationCreate>();
  const { mutate: pushNotificationCreate, isLoading } = usePushNotificationCreate();

  const submitHandler = (data: IPushNotificationCreate) => {
    pushNotificationCreate({ ...data, type: NotificationType.push });
  };

  return (
    <>
      <PageHeader
        title={t('pushNotifications.form.createTitle')}
        description={t('pushNotifications.form.createDescription')}
        navigateBackUrl={routes.pushNotifications()}
      />
      <Form
        name='pushNotificationCreate'
        form={form}
        requiredMark='optional'
        layout='vertical'
        onFinish={submitHandler}
        scrollToFirstError
      >
        <FormCommon />

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {t('global.send')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
