import { RegularShape, Stroke, Fill, Style, Circle } from 'ol/style';

const redStroke = new Stroke({ color: [255, 0, 0, 1], width: 1 });
const redStrokeDash = new Stroke({ color: [255, 0, 0, 1], width: 1, lineDash: [5, 5] });
const whiteFill = new Fill({ color: [255, 255, 255, 1] });
const selectFill = new Fill({ color: [255, 0, 0, 0.01] });

// vars
const bigRadius = 8;
const smallRadius = 6;
const pointsSquare = 4; // square
const angleSquare = Math.PI / 4;

const bigpt = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: bigRadius,
  points: pointsSquare,
  angle: angleSquare,
}); // center point

// rotate pointer
const circle0 = new Circle({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
});

const circle = new Circle({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
  displacement: [0, 10],
});

// scale pointers
const scaleLD = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: bigRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [-17, -15],
});

const scaleLU = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: bigRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [-17, 15],
});

const scaleRU = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: bigRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [17, 15],
});

const scaleRD = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: bigRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [17, -15],
});

const scaleL = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [-15, 0],
});

const scaleU = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [0, 15],
});

const scaleR = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [15, 0],
});

const scaleD = new RegularShape({
  fill: whiteFill,
  stroke: redStroke,
  radius: smallRadius,
  points: pointsSquare,
  angle: angleSquare,
  displacement: [0, -15],
});

const transformStyles = {
  default: new Style({
    image: bigpt,
    stroke: redStrokeDash,
    fill: selectFill,
  }),
  // translate: new Style({
  // }),
  rotate: new Style({
    image: circle,
  }),
  rotate0: new Style({
    image: circle0,
  }),
  scale: new Style({
    image: scaleLD,
  }),
  scale1: new Style({
    image: scaleLU,
  }),
  scale2: new Style({
    image: scaleRU,
  }),
  scale3: new Style({
    image: scaleRD,
  }),
  scalev: new Style({
    image: scaleL,
  }),
  scaleh1: new Style({
    image: scaleU,
  }),
  scalev2: new Style({
    image: scaleR,
  }),
  scaleh3: new Style({
    image: scaleD,
  }),
};

export default transformStyles;
