import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import categoriesBasicActions from '@/redux/categoriesBasic/actions';
import { getLoadingStatus, getCategoryBasic } from '@/redux/categoriesBasic/selectors';

export const useCategoryBasicData = (objectId: number) => {
  const dispatch = useDispatch();
  const categoryBasic = useSelector(getCategoryBasic);
  const categoryBasicLoading = useSelector(getLoadingStatus);

  useEffect(() => {
    if (!objectId) {
      return;
    }

    dispatch(categoriesBasicActions.loadCategoryBasic(objectId));

    return () => {
      dispatch(categoriesBasicActions.removeCategoryBasic());
    };
  }, [dispatch, objectId]);

  return { categoryBasic, categoryBasicLoading };
};
