import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  EnvironmentOutlined,
  TagsOutlined,
  ReadOutlined,
  PushpinOutlined,
  NotificationOutlined,
  DeploymentUnitOutlined,
  UsbOutlined,
  FireOutlined,
  UserOutlined,
  NodeIndexOutlined,
  BulbOutlined,
  CompassOutlined,
} from '@ant-design/icons';

import type { MenuProps } from 'antd';

import { routes, subRoutes } from '@/app/router/routes';

export const useMenuItemList = () => {
  const { t } = useTranslation();

  const items: MenuProps['items'] = [
    {
      key: subRoutes.map,
      label: <Link to={routes.map()}>{t('menu.map')}</Link>,
      icon: <EnvironmentOutlined />,
    },
    {
      key: subRoutes.mapCategories,
      label: <Link to={routes.mapCategories()}>{t('menu.mapCategories')}</Link>,
      icon: <TagsOutlined />,
    },
    {
      key: subRoutes.units,
      label: <Link to={routes.units()}>{t('menu.units')}</Link>,
      icon: <DeploymentUnitOutlined />,
    },
    {
      key: subRoutes.devices,
      label: <Link to={routes.devices()}>{t('menu.devices')}</Link>,
      icon: <UsbOutlined />,
    },
    {
      key: subRoutes.profiles,
      label: <Link to={routes.profiles()}>{t('menu.profiles')}</Link>,
      icon: <NodeIndexOutlined />,
    },
    {
      key: subRoutes.navigationCues,
      label: <Link to={routes.navigationCues()}>{t('menu.navigationCues')}</Link>,
      icon: <BulbOutlined />,
    },
    {
      key: subRoutes.beacons,
      label: <Link to={routes.beacons()}>{t('menu.beacons')}</Link>,
      icon: <CompassOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: subRoutes.announcements,
      label: <Link to={routes.announcements()}>{t('menu.announcements')}</Link>,
      icon: <ReadOutlined />,
    },
    {
      key: subRoutes.placeNotifications,
      label: <Link to={routes.placeNotifications()}>{t('menu.placeNotifications')}</Link>,
      icon: <PushpinOutlined />,
    },
    {
      key: subRoutes.pushNotifications,
      label: <Link to={routes.pushNotifications()}>{t('menu.pushNotifications')}</Link>,
      icon: <NotificationOutlined />,
    },
    {
      key: subRoutes.popularLocations,
      label: <Link to={routes.popularLocations()}>{t('menu.popularLocations')}</Link>,
      icon: <FireOutlined />,
    },
    {
      key: subRoutes.admins,
      label: <Link to={routes.admins()}>{t('menu.admins')}</Link>,
      icon: <UserOutlined />,
    },
  ];

  return items;
};
